import { useAreobicCapabilities } from '~/modules/aerobicCapabilities/useAerobicCapabilities'

import { Protocol } from './types'
import { useVSAQ } from './useVSAQ'

type Props = {
  data: Protocol
}

export const TableInput: React.FC<Props> = ({ data }) => {
  const { data: formData } = useAreobicCapabilities()
  const { updateSlopeProtocolData } = useVSAQ()

  return (
    <table className='w-full my-3'>
      <thead>
        <tr>
          <th className='text-left py-2 pl-2'>Tempo (min)</th>
          <th className='text-left'>Velocidade (Km/h)</th>
          <th className='text-left pr-2'>Inclinação</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr
            data-selected={row.time === formData?.slope.time}
            key={`input-protocol-${index}`}
            className='odd:bg-blue-50 cursor-pointer data-[selected=true]:bg-gpa-blue-500 data-[selected=true]:text-white'
            onClick={() => updateSlopeProtocolData(row)}
          >
            <td className='py-2 pl-2'>{row.time}</td>
            <td>{(row.speed * 1.609344).toFixed(1)}</td>
            <td className='pr-2'>{row.steepness}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
