import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { FiX } from 'react-icons/fi'
import zod from 'zod'

import { Button } from '~/components/Button'
import { Modal } from '~/components/Modal'
import { RadioButton } from '~/components/RadioButton'
import { aerobicCapabilitiesAtom } from '~/modules/aerobicCapabilities/data'
import { updateAerobicCapabilitiesData } from '~/modules/aerobicCapabilities/services'

const schema = zod.object({
  qDuke01: zod.boolean().transform((a) => !!a),
  qDuke02: zod.boolean(),
  qDuke03: zod.boolean(),
  qDuke04: zod.boolean(),
  qDuke05: zod.boolean(),
  qDuke06: zod.boolean(),
  qDuke07: zod.boolean(),
  qDuke08: zod.boolean(),
  qDuke09: zod.boolean(),
  qDuke10: zod.boolean(),
  qDuke11: zod.boolean(),
  qDuke12: zod.boolean(),
})

type QDukeForm = zod.infer<typeof schema>

const questions: Array<{
  name: keyof QDukeForm
  label: string
}> = [
  {
    name: 'qDuke01',
    label:
      '1) Você pode cuidar de si mesmo, isto é, comer, vestir-se, banhar-se ou utilizar o banheiro?',
  },
  {
    name: 'qDuke02',
    label: '2) Caminhar em ambientes internos, como em sua própria casa?',
  },
  {
    name: 'qDuke03',
    label: '3) Caminhar por um ou dois quarteirões em terreno plano?',
  },
  {
    name: 'qDuke04',
    label: '4) Subir um lance de escada ou caminhar subindo uma colina?',
  },
  { name: 'qDuke05', label: '5) Correr percorrendo uma pequena distância?' },
  {
    name: 'qDuke06',
    label:
      '6) Realizar um trabalho leve na própria casa, como remover a poeira ou lavar os pratos?',
  },
  {
    name: 'qDuke07',
    label:
      '7) Realizar um trabalho moderado na própria casa, como passar o aspirador, varrer os assoalhos ou carregar mantimentos?',
  },
  {
    name: 'qDuke08',
    label:
      '8) Realizar um trabalho pesado na própria casa, como esfregar o assoalho ou levantar ou movimentar um móvel pesado?',
  },
  {
    name: 'qDuke09',
    label:
      '9) realizar um trabalho no pátio, como remover folhas com um ancinho, capinar com segador elétrico, ou empurrá-lo?',
  },
  { name: 'qDuke10', label: '10) Ter relações sexuais?' },
  {
    name: 'qDuke11',
    label:
      '11) Participar de atividades recreativas moderadas como golf, boliche, dança dupla de tênis, ou arremeçar uma bola de beisebol ou de futebol americano?',
  },
  {
    name: 'qDuke12',
    label:
      '12) Participar de desportos extenuantes como natação, tênis individual, futebol americano, basquete ou esqui?',
  },
]

export const Duke: React.FC = () => {
  const data = aerobicCapabilitiesAtom.get()
  const [showModal, setShowModal] = useState<boolean>(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<QDukeForm>({
    defaultValues: {
      ...questions.reduce((accu: QDukeForm, currVal, index: number) => {
        accu[currVal.name] = data?.duke[index] as any
        return accu
      }, {} as QDukeForm),
    },
    resolver: zodResolver(schema),
  })

  const handleToggleModal = useCallback(() => {
    setShowModal((state) => !state)
  }, [])

  const onFormSubmit = useCallback((payload: QDukeForm) => {
    updateAerobicCapabilitiesData({
      duke: [...Object.values(payload)],
    })

    setShowModal(false)
  }, [])

  return (
    <>
      <Button title='Questionário de Duke' onClick={handleToggleModal}>
        Questionário de Duke
      </Button>

      <Modal visible={showModal}>
        <header className='flex items-center justify-between'>
          <h1 className='text-lg font-bold'>Questionário de Duke</h1>
          <button
            className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
            onClick={handleToggleModal}
          >
            <FiX size={24} />
          </button>
        </header>

        <form className='my-4' onSubmit={handleSubmit(onFormSubmit)}>
          {questions.map((question) => (
            <Controller
              key={question.name}
              control={control}
              name={question.name}
              render={({ field }) => (
                <div className='flex flex-col md:flex-row odd:bg-blue-50 p-3 justify-between flex-wrap gap-4'>
                  <p className='flex-1'>{question.label}</p>
                  <div className='flex gap-3 items-center justify-center self-stretch'>
                    <RadioButton
                      checked={field.value === false}
                      onChange={() => field.onChange(false)}
                      label='Não'
                      hasError={!!errors[question.name]?.message}
                    />
                    <RadioButton
                      label='Sim'
                      checked={field.value === true}
                      onChange={() => field.onChange(true)}
                      hasError={!!errors[question.name]?.message}
                    />
                  </div>
                </div>
              )}
            />
          ))}

          <Button className='ml-auto w-48 mt-4'>OK</Button>
        </form>
      </Modal>
    </>
  )
}
