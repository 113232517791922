import { useEffect, useState } from 'react'

import { TextInput } from '~/components/TextInput'
import { aerobicCapabilitiesAtom } from '~/modules/aerobicCapabilities/data'
import { updateAerobicCapabilitiesData } from '~/modules/aerobicCapabilities/services'
import { UKKProtocolData } from '~/modules/aerobicCapabilities/types'

export const UKK: React.FC = () => {
  const data = aerobicCapabilitiesAtom.get()
  const [value, setValue] = useState<UKKProtocolData>({
    heartRate: data?.ukk?.heartRate,
    time: data?.ukk?.time,
  })

  useEffect(() => {
    updateAerobicCapabilitiesData({ ukk: value })
  }, [value])

  return (
    <div className='flex gap-3'>
      <TextInput
        label='Tempo (min)'
        placeholder='0'
        defaultValue={value.time}
        onChange={(e) =>
          setValue((state) => ({
            ...state,
            time: +e.target.value.replace(/\D/, ''),
          }))
        }
      />
      <TextInput
        label='Frequência Cardíaca Final (bpm)'
        placeholder='0'
        defaultValue={value.heartRate}
        onChange={(e) =>
          setValue((state) => ({
            ...state,
            heartRate: +e.target.value.replace(/\D/, ''),
          }))
        }
      />
    </div>
  )
}
