import { useCallback } from 'react'

import { useAtom } from '~/observers/jotai'
import { computeAgeService } from '~/services/computeAgeService'

import { evaluationAtom } from '../evalutation/data'
import { hemodynamicMeasurementsAtom } from './data'
import { updateHemodynamicMeasurementsData } from './services'

export function useHemodynamicMeasurements() {
  const [evaluation] = useAtom(evaluationAtom)
  const [data] = useAtom(hemodynamicMeasurementsAtom)

  const calcFreqCardiacaReserva = useCallback(() => {
    if (!data?.maxHeartFrequency || !data.restingHeartFrequency) return 0
    return data.maxHeartFrequency - data.restingHeartFrequency
  }, [data])

  const calcFreqCardiacaReservaNaAgua = useCallback(() => {
    if (!data?.maxHeartFrequency || !data.restingHeartFrequencyInWater) return 0
    return data.maxHeartFrequency - data.restingHeartFrequencyInWater
  }, [data])

  const calcClassificacaoDaPressaoAterial = useCallback((): Classification => {
    if (!data || Object.keys(data).length === 0)
      return {
        color: 'none',
        label: '',
      }

    let result = ''
    let color: Color = 'lime'

    if (data.sistolicPressure < 120 && data.diastolicPressure < 80) {
      result = 'Ótima'
    } else if (data.sistolicPressure < 129 && data.diastolicPressure < 84) {
      result = 'Normal'
      color = 'green'
    } else if (data.sistolicPressure < 139 && data.diastolicPressure < 89) {
      result = 'Normal Limítrofe'
      color = 'orange'
    } else if (data.sistolicPressure < 159 && data.diastolicPressure < 99) {
      result = 'Hipertensão leve'
      color = 'amber'
    } else if (data.sistolicPressure < 179 && data.diastolicPressure < 109) {
      result = 'Hipertensão moderada'
      color = 'red'
    } else if (data.sistolicPressure >= 180 && data.diastolicPressure >= 110) {
      result = ' Hipertensão Grave'
      color = 'purple'
    }

    if (data.sistolicPressure >= 140 && data.diastolicPressure >= 90) {
      result += 'Hipertensão Sistólica Isolada'
      color = 'purple'
    }

    return {
      label: result,
      color,
    }
  }, [data])

  const freqCardiacaMaximaSugerida = useCallback(() => {
    if (!evaluation?.student) return 0
    const age = computeAgeService(evaluation?.student.birthday)
    updateHemodynamicMeasurementsData({ maxHeartFrequency: 220 - age })
  }, [evaluation])

  return {
    data,
    freqCardiacaMaximaSugerida,
    calcClassificacaoDaPressaoAterial,
    calcFreqCardiacaReserva,
    calcFreqCardiacaReservaNaAgua,
  }
}
