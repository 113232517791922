import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '~/components/Button'
import { QuestionFrame } from '~/components/QuestionFrame'
import { RadioButtonArray } from '~/components/RadioButtonArray'
import { anamnesisAtom } from '~/modules/anamnesis/data'
import {
  patchAnamnesis,
  updateAnamnesisData,
} from '~/modules/anamnesis/services'
import { stepCounterAtom } from '~/modules/evalutation/data'
import { useAtom } from '~/observers/jotai'
import { antropometryRoute } from '~/routes/routes'
import { EvaluationRouteParams } from '~/routes/types'

import { formsLayoutContext } from '../../../../layout'
import { StressFormType, stressFormSchema } from './schema'
import { questionsRefs } from './variables'

export const StressForm: React.FC = () => {
  const [defaultValues] = useAtom(anamnesisAtom)
  const [loading, setLoading] = useState<boolean>(false)
  const { evaluation_id, student_id } = useParams<EvaluationRouteParams>()
  const navigate = useNavigate()
  const { scrollToTop } = useContext(formsLayoutContext)

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<StressFormType>({
    defaultValues,
    resolver: zodResolver(stressFormSchema),
  })

  const handleFormSubmit = useCallback(
    async (data: StressFormType) => {
      if (!student_id || !evaluation_id) return
      setLoading(true)

      const success = await patchAnamnesis(
        student_id,
        evaluation_id,
        'stress',
        data,
      )
      if (success) {
        scrollToTop()
        navigate(
          antropometryRoute
            .replace(':student_id', student_id)
            .replace(':evaluation_id', evaluation_id),
          {
            replace: true,
          },
        )

        stepCounterAtom.get() < 1 && stepCounterAtom.set(1)
      }

      setLoading(false)
    },
    [student_id, evaluation_id, navigate, scrollToTop],
  )

  const handleGoBack = useCallback(() => {
    navigate('?subform=fatorDeRiscoCoronariano', {
      replace: true,
    })
    scrollToTop()
  }, [navigate, scrollToTop])

  useEffect(() => {
    const { unsubscribe } = watch((data) => {
      updateAnamnesisData(data as any)
    })

    return () => {
      unsubscribe()
    }
  }, [watch])

  useEffect(() => {
    const keys = Object.keys(errors) as (keyof StressFormType)[]
    if (keys[0]) {
      questionsRefs.get(keys[0])?.current?.scrollIntoView({ block: 'center' })
    }
  }, [errors])

  return (
    <div>
      <form
        className='flex flex-col gap-6'
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <h3 className='font-semibold'>Você tem sofrido algum desses males?</h3>
        <div className='grid grid-cols-[repeat(auto-fill,_minmax(16rem,_1fr))] justify-center gap-6'>
          <QuestionFrame
            ref={questionsRefs.get('lackingAppetite')}
            errors={!!errors.lackingAppetite}
            title='1) Falta de apetite'
            isValid={
              stressFormSchema
                .pick({ lackingAppetite: true })
                .safeParse({ lackingAppetite: watch('lackingAppetite') })
                .success
            }
          >
            <Controller
              control={control}
              name='lackingAppetite'
              render={({ field }) => (
                <RadioButtonArray
                  value={field.value}
                  onChange={field.onChange}
                  options={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false },
                  ]}
                />
              )}
            />
            <span className='text-xs text-gpa-red'>
              {errors?.lackingAppetite?.message}
            </span>
          </QuestionFrame>
          <QuestionFrame
            ref={questionsRefs.get('highRestingHeartRate')}
            errors={!!errors.highRestingHeartRate}
            title='2) F.C. elevada em repouso'
            isValid={
              stressFormSchema.pick({ highRestingHeartRate: true }).safeParse({
                highRestingHeartRate: watch('highRestingHeartRate'),
              }).success
            }
          >
            <Controller
              control={control}
              name='highRestingHeartRate'
              render={({ field }) => (
                <RadioButtonArray
                  value={field.value}
                  onChange={field.onChange}
                  options={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false },
                  ]}
                />
              )}
            />
            <span className='text-xs text-gpa-red'>
              {errors?.highRestingHeartRate?.message}
            </span>
          </QuestionFrame>
          <QuestionFrame
            ref={questionsRefs.get('highBloodPressure')}
            errors={!!errors.highBloodPressure}
            title='3) Pressão arterial alta'
            isValid={
              stressFormSchema.pick({ highBloodPressure: true }).safeParse({
                highBloodPressure: watch('highBloodPressure'),
              }).success
            }
          >
            <Controller
              control={control}
              name='highBloodPressure'
              render={({ field }) => (
                <RadioButtonArray
                  value={field.value}
                  onChange={field.onChange}
                  options={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false },
                  ]}
                />
              )}
            />
            <span className='text-xs text-gpa-red'>
              {errors?.highBloodPressure?.message}
            </span>
          </QuestionFrame>
          <QuestionFrame
            ref={questionsRefs.get('gastrointestinalDysfunction')}
            errors={!!errors.gastrointestinalDysfunction}
            title='4) Disfunções gastro-intestinais'
            isValid={
              stressFormSchema
                .pick({ gastrointestinalDysfunction: true })
                .safeParse({
                  gastrointestinalDysfunction: watch(
                    'gastrointestinalDysfunction',
                  ),
                }).success
            }
          >
            <Controller
              control={control}
              name='gastrointestinalDysfunction'
              render={({ field }) => (
                <RadioButtonArray
                  value={field.value}
                  onChange={field.onChange}
                  options={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false },
                  ]}
                />
              )}
            />
            <span className='text-xs text-gpa-red'>
              {errors?.gastrointestinalDysfunction?.message}
            </span>
          </QuestionFrame>
          <QuestionFrame
            ref={questionsRefs.get('muscularDamage')}
            errors={!!errors.muscularDamage}
            title='5) Lesões musculares constantes'
            isValid={
              stressFormSchema.pick({ muscularDamage: true }).safeParse({
                muscularDamage: watch('muscularDamage'),
              }).success
            }
          >
            <Controller
              control={control}
              name='muscularDamage'
              render={({ field }) => (
                <RadioButtonArray
                  value={field.value}
                  onChange={field.onChange}
                  options={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false },
                  ]}
                />
              )}
            />
            <span className='text-xs text-gpa-red'>
              {errors?.muscularDamage?.message}
            </span>
          </QuestionFrame>
          <QuestionFrame
            ref={questionsRefs.get('lackingFocus')}
            errors={!!errors.lackingFocus}
            title='6) Falta de concentração'
            isValid={
              stressFormSchema.pick({ lackingFocus: true }).safeParse({
                lackingFocus: watch('lackingFocus'),
              }).success
            }
          >
            <Controller
              control={control}
              name='lackingFocus'
              render={({ field }) => (
                <RadioButtonArray
                  value={field.value}
                  onChange={field.onChange}
                  options={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false },
                  ]}
                />
              )}
            />
            <span className='text-xs text-gpa-red'>
              {errors?.lackingFocus?.message}
            </span>
          </QuestionFrame>
          <QuestionFrame
            ref={questionsRefs.get('insomnia')}
            errors={!!errors.insomnia}
            title='7) Insônia'
            isValid={
              stressFormSchema.pick({ insomnia: true }).safeParse({
                insomnia: watch('insomnia'),
              }).success
            }
          >
            <Controller
              control={control}
              name='insomnia'
              render={({ field }) => (
                <RadioButtonArray
                  value={field.value}
                  onChange={field.onChange}
                  options={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false },
                  ]}
                />
              )}
            />
            <span className='text-xs text-gpa-red'>
              {errors?.insomnia?.message}
            </span>
          </QuestionFrame>
          <QuestionFrame
            ref={questionsRefs.get('lowerLibido')}
            errors={!!errors.lowerLibido}
            title='8) Diminuição da libido'
            isValid={
              stressFormSchema.pick({ lowerLibido: true }).safeParse({
                lowerLibido: watch('lowerLibido'),
              }).success
            }
          >
            <Controller
              control={control}
              name='lowerLibido'
              render={({ field }) => (
                <RadioButtonArray
                  value={field.value}
                  onChange={field.onChange}
                  options={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false },
                  ]}
                />
              )}
            />
            <span className='text-xs text-gpa-red'>
              {errors?.lowerLibido?.message}
            </span>
          </QuestionFrame>
          <QuestionFrame
            ref={questionsRefs.get('irritability')}
            errors={!!errors.irritability}
            title='9) Irritabilidade'
            isValid={
              stressFormSchema.pick({ irritability: true }).safeParse({
                irritability: watch('irritability'),
              }).success
            }
          >
            <Controller
              control={control}
              name='irritability'
              render={({ field }) => (
                <RadioButtonArray
                  value={field.value}
                  onChange={field.onChange}
                  options={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false },
                  ]}
                />
              )}
            />
            <span className='text-xs text-gpa-red'>
              {errors?.irritability?.message}
            </span>
          </QuestionFrame>
        </div>

        <div className='flex justify-between'>
          <Button
            type='button'
            loading={loading}
            disabled={loading}
            onClick={handleGoBack}
          >
            <FiChevronLeft size={24} />
            <span className='mb-1'>Voltar</span>
          </Button>
          <Button loading={loading} disabled={loading}>
            <span className='mb-1'>Salvar</span>
            <FiChevronRight size={24} />
          </Button>
        </div>
      </form>
    </div>
  )
}
