import { Toaster } from '~/components/Toaster'

import { initiateSession } from './modules/authentication/services'
import { AppRouter } from './routes'
import { app_version } from './utils/version'

initiateSession()

function App() {
  return (
    <>
      <AppRouter />
      <Toaster />
      <span className='hidden'>versão {app_version}</span>
    </>
  )
}

export default App
