import { PropsWithChildren, createContext, useCallback, useRef } from 'react'

import { Header } from './Header'

type FormsLayoutContextData = {
  scrollToTop: () => void
}

export const formsLayoutContext = createContext<FormsLayoutContextData>(
  {} as FormsLayoutContextData,
)

export const FormsLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const scrollToTop = useCallback(() => {
    containerRef.current?.scrollTo({ top: 0 })
  }, [])

  return (
    <formsLayoutContext.Provider
      value={{
        scrollToTop,
      }}
    >
      <div
        ref={containerRef}
        className='flex flex-1 min-w-[280px] flex-col overflow-y-auto h-full py-7 gap-6'
      >
        <Header />
        {children}
      </div>
    </formsLayoutContext.Provider>
  )
}
