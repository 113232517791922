import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import zod from 'zod'

import { Button } from '~/components/Button'
import { TextInput } from '~/components/TextInput'
import { addExerciseToStandardizedSeries } from '~/modules/exercisesPrescriptions/services'

const schema = zod.object({
  exercise: zod
    .string()
    .trim()
    .min(3, 'Deve ter ao menos 3 caracteres')
    .max(30, 'Deve ter no máximo 30 caracteres'),
})

type FormType = {
  exercise: string
}

type Props = {
  group: string
}

export const CreateExerciseForm: React.FC<Props> = ({ group }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormType>({ resolver: zodResolver(schema) })

  const onFormSubmit = useCallback(
    (payload: FormType) => {
      addExerciseToStandardizedSeries(payload.exercise, group)
      reset()
    },
    [reset, group],
  )

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className='flex flex-wrap gap-3'>
        <TextInput
          {...register('exercise')}
          placeholder='Novo exercício para esta série'
          error={errors.exercise?.message}
        />
        <Button>Adicionar exercício à Série</Button>
      </div>
    </form>
  )
}
