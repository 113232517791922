import { AxiosError } from 'axios'

import { api } from '~/utils/api'
import { storageAuthKey } from '~/utils/storageKeys'

import { insertToast } from '../toasts/services'
import { authenticationAtom } from './data'
import { AuthenticateAdminDTO, LoginFormType } from './schema'
import { Authentication } from './types'

export function signOut() {
  localStorage.clear()
  delete api.defaults.headers.authorization
  authenticationAtom.set(undefined)
}

export function initiateSession() {
  const sessionData = localStorage.getItem(storageAuthKey)

  if (sessionData) {
    const session = JSON.parse(sessionData)
    authenticationAtom.set(session)
    api.defaults.headers.authorization = `Bearer ${session.token}`
  }
}

export async function authenticateAdmin(payload: AuthenticateAdminDTO) {
  await api
    .post<Authentication>('/admin/authenticate', payload)
    .then(({ data }) => {
      if (!data) return
      api.defaults.headers.authorization = `Bearer ${data.token}`
      localStorage.setItem(storageAuthKey, JSON.stringify(data))
      authenticationAtom.set(data)
    })
    .catch((error: AxiosError<APIError>) => {
      if (error.response?.data.message) {
        insertToast({
          title: 'Falha ao autenticar',
          duration: 3,
          message: error.response.data.message,
          type: 'error',
        })
      }
    })
}

export async function authenticatePersonalTrainer(payload: LoginFormType) {
  await api
    .post<Authentication>('personal-trainers/authenticate', payload)
    .then(({ data }) => {
      if (!data) return
      api.defaults.headers.authorization = `Bearer ${data.token}`
      localStorage.setItem(storageAuthKey, JSON.stringify(data))
      authenticationAtom.set(data)
    })
    .catch((error) => {
      console.log(error)
      insertToast({
        title: 'Falha ao autenticar',
        duration: 3,
        message: error.response.data.message,
        type: 'error',
      })
    })
}
