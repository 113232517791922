import { useCallback, useEffect } from 'react'

import { useAtom } from '~/observers/jotai'
import { swapItemsInArrayService } from '~/services/swapItemsInArrayService'
import { WeekDays } from '~/types/types'
import { getWeekdayIndex } from '~/utils/weekdays'

import { standardizedSeriesAtom } from '../standardizedSeries/data'
import { getIntensityRange } from '../standardizedSeries/services'
import { StandardizedSeries } from '../standardizedSeries/types'
import { schedulerAtom } from './data'
import { updateSchedulerData } from './services'
import { MoveActivityDirection } from './types'

export function useScheduler() {
  const [data] = useAtom(schedulerAtom)

  const toggleActivityInWeek = useCallback(
    (day: WeekDays | number, activity: string) => {
      const index = typeof day === 'number' ? day : getWeekdayIndex(day)

      if (data?.activityWeek[index]) {
        const newArr = [...data.activityWeek]
        const activityIndex = newArr[index]?.findIndex(
          (item) => item.name === activity,
        )
        if (activityIndex !== undefined && activityIndex >= 0) {
          newArr[index]?.splice(activityIndex, 1)
          updateSchedulerData({ activityWeek: newArr })
        } else {
          newArr[index]?.push({ name: activity })
          updateSchedulerData({ activityWeek: newArr })
        }
      } else {
        const newArr = [...(data?.activityWeek || [])]
        newArr[index] = [{ name: activity }]
        updateSchedulerData({ activityWeek: newArr })
      }
    },

    [data?.activityWeek],
  )

  const moveItemInList = useCallback(
    (direction: MoveActivityDirection, index: number, dayIndex: number) => {
      if (
        (direction === 'up' && index === 0) ||
        (direction === 'down' &&
          data?.activityWeek[dayIndex]?.length === index + 1)
      ) {
        return
      }

      if (data?.activityWeek) {
        const newArr = [...data.activityWeek]
        direction === 'up'
          ? swapItemsInArrayService(newArr[dayIndex], index, index - 1)
          : swapItemsInArrayService(newArr[dayIndex], index, index + 1)
        updateSchedulerData({ activityWeek: newArr })
      }
    },
    [data?.activityWeek],
  )

  const removeItemInList = useCallback(
    (index: number, dayIndex: number) => {
      if (!data?.activityWeek[dayIndex]) return
      const newArr = [...data.activityWeek]
      newArr[dayIndex]?.splice(index, 1)
      updateSchedulerData({ activityWeek: newArr })
    },
    [data?.activityWeek],
  )

  useEffect(() => {
    if (!standardizedSeriesAtom.get()) return
    const series = [...(standardizedSeriesAtom.get()?.series || [])]

    if (series.length >= (data?.numSeries || 0)) {
      for (let i = series.length; i > (data?.numSeries || 0); i--) {
        series.pop()
      }
    } else {
      const letters = ['A', 'B', 'C', 'D', 'E']
      for (let i = 0; i < (data?.numSeries || 0); i++) {
        if (!series[i]) {
          series[i] = {
            letter: letters[i],
            description: '',
            intensity: getIntensityRange('Endurance')[0],
            type: 'Endurance',
            exercises: [],
          }
        }
      }
    }

    standardizedSeriesAtom.set({
      ...(standardizedSeriesAtom.get() as StandardizedSeries),
      series,
    })
  }, [data?.numSeries])

  return {
    data,
    toggleActivityInWeek,
    moveItemInList,
    removeItemInList,
  }
}
