import { Option } from '~/types/types'

export const defaultActivities = [
  'Trabalho de Flexibilidade',
  'Trabalho de Coordenação',
  'Massagem',
  'Circuito Aeróbico',
  'Circuito de RML',
  'Andar',
  'Força Dinâmica',
  'Força Pura',
  'Força Explosiva',
  'Pedalar',
  'Correr',
  'Hidroginástica',
]

export const strengthExercisesAmountOptions: Option<number>[] = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
]
