import { TextInput } from '~/components/TextInput'
import { antropometryAtom } from '~/modules/antropometry/data'
import { updateCorporalEvaluationData } from '~/modules/corporalEvaluation/services'
import { useCorporalEvaluation } from '~/modules/corporalEvaluation/useCorporalEvaluation'
import { useAtom } from '~/observers/jotai'

export const PenroeNelsonFisher: React.FC = () => {
  const [antropometry] = useAtom(antropometryAtom)
  const { data } = useCorporalEvaluation()

  return (
    <div className='flex gap-3'>
      <TextInput
        label='Abdômen (cm)'
        placeholder='0,0'
        value={data?.abdomen || antropometry?.abdomen}
        onChange={(e) =>
          updateCorporalEvaluationData({
            abdomen: +e.target.value.replace(/[\D]/, ''),
          })
        }
      />
      <TextInput
        label='Quadril (cm)'
        value={data?.waist || antropometry?.waist}
        placeholder='0,0'
        onChange={(e) =>
          updateCorporalEvaluationData({
            waist: +e.target.value.replace(/[\D]/, ''),
          })
        }
      />
    </div>
  )
}
