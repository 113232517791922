import { useCallback, useContext, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '~/components/Button'
import { saveStandardizedSeries } from '~/modules/standardizedSeries/services'
import { usePeriodizedSeries } from '~/modules/standardizedSeries/usePeriodizedSeries'
import {
  exercisesPrescriptionsRoute,
  studentEvaluationsRoute,
} from '~/routes/routes'
import { EvaluationRouteParams } from '~/routes/types'

import { formsLayoutContext } from '../../layout'
import { EditModal } from './compoenents/EditModal'

export const SeriesEdit: React.FC = () => {
  const navigate = useNavigate()
  const { scrollToTop } = useContext(formsLayoutContext)
  const { evaluation_id, student_id } = useParams<EvaluationRouteParams>()
  const [loading, setLoading] = useState<boolean>(false)
  const { data } = usePeriodizedSeries()

  const handleSave = useCallback(async () => {
    if (!evaluation_id || !student_id) return
    setLoading(true)
    await saveStandardizedSeries(student_id, evaluation_id)
    setLoading(false)
    navigate({
      pathname:
        studentEvaluationsRoute
          .replace(':evaluation_id', evaluation_id)
          .replace(':student_id', student_id) + '/macrociclos',
    })
  }, [evaluation_id, student_id, navigate])

  const handleGoBack = useCallback(() => {
    if (!student_id || !evaluation_id) return
    navigate(
      exercisesPrescriptionsRoute
        .replace(':student_id', student_id)
        .replace(':evaluation_id', evaluation_id),
      {
        replace: true,
      },
    )
    scrollToTop()
  }, [navigate, scrollToTop, student_id, evaluation_id])

  return (
    <div className='flex flex-col px-6 py-9 gap-6 rounded-2xl bg-white'>
      {data?.series.map((series, seriesIndex) => (
        <div
          key={`data-test-${series.letter}`}
          className='flex flex-col gap-4 border border-slate-200 rounded-xl p-4'
        >
          <div className='flex flex-wrap gap-4'>
            <div className='flex flex-col shrink-0'>
              <h2 className='text-xl'>Série {series.letter}</h2>
              <p>Descrição: {series.description || '*sem descrição*'}</p>
            </div>
          </div>
          <div className='flex flex-col gap-6'>
            {series.exercises.map((exercise, exerciseIndex) => (
              <div
                key={`series-${series.letter}-${exercise.name.replace(
                  ' ',
                  '-',
                )}`}
                className='flex flex-col bg-gpa-blue-50 p-4 rounded-lg border border-slate-400'
              >
                <div className=''>
                  <span className='text-lg font-semibold'>{exercise.name}</span>
                </div>
                <div className='flex flex-col gap-4'>
                  <div className='flex flex-wrap gap-3'>
                    <span>Carga Inicial {exercise.initialCharge}</span>
                    <span>Carga Máxima {exercise.maximumCharge}</span>
                    <span>Séries {exercise.numSeries}</span>
                    <span>Repetições {exercise.repetitions}</span>
                    <span>Intervalo {exercise.interval}</span>
                    <span>Velocidade {exercise.speed}</span>
                    <span>Ajuste/Obs {exercise.adjustments}</span>
                  </div>

                  <div className='flex flex-wrap gap-3 self-end'>
                    <EditModal
                      letter={series.letter}
                      exercise={exercise}
                      seriesIndex={seriesIndex}
                      exerciseIndex={exerciseIndex}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}

      <div className='flex justify-between w-full'>
        <Button
          type='button'
          loading={loading}
          disabled={loading}
          onClick={handleGoBack}
        >
          <FiChevronLeft size={24} />
          <span className='mb-1'>Voltar</span>
        </Button>
        <Button
          loadingMessage='Salvando...'
          loading={loading}
          disabled={loading}
          onClick={handleSave}
        >
          <span className='mb-1'>Ir para plano de treino</span>
          <FiChevronRight size={24} />
        </Button>
      </div>
    </div>
  )
}
