import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '~/components/Button'
import { QuestionFrame } from '~/components/QuestionFrame'
import { SelectInput } from '~/components/SelectInput'
import { TextInput } from '~/components/TextInput'
import {
  anamnesisAtom,
  anamnesisStepCounterAtom,
} from '~/modules/anamnesis/data'
import {
  patchAnamnesis,
  updateAnamnesisData,
} from '~/modules/anamnesis/services'
import { useCoronarianRiskFactorFormula } from '~/modules/anamnesis/useCoronarianRiskFactorFormula'
import { useAtom } from '~/observers/jotai'
import { EvaluationRouteParams } from '~/routes/types'

import { formsLayoutContext } from '../../../../layout'
import {
  CoronarianRiskFactorFormType,
  coronarianRiskFactorSchema,
} from './schema'
import {
  cholesterolEnum,
  dailyPhysicalActivityEnum,
  hereditarianismEnum,
  questionsRefs,
  sexAndAgeEnum,
  smokeEnum,
  weightEnum,
} from './variables'

export const CoronarianRiskFactorForm: React.FC = () => {
  const [defaultValues] = useAtom(anamnesisAtom)
  const { classificarPressaoArterial } = useCoronarianRiskFactorFormula()
  const [loading, setLoading] = useState<boolean>(false)
  const { evaluation_id, student_id } = useParams<EvaluationRouteParams>()
  const navigate = useNavigate()
  const { scrollToTop } = useContext(formsLayoutContext)

  const {
    control,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CoronarianRiskFactorFormType>({
    defaultValues,
    shouldFocusError: false,
    resolver: zodResolver(coronarianRiskFactorSchema),
  })

  const handleFormSubmit = useCallback(
    async (data: CoronarianRiskFactorFormType) => {
      if (!student_id || !evaluation_id) return
      setLoading(true)

      const success = await patchAnamnesis(
        student_id,
        evaluation_id,
        'coronarian-risk-factor',
        data,
      )
      if (success) {
        scrollToTop()
        navigate('?subform=stress', {
          replace: true,
        })
        anamnesisStepCounterAtom.get() < 3 && anamnesisStepCounterAtom.set(3)
      }

      setLoading(false)
    },
    [student_id, evaluation_id, navigate, scrollToTop],
  )

  const handleGoBack = useCallback(() => {
    navigate('?subform=parQ', {
      replace: true,
    })
    scrollToTop()
  }, [navigate, scrollToTop])

  useEffect(() => {
    const { unsubscribe } = watch((data) => {
      updateAnamnesisData(data as any)
    })

    return () => {
      unsubscribe()
    }
  }, [watch])

  useEffect(() => {
    const keys = Object.keys(errors) as (keyof CoronarianRiskFactorFormType)[]
    if (keys[0]) {
      questionsRefs.get(keys[0])?.current?.scrollIntoView({ block: 'center' })
    }
  }, [errors])

  return (
    <div>
      <form
        className='flex flex-col gap-6'
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <QuestionFrame
          title='1) Atividade física realizada no dia a dia'
          ref={questionsRefs.get('dailyPhysicalActivity')}
          errors={!!errors.dailyPhysicalActivity}
          isValid={
            coronarianRiskFactorSchema
              .pick({
                dailyPhysicalActivity: true,
              })
              .safeParse({
                dailyPhysicalActivity: watch('dailyPhysicalActivity'),
              }).success
          }
        >
          <Controller
            control={control}
            name='dailyPhysicalActivity'
            render={({ field }) => (
              <SelectInput
                placeholder='Selecione um valor'
                error={errors.dailyPhysicalActivity?.message}
                value={field.value}
                onChange={field.onChange}
                options={dailyPhysicalActivityEnum.map((option) => ({
                  label: option,
                  value: option,
                }))}
              />
            )}
          />
        </QuestionFrame>
        <QuestionFrame
          title='2) Peso'
          ref={questionsRefs.get('weight')}
          errors={!!errors.weight}
          isValid={
            coronarianRiskFactorSchema
              .pick({
                weight: true,
              })
              .safeParse({ weight: watch('weight') }).success
          }
        >
          <Controller
            control={control}
            name='weight'
            render={({ field }) => (
              <SelectInput
                placeholder='Selecione um valor'
                value={field.value}
                onChange={field.onChange}
                error={errors.weight?.message}
                options={weightEnum.map((option) => ({
                  label: option,
                  value: option,
                }))}
              />
            )}
          />
        </QuestionFrame>
        <QuestionFrame
          ref={questionsRefs.get('sexAndAge')}
          errors={!!errors.sexAndAge}
          title='3) Sexo e idade (Características sexuais)'
          isValid={
            coronarianRiskFactorSchema
              .pick({
                sexAndAge: true,
              })
              .safeParse({ sexAndAge: watch('sexAndAge') }).success
          }
        >
          <Controller
            control={control}
            name='sexAndAge'
            render={({ field }) => (
              <SelectInput
                placeholder='Selecione um valor'
                error={errors.sexAndAge?.message}
                value={field.value}
                onChange={field.onChange}
                options={sexAndAgeEnum.map((option) => ({
                  label: option,
                  value: option,
                }))}
              />
            )}
          />
        </QuestionFrame>
        <QuestionFrame
          ref={questionsRefs.get('smoke')}
          errors={!!errors.smoke}
          title='4) Fumo'
          isValid={
            coronarianRiskFactorSchema
              .pick({
                smoke: true,
              })
              .safeParse({ smoke: watch('smoke') }).success
          }
        >
          <Controller
            control={control}
            name='smoke'
            render={({ field }) => (
              <SelectInput
                placeholder='Selecione um valor'
                value={field.value}
                onChange={field.onChange}
                error={errors.smoke?.message}
                options={smokeEnum.map((option) => ({
                  label: option,
                  value: option,
                }))}
              />
            )}
          />
        </QuestionFrame>
        <QuestionFrame
          ref={questionsRefs.get('arterialSystolicPressure')}
          errors={!!errors.arterialSystolicPressure}
          title='5) Pressão arterial sistólica'
          isValid={
            coronarianRiskFactorSchema
              .pick({
                arterialSystolicPressure: true,
              })
              .safeParse({
                arterialSystolicPressure: watch('arterialSystolicPressure'),
              }).success
          }
        >
          <div className='flex flex-row px-4 border-500 focus-within:border-gpa-blue-500 py-2 h-[48px] border-2 rounded-md gap-2 min-w-[244px]'>
            {classificarPressaoArterial(
              watch('arterialSystolicPressure.systolic'),
            )}
          </div>
          <div className='flex gap-4 flex-wrap'>
            <Controller
              control={control}
              name='arterialSystolicPressure.systolic'
              render={({ field }) => (
                <TextInput
                  defaultValue={field.value}
                  onChange={(e) => {
                    field.onChange(Number(e.target.value))
                  }}
                  type='number'
                  label='Pressão Sistólica'
                  error={errors.arterialSystolicPressure?.systolic?.message}
                />
              )}
            />
            <TextInput
              {...register('arterialSystolicPressure.diastolic', {
                valueAsNumber: true,
              })}
              type='number'
              label='Pressão Diastólica'
              error={errors.arterialSystolicPressure?.diastolic?.message}
            />
          </div>
        </QuestionFrame>
        <QuestionFrame
          ref={questionsRefs.get('hereditarianism')}
          errors={!!errors.hereditarianism}
          title='6) Hereditariedade'
          isValid={
            coronarianRiskFactorSchema
              .pick({
                hereditarianism: true,
              })
              .safeParse({ hereditarianism: watch('hereditarianism') }).success
          }
        >
          <Controller
            control={control}
            name='hereditarianism'
            render={({ field }) => (
              <SelectInput
                placeholder='Selecione um valor'
                value={field.value}
                onChange={field.onChange}
                error={errors.hereditarianism?.message}
                options={hereditarianismEnum.map((option) => ({
                  label: option,
                  value: option,
                }))}
              />
            )}
          />
        </QuestionFrame>
        <QuestionFrame
          ref={questionsRefs.get('cholesterol')}
          errors={!!errors.cholesterol}
          title='7) Colesterol ou percentual de gordura na dieta'
          isValid={
            coronarianRiskFactorSchema
              .pick({
                cholesterol: true,
              })
              .safeParse({ cholesterol: watch('cholesterol') }).success
          }
        >
          <Controller
            control={control}
            name='cholesterol'
            render={({ field }) => (
              <SelectInput
                placeholder='Selecione um valor'
                value={field.value}
                onChange={field.onChange}
                error={errors.cholesterol?.message}
                options={cholesterolEnum.map((option) => ({
                  label: option,
                  value: option,
                }))}
              />
            )}
          />
        </QuestionFrame>

        <div className='flex justify-between'>
          <Button
            type='button'
            loading={loading}
            disabled={loading}
            onClick={handleGoBack}
          >
            <FiChevronLeft size={24} />
            <span className='mb-1'>Voltar</span>
          </Button>
          <Button loading={loading} disabled={loading}>
            <span className='mb-1'>Salvar</span>
            <FiChevronRight size={24} />
          </Button>
        </div>
      </form>
    </div>
  )
}
