import zod from 'zod'

export const schema = zod.object({
  exerciseGroup: zod.enum([
    'Panturrilha',
    'Coxas e gluteos',
    'Abdomen',
    'Peito',
    'Costas',
    'Ombro',
    'Biceps',
    'Triceps',
    'Antebraço',
    'Outros',
  ] as const),
  exercise: zod
    .string()
    .trim()
    .min(3, 'Precisa ter no mínimo 3 caracteres')
    .max(15, 'Um limite de 15 caracteres é permitido'),
})

export type FormType = zod.infer<typeof schema>
