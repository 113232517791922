import { ChangeEvent, useCallback, useContext, useRef, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '~/components/Button'
import { QuestionFrame } from '~/components/QuestionFrame'
import { TextInput } from '~/components/TextInput'
import { floatMask } from '~/components/TextInput/utils/mask'
import {
  saveAerobicCapabilities,
  updateAerobicCapabilitiesData,
} from '~/modules/aerobicCapabilities/services'
import { useAreobicCapabilities } from '~/modules/aerobicCapabilities/useAerobicCapabilities'
import {
  aerobicPrescriptionRoute,
  hemodynamicMeasuresRoute,
} from '~/routes/routes'
import { EvaluationRouteParams } from '~/routes/types'

import { formsLayoutContext } from '../../layout'
import { Protocols } from './protocols'

export const AerobicCapabilitiesForm: React.FC = () => {
  const navigate = useNavigate()
  const { scrollToTop } = useContext(formsLayoutContext)
  const suggestionRef = useRef<HTMLInputElement>(null)
  const { evaluation_id, student_id } = useParams<EvaluationRouteParams>()
  const [loading, setLoading] = useState<boolean>(false)
  const {
    vo2,
    basal,
    active,
    moderatelyActive,
    foreseenVO2,
    starter,
    classification,
    reserved,
    METSMax,
    highlightIndex,
    suggestVO2,
    data,
  } = useAreobicCapabilities()

  const handleSuggestTargetValue = useCallback(() => {
    suggestionRef.current!.value = suggestVO2().toFixed(1)
    updateAerobicCapabilitiesData({ targetVO2: suggestVO2() })
  }, [suggestVO2])

  const handleChangeTargetValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      suggestionRef.current!.value = floatMask(e.target.value)
      updateAerobicCapabilitiesData({
        targetVO2: +floatMask(e.target.value),
      })
    },
    [],
  )

  const handleSave = useCallback(() => {
    if (!student_id || !evaluation_id) return
    saveAerobicCapabilities(student_id, evaluation_id).then((success) => {
      setLoading(false)
      if (success) {
        scrollToTop()
        navigate(
          aerobicPrescriptionRoute
            .replace(':student_id', student_id)
            .replace(':evaluation_id', evaluation_id),
          { replace: true },
        )
      }
    })
  }, [evaluation_id, student_id, navigate, scrollToTop])

  const handleGoBack = useCallback(() => {
    if (!student_id || !evaluation_id) return
    navigate(
      hemodynamicMeasuresRoute
        .replace(':student_id', student_id)
        .replace(':evaluation_id', evaluation_id),
      {
        replace: true,
      },
    )
    scrollToTop()
  }, [navigate, scrollToTop, student_id, evaluation_id])

  return (
    <div className='flex flex-col px-6 py-9 gap-6 rounded-2xl bg-white'>
      <QuestionFrame title='Capacidade Aeróbica' isValid={false}>
        <Protocols />

        <p>{data?.protocol}</p>

        <div className='flex flex-col gap-3'>
          <p className='text-black'>VO2</p>
          <div className='flex w-full justify-center items-center gap-3'>
            <div className='relative bg-gpa-gray-500 rounded-lg py-1 flex items-center justify-center flex-col w-full overflow-hidden'>
              <div
                className={`absolute inset-0 w-1/2 bg-${classification.color}-500`}
                style={{ width: classification.completion + '%' }}
              />
              <span className='z-0 font-bold'>
                {classification.label} ({classification.index}/5)
              </span>
            </div>
          </div>
          <div className='flex w-full gap-3 justify-center flex-wrap'>
            <div className='flex gap-2 flex-wrap'>
              <div className='flex flex-1'>
                <span className='py-1 px-4 bg-gpa-gray-500 w-full'>Medido</span>
                <span className='py-1 px-4 bg-gpa-gray-100 w-full'>
                  {vo2.toFixed(2)}
                </span>
              </div>
              <div className='flex flex-1'>
                <span className='py-1 px-4 bg-gpa-gray-500 w-full'>
                  Previsto
                </span>
                <span className='py-1 px-4 bg-gpa-gray-100 w-full'>
                  {+foreseenVO2.toFixed(2)}
                </span>
              </div>
            </div>

            <div className='flex gap-2 flex-wrap'>
              <div className='flex flex-1'>
                <span className='py-1 px-4 bg-gpa-gray-500 w-full'>
                  Reserva
                </span>
                <span className='py-1 px-4 bg-gpa-gray-100 w-full'>
                  {reserved.toFixed(2)}
                </span>
              </div>
              <div className='flex flex-1'>
                <span className='py-1 px-4 bg-gpa-gray-500 w-full whitespace-nowrap'>
                  METS Máximo
                </span>
                <span className='py-1 px-4 bg-gpa-gray-100 w-full flex-1'>
                  {METSMax.toFixed(1)}
                </span>
              </div>
            </div>
          </div>

          <div className='flex md:items-center md:justify-between mt-6 md:flex-row flex-col flex-wrap'>
            <div className='flex flex-col gap-3'>
              <p className='font-bold'>Taxa Metabólica Diária</p>

              <div className='flex gap-3 flex-wrap'>
                <div className='flex flex-col'>
                  <span className='px-4 py-1'>Basal</span>
                  <span className='px-4 py-1'>{basal}</span>
                </div>
                <div className='flex overflow-x-auto'>
                  <table className='min-w-[200px]'>
                    <tbody>
                      <tr
                        className={`${
                          highlightIndex === 0
                            ? 'bg-blue-300'
                            : 'bg-gpa-gray-500'
                        }`}
                      >
                        <td className='px-4 py-1'>Iniciante</td>
                        <td
                          className={`${
                            highlightIndex === 0
                              ? 'bg-blue-200'
                              : 'bg-gpa-gray-100'
                          } px-4 `}
                        >
                          {starter}
                        </td>
                      </tr>
                      <tr
                        className={`${
                          highlightIndex === 1
                            ? 'bg-blue-300'
                            : 'bg-gpa-gray-500'
                        }`}
                      >
                        <td className='px-4 py-1'>Moderadamente Ativo</td>
                        <td
                          className={`${
                            highlightIndex === 1
                              ? 'bg-blue-200'
                              : 'bg-gpa-gray-100'
                          } px-4 `}
                        >
                          {moderatelyActive}
                        </td>
                      </tr>
                      <tr
                        className={`${
                          highlightIndex === 2
                            ? 'bg-blue-300'
                            : 'bg-gpa-gray-500'
                        }`}
                      >
                        <td className='px-4 py-1'>Ativo</td>
                        <td
                          className={`${
                            highlightIndex === 2
                              ? 'bg-blue-200'
                              : 'bg-gpa-gray-100'
                          } px-4 `}
                        >
                          {active}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className='flex items-center gap-3 mt-4 ml-2 min-w-[100px]'>
              <div className='self-start mb-5 px-2'>
                <TextInput
                  ref={suggestionRef}
                  label='VO2 alvo'
                  defaultValue={data?.targetVO2.toFixed(2)}
                  onChange={handleChangeTargetValue}
                />
              </div>
              <Button type='button' onClick={handleSuggestTargetValue}>
                Sugerir
              </Button>
            </div>
          </div>
        </div>
      </QuestionFrame>
      <div className='flex justify-between'>
        <Button
          type='button'
          loading={loading}
          disabled={loading}
          onClick={handleGoBack}
        >
          <FiChevronLeft size={24} />
          <span className='mb-1'>Voltar</span>
        </Button>
        <Button
          loadingMessage='Salvando...'
          loading={loading}
          disabled={loading}
          onClick={handleSave}
        >
          <span className='mb-1'>Salvar</span>
          <FiChevronRight size={24} />
        </Button>
      </div>
    </div>
  )
}
