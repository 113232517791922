import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { FiX } from 'react-icons/fi'

import { Button } from '~/components/Button'
import { ExerciseTag } from '~/components/ExerciseTag'
import { Modal } from '~/components/Modal'
import { TextInput } from '~/components/TextInput'
import {
  recoverStandardAerobicExercises,
  removeExercise,
} from '~/modules/aerobicExercises/services'
import { useAerobicExercises } from '~/modules/aerobicExercises/useAerobicExercises'

type Props = {
  visible: boolean
  onClose: () => void
}

export const RemoveActivityModal: React.FC<Props> = ({ visible, onClose }) => {
  const { data } = useAerobicExercises()
  const [query, setQuery] = useState<string>('')

  const handleClose = useCallback(() => {
    onClose()
    setQuery('')
  }, [onClose])

  const handleRemoveActivity = useCallback(
    (activity: string) => {
      removeExercise(activity)
      onClose()
    },
    [onClose],
  )

  const handleUpdateQuery = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value)
    },
    [],
  )

  const handleRecoverExercises = useCallback(() => {
    recoverStandardAerobicExercises()
    onClose()
  }, [onClose])

  const filteredList = useMemo(() => {
    const list = data?.exercises
    if (!query) return list
    return list?.filter((i) =>
      i
        .toLowerCase()
        .includes(query.normalize('NFD').replace(/[\u0300-\u036f]/g, '')),
    )
  }, [query, data?.exercises])

  return (
    <Modal visible={visible}>
      <div className='flex items-center justify-between mb-3'>
        <h2 className='text-xl font-medium'>Excluir Exercício</h2>
        <button
          className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
          onClick={handleClose}
        >
          <FiX size={24} />
        </button>
      </div>

      <TextInput
        onChange={handleUpdateQuery}
        placeholder='filtrar atividade por nome'
      />

      <div className='flex flex-wrap gap-3'>
        {filteredList?.map((activity, index) => (
          <ExerciseTag
            key={`remove-activity-${index}`}
            exercise={activity}
            onDelete={() => handleRemoveActivity(activity)}
          />
        ))}
      </div>
      <div className='flex flex-col w-full mt-4'>
        <Button onClick={handleRecoverExercises}>
          Recuperar exercícios padrão
        </Button>
      </div>
    </Modal>
  )
}
