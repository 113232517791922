import { useEffect, useState } from 'react'

import { TextInput } from '~/components/TextInput'
import { aerobicCapabilitiesAtom } from '~/modules/aerobicCapabilities/data'
import { updateAerobicCapabilitiesData } from '~/modules/aerobicCapabilities/services'
import { OwnIndexProtocolData } from '~/modules/aerobicCapabilities/types'

export const Polar: React.FC = () => {
  const data = aerobicCapabilitiesAtom.get()
  const [value, setValue] = useState<OwnIndexProtocolData>({
    maxVO2Consumtion: data?.ownIndex?.maxVO2Consumtion,
  })

  useEffect(() => {
    updateAerobicCapabilitiesData({ ownIndex: value })
  }, [value])

  return (
    <div className='flex gap-3'>
      <TextInput
        defaultValue={value?.maxVO2Consumtion}
        label='Consumo Máximo de VO2'
        placeholder='0'
        onChange={(e) =>
          setValue((state) => ({
            ...state,
            maxVO2Consumtion: +e.target.value.replace(/[\D]/, ''),
          }))
        }
      />
    </div>
  )
}
