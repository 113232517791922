import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '~/components/Button'
import { Modal } from '~/components/Modal'
import { updateAerobicPrescriptionsData } from '~/modules/aerobicPrescription/services'
import { useAerobicPrescription } from '~/modules/aerobicPrescription/useAerobicPrescription'
import { exercisesPrescriptionsRoute } from '~/routes/routes'
import { EvaluationRouteParams } from '~/routes/types'

export const PromptModal: React.FC = () => {
  const navigate = useNavigate()
  const { data } = useAerobicPrescription()

  const { evaluation_id, student_id } = useParams<EvaluationRouteParams>()

  const handleNavigateOut = useCallback(() => {
    if (student_id && evaluation_id) {
      navigate(
        `${exercisesPrescriptionsRoute
          .replace(':student_id', student_id)
          .replace(':evaluation_id', evaluation_id)}`,
        { replace: true },
      )
    }
  }, [navigate, student_id, evaluation_id])

  const handleCloseModal = useCallback(() => {
    updateAerobicPrescriptionsData({ hasPrescription: true })
  }, [])

  return (
    <Modal visible={!data?.hasPrescription}>
      <div className='flex flex-col gap-5'>
        <h1 className='text-lg'>Deseja prescrição aeróbica?</h1>
        <div className='flex gap-4 self-end'>
          <Button mode='outlined' className='w-28' onClick={handleNavigateOut}>
            Não
          </Button>
          <Button className='w-28' onClick={handleCloseModal}>
            Sim
          </Button>
        </div>
      </div>
    </Modal>
  )
}
