import zod from 'zod'

export const parqFormSchema = zod.object({
  bloodPressureMedication: zod.boolean({
    invalid_type_error: 'Campo obrigatório',
    required_error: 'Campo obrigatório',
  }),
  boneAche: zod.boolean({
    invalid_type_error: 'Campo obrigatório',
    required_error: 'Campo obrigatório',
  }),
  chestPain: zod.boolean({
    invalid_type_error: 'Campo obrigatório',
    required_error: 'Campo obrigatório',
  }),
  chestPainLastMonth: zod.boolean({
    invalid_type_error: 'Campo obrigatório',
    required_error: 'Campo obrigatório',
  }),
  dizziness: zod.boolean({
    invalid_type_error: 'Campo obrigatório',
    required_error: 'Campo obrigatório',
  }),
  reasonNotToExercise: zod.boolean({
    invalid_type_error: 'Campo obrigatório',
    required_error: 'Campo obrigatório',
  }),
  supervisedPhysicalActivity: zod.boolean({
    invalid_type_error: 'Campo obrigatório',
    required_error: 'Campo obrigatório',
  }),
})

export type ParQFormType = zod.infer<typeof parqFormSchema>
