import { useEffect, useState } from 'react'

import { TextInput } from '~/components/TextInput'
import { aerobicCapabilitiesAtom } from '~/modules/aerobicCapabilities/data'
import { updateAerobicCapabilitiesData } from '~/modules/aerobicCapabilities/services'
import { MargariaProtocolData } from '~/modules/aerobicCapabilities/types'

export const Margaria: React.FC = () => {
  const data = aerobicCapabilitiesAtom.get()
  const [value, setValue] = useState<MargariaProtocolData>({
    distance: data?.margaria?.distance,
    time: data?.margaria?.time,
  })

  useEffect(() => {
    updateAerobicCapabilitiesData({ margaria: value })
  }, [value])

  return (
    <div className='flex gap-3'>
      <TextInput
        label='Tempo (min)'
        placeholder='0'
        defaultValue={value?.time}
        onChange={(e) =>
          setValue((state) => ({
            ...state,
            time: +e.target.value.replace(/[\D]/, ''),
          }))
        }
      />
      <TextInput
        label='Distância (m)'
        placeholder='0'
        defaultValue={value?.distance}
        onChange={(e) =>
          setValue((state) => ({
            ...state,
            distance: +e.target.value.replace(/[\D]/, ''),
          }))
        }
      />
    </div>
  )
}
