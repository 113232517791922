import zod from 'zod'

export const stressFormSchema = zod.object({
  lackingAppetite: zod.boolean({
    invalid_type_error: 'Campo obrigatório',
    required_error: 'Campo obrigatório',
  }),
  highRestingHeartRate: zod.boolean({
    invalid_type_error: 'Campo obrigatório',
    required_error: 'Campo obrigatório',
  }),
  highBloodPressure: zod.boolean({
    invalid_type_error: 'Campo obrigatório',
    required_error: 'Campo obrigatório',
  }),
  gastrointestinalDysfunction: zod.boolean({
    invalid_type_error: 'Campo obrigatório',
    required_error: 'Campo obrigatório',
  }),
  muscularDamage: zod.boolean({
    invalid_type_error: 'Campo obrigatório',
    required_error: 'Campo obrigatório',
  }),
  lackingFocus: zod.boolean({
    invalid_type_error: 'Campo obrigatório',
    required_error: 'Campo obrigatório',
  }),
  insomnia: zod.boolean({
    invalid_type_error: 'Campo obrigatório',
    required_error: 'Campo obrigatório',
  }),
  lowerLibido: zod.boolean({
    invalid_type_error: 'Campo obrigatório',
    required_error: 'Campo obrigatório',
  }),
  irritability: zod.boolean({
    invalid_type_error: 'Campo obrigatório',
    required_error: 'Campo obrigatório',
  }),
})

export type StressFormType = zod.infer<typeof stressFormSchema>
