import { SelectInput } from '~/components/SelectInput'
import { updateCorporalEvaluationData } from '~/modules/corporalEvaluation/services'
import { CorporalEvaluationProtocol } from '~/modules/corporalEvaluation/types'
import { useCorporalEvaluation } from '~/modules/corporalEvaluation/useCorporalEvaluation'
import { evaluationAtom } from '~/modules/evalutation/data'
import { Sex } from '~/modules/students/types'
import { useAtom } from '~/observers/jotai'
import { Option } from '~/types/types'

import { CoteWilmore } from './CoteWilmore'
import { Direto } from './Direto'
import { Faulker } from './Faulker'
import { PenroeNelsonFisher } from './PenroeNelsonFisher'
import { PollockJacksonSevenFold } from './PollockJacksonSevenFold'
import { PollockJacksonTrifold } from './PollockJacksonTrifold'
import { WetmanCol } from './WetmanCol'

const protocols = new Map<CorporalEvaluationProtocol, JSX.Element>([
  ['Direto', <Direto key='protocol-direto' />],
  [
    'Pollock & Jackson 3 Dobras',
    <PollockJacksonTrifold key='protocol-jackson-trifold' />,
  ],
  [
    'Pollock & Jackson 7 Dobras',
    <PollockJacksonSevenFold key='protocol-jackson-sevenfold' />,
  ],
  [
    'Wetman e col., para pessoas obesas',
    <WetmanCol key='protocol-wetman-col' />,
  ],
  ['Faulkner', <Faulker key='protocol-faulkner' />],
  [
    'Perímetros Coté e Wilmore Masculino',
    <CoteWilmore key='protocol-wilmore' />,
  ],
  [
    'Perímetros Penroe, Nelson e Fisher (1985) Feminino',
    <PenroeNelsonFisher key='protocol-penroe' />,
  ],
])

const getOptions = (sex: Sex): Option<CorporalEvaluationProtocol>[] => [
  {
    label: 'Direto',
    value: 'Direto',
  },
  {
    label: 'Pollock & Jackson 3 Dobras',
    value: 'Pollock & Jackson 3 Dobras',
  },
  {
    label: 'Pollock & Jackson 7 Dobras',
    value: 'Pollock & Jackson 7 Dobras',
  },
  {
    label: 'Wetman e col., para pessoas obesas',
    value: 'Wetman e col., para pessoas obesas',
  },
  {
    label: 'Faulkner',
    value: 'Faulkner',
  },
  sex === 'masculino'
    ? {
        label: 'Perímetros Coté e Wilmore Masculino',
        value: 'Perímetros Coté e Wilmore Masculino',
      }
    : {
        label: 'Perímetros Penroe, Nelson e Fisher (1985) Feminino',
        value: 'Perímetros Penroe, Nelson e Fisher (1985) Feminino',
      },
]

export const Protocols: React.FC = () => {
  const [evaluation] = useAtom(evaluationAtom)
  const { data } = useCorporalEvaluation()

  return (
    <div className='flex flex-col flex-1'>
      <SelectInput
        label='Protocolo'
        value={data?.protocol}
        options={getOptions(evaluation?.student.sex ?? 'masculino')}
        onChange={(v: CorporalEvaluationProtocol) =>
          updateCorporalEvaluationData({
            protocol: v,
          })
        }
      />
      <div className='flex flex-1'>
        {protocols.get(data?.protocol || 'Direto')}
      </div>
    </div>
  )
}
