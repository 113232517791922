import { AxiosError } from 'axios'

import { api } from '~/utils/api'

import { stepCounterAtom } from '../evalutation/data'
import { insertToast } from '../toasts/services'
import { hemodynamicMeasurementsAtom } from './data'
import { HemodynamicMeasurements } from './types'

export async function updateHemodynamicMeasurementsData(
  payload: Partial<HemodynamicMeasurements>,
) {
  if (!hemodynamicMeasurementsAtom.get()) return
  hemodynamicMeasurementsAtom.set({
    ...hemodynamicMeasurementsAtom.get(),
    ...(payload as HemodynamicMeasurements),
  })
}

export async function saveHemodynamicMeasurements(
  studentId: string,
  evaluationId: string,
) {
  const payload = { ...hemodynamicMeasurementsAtom.get() }

  delete payload.id
  delete payload.evaluationId
  delete payload.createdAt
  delete payload.updatedAt

  return api
    .patch(
      `personal-trainers/students/${studentId}/evaluations/${evaluationId}/hemodynamic-measurements`,
      payload,
    )
    .then(({ data }) => {
      hemodynamicMeasurementsAtom.set(data)
      stepCounterAtom.get() < 4 && stepCounterAtom.set(4)
      return true
    })
    .catch((error: AxiosError<APIError>) => {
      if (error.response?.data.message) {
        insertToast({
          duration: 3,
          message: error.response.data.message,
          title: 'Erro!',
          type: 'error',
        })
      }
      return false
    })
}

export async function clearHemodynamicMeasurementsData() {
  hemodynamicMeasurementsAtom.set(undefined)
}
