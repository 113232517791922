import { anamnesisAtom } from '~/modules/anamnesis/data'
import { useAtom } from '~/observers/jotai'
import { weekDaysOrder } from '~/utils/weekdays'

import { Header } from './Header'

export const Anamnesis: React.FC = () => {
  const [data] = useAtom(anamnesisAtom)

  return (
    <div className='flex flex-col px-8 py-7 w-full page-break text-xs'>
      <Header title='ANAMNESE' borderBottom />

      {/* Section 1 */}
      <div className='flex flex-col  mt-1 pb-1 border-b border-black'>
        <div className='flex flex-col'>
          <span className='font-bold'>1-) Objetivos</span>
          <span>{data?.goals?.join('; ')}</span>
        </div>
      </div>

      {/* Section 2 - 3 */}
      <div className='grid grid-cols-2 mt-1 pb-1 border-b border-black'>
        <div className='flex flex-col'>
          <span className='font-bold'>2-) Condicionamento Físico Atual</span>
          <span>{data?.presentPhysicalConditioning}</span>
        </div>
        <div className='flex flex-col'>
          <span className='font-bold'>3-) Há quanto tempo?</span>
          <span>
            {data?.physicalConditioningTimePeriod?.quantity}{' '}
            {data?.physicalConditioningTimePeriod?.period}
          </span>
        </div>
      </div>

      {/* Section 4 - 5 */}
      <div className='grid grid-cols-2  mt-1 pb-1 border-b border-black'>
        <div className='flex flex-col'>
          <span className='font-bold'>
            4-) Quantos dias disponíveis na semana?
          </span>
          <span>{data?.availableDaysPerWeek}</span>
        </div>
        <div className='flex flex-col'>
          <span className='font-bold'>5-) Tempo Disponível?</span>
          <span>{data?.availableTime} minutos</span>
        </div>
      </div>

      {/* Section 6 */}
      <div className='grid grid-cols-2  mt-1 pb-1 border-b border-black'>
        <div className='flex flex-col'>
          <span className='font-bold'>
            6-) Dias disponíveis para a prática de atividades físicas
          </span>
          <span>
            {weekDaysOrder
              .filter((d) => data?.availableWeekDaysForPhysical?.includes(d))
              .join(', ')}
          </span>
        </div>
      </div>

      {/* Section 7 - 8 */}
      <div className='grid grid-cols-2  mt-1 pb-1 border-b border-black'>
        <div className='flex flex-col'>
          <span className='font-bold'>
            7-) Exercícios físicos realizados atualmente
          </span>
          <span>{data?.currentExercises?.exercises?.join(', ')}</span>
        </div>
        <div className='flex flex-col'>
          <span className='font-bold'>8-) Intensidade?</span>
          <span>{data?.exercisesIntensity}</span>
        </div>
      </div>

      {/* Section 9 */}
      <div className='grid grid-cols-2  mt-1 pb-1 border-b border-black'>
        <div className='flex flex-col'>
          <span className='font-bold'>
            9-) Onde prefere fazer atividade física?
          </span>
          <span>{data?.exercisesLocale}</span>
        </div>
      </div>

      {/* Section 10 - 11 */}
      <div className='grid grid-cols-2  mt-1 pb-1 border-b border-black'>
        <div className='flex flex-col'>
          <span className='font-bold'>
            10-) Refeições normalmente realizadas no dia
          </span>
          <span>{data?.regularMeals?.join(', ')}</span>
        </div>
        <div className='flex flex-col'>
          <span className='font-bold'>11-) Algum tipo de dieta?</span>
          <span>{data?.diet}</span>
        </div>
      </div>

      {/* Section 12 */}
      <div className='grid grid-cols-2  mt-1 pb-1 border-b border-black'>
        <div className='flex flex-col'>
          <span className='font-bold'>12-) Suplementos alimentares em uso</span>
          <span>{data?.suplements}</span>
        </div>
      </div>

      {/* Section 13 */}
      <div className='grid grid-cols-2  mt-1 pb-1 border-b border-black'>
        <div className='flex flex-col'>
          <span className='font-bold'>13-) Já fez teste de esforço?</span>
          <span>{data?.effortTest}</span>
        </div>
      </div>

      {/* Section 14 - 15 */}
      <div className='grid grid-cols-2  mt-1 pb-1 border-b border-black'>
        <div className='flex flex-col'>
          <span className='font-bold'>14-) Bebidas alcoólicas</span>
          <span>{data?.alcoholicDrinks}</span>
        </div>
        <div className='flex flex-col'>
          <span className='font-bold'>
            15-) Ingere líquidos com frequência?
          </span>
          <span>{data?.litersPerDay}</span>
        </div>
      </div>

      {/* Section 16 */}
      <div className='grid grid-cols-2  mt-1 pb-1 border-b border-black'>
        <div className='flex flex-col'>
          <span className='font-bold'>16-) Diabetes?</span>
          <span>{data?.diabetes}</span>
        </div>
      </div>

      {/* Section 17 - 18 - 19 */}
      <div className='grid grid-cols-3  mt-1 pb-1 border-b border-black'>
        <div className='flex flex-col'>
          <span className='font-bold'>17-) Bebidas alcoólicas</span>
          <span>{data?.alcoholicDrinks}</span>
        </div>
        <div className='flex flex-col'>
          <span className='font-bold'>
            18-) Ingere líquidos com frequência?
          </span>
          <div>{data?.litersPerDay}</div>
        </div>
        <div className='flex flex-col'>
          <span className='font-bold'>19-) Nível de triglicerídeos</span>
          <span>{data?.triglyceridesLevels}</span>
        </div>
      </div>

      {/* Section 20 */}
      <div className='grid grid-cols-2  mt-1 pb-1 border-b border-black'>
        <div className='flex flex-col'>
          <span className='font-bold'>20-) Tabagismo</span>
          <span>{data?.tabagism}</span>
        </div>
      </div>

      {/* Section 21 */}
      <div className='grid grid-cols-2  mt-1 pb-1 border-b border-black'>
        <div className='flex flex-col'>
          <div className='flex flex-col'>
            <span className='font-bold pr-4'>21-) Doenças e Queixas</span>
            <span className='pl-2'>{data?.diseases?.diseases.join('; ')}</span>
          </div>
          <span className='font-bold pl-8'>Outras Doenças e Queixas</span>
          <span>{data?.diseases?.other}</span>
        </div>
      </div>

      {/* Section 22 */}
      <div className='grid grid-cols-2  mt-1 pb-1 border-b border-black'>
        <div className='flex flex-col'>
          <div className='flex flex-col'>
            <span className='font-bold pr-4'>
              22-) Medicamentos utilizados regularmente
            </span>
            <span className='pl-2'>
              {data?.regularMedications?.medications.join('; ')}
            </span>
          </div>
          <span className='font-bold pl-8'>Outros medicamentos utilizados</span>
          <span>{data?.regularMedications?.other}</span>
        </div>
      </div>
      <div className='grid grid-cols-2  mt-1 pb-1 border-b border-black'>
        <div className='flex flex-col'>
          <span className='font-bold'>
            23-) Lesões articulares ou musculares
          </span>
          <span className='pl-2'>{data?.jointMuscularDamage?.join('; ')}</span>
        </div>
      </div>

      {/* Section 24 */}
      <div className='grid grid-cols-2  mt-1 pb-1 border-b border-black'>
        <div className='flex flex-col'>
          <span className='font-bold'>24-) Histórico Familiar</span>
          <span className='pl-2'>{data?.pedigree?.join('; ')}</span>
        </div>
      </div>

      {/* Section 25 */}
      <div className='grid grid-cols-2  mt-1 pb-1 '>
        <div className='flex flex-col'>
          <span className='font-bold'>25-) Comentários</span>
          <span>{data?.commentary}</span>
        </div>
      </div>
    </div>
  )
}
