import { Protocol } from './types'

export const protocoloCastro0: Protocol = [
  { time: '00:00:00', speed: 0.5, steepness: 0 },
  { time: '00:00:30', speed: 0.6, steepness: 0 },
  { time: '00:01:00', speed: 0.6, steepness: 0 },
  { time: '00:01:30', speed: 0.7, steepness: 0 },
  { time: '00:02:00', speed: 0.7, steepness: 0.5 },
  { time: '00:02:30', speed: 0.8, steepness: 0.5 },
  { time: '00:03:00', speed: 0.9, steepness: 1 },
  { time: '00:03:30', speed: 1, steepness: 1 },
  { time: '00:04:00', speed: 1, steepness: 1.5 },
  { time: '00:04:30', speed: 1.1, steepness: 2 },
  { time: '00:05:00', speed: 1.2, steepness: 2.5 },
  { time: '00:05:30', speed: 1.2, steepness: 3 },
  { time: '00:06:00', speed: 1.3, steepness: 3.5 },
  { time: '00:06:30', speed: 1.4, steepness: 4 },
  { time: '00:07:00', speed: 1.4, steepness: 4.5 },
  { time: '00:07:30', speed: 1.5, steepness: 5 },
  { time: '00:08:00', speed: 1.5, steepness: 6 },
  { time: '00:08:30', speed: 1.5, steepness: 7 },
  { time: '00:09:00', speed: 1.5, steepness: 8 },
  { time: '00:09:30', speed: 1.5, steepness: 9 },
  { time: '00:10:00', speed: 1.5, steepness: 10 },
  { time: '00:10:30', speed: 1.5, steepness: 11 },
  { time: '00:11:00', speed: 1.5, steepness: 12 },
  { time: '00:11:30', speed: 1.5, steepness: 13 },
  { time: '00:12:00', speed: 1.5, steepness: 14 },
  { time: '00:12:30', speed: 1.5, steepness: 15 },
  { time: '00:13:00', speed: 1.5, steepness: 16 },
  { time: '00:13:30', speed: 1.5, steepness: 17 },
  { time: '00:14:00', speed: 1.5, steepness: 18 },
  { time: '00:14:30', speed: 1.5, steepness: 19 },
  { time: '00:15:00', speed: 1.5, steepness: 20 },
]

export const protocoloCastro1: Protocol = [
  { time: '00:00:00', speed: 1.5, steepness: 0 },
  { time: '00:00:30', speed: 1.6, steepness: 0 },
  { time: '00:01:00', speed: 1.7, steepness: 0 },
  { time: '00:01:30', speed: 1.8, steepness: 0.5 },
  { time: '00:02:00', speed: 1.9, steepness: 0.5 },
  { time: '00:02:30', speed: 2, steepness: 1 },
  { time: '00:03:00', speed: 2.1, steepness: 1 },
  { time: '00:03:30', speed: 2.2, steepness: 1.5 },
  { time: '00:04:00', speed: 2.3, steepness: 1.5 },
  { time: '00:04:30', speed: 2.4, steepness: 1.5 },
  { time: '00:05:00', speed: 2.4, steepness: 1.5 },
  { time: '00:05:30', speed: 2.5, steepness: 1.5 },
  { time: '00:06:00', speed: 2.6, steepness: 2 },
  { time: '00:06:30', speed: 2.7, steepness: 2.5 },
  { time: '00:07:00', speed: 2.8, steepness: 3 },
  { time: '00:07:30', speed: 2.9, steepness: 3.5 },
  { time: '00:08:00', speed: 3, steepness: 4 },
  { time: '00:08:30', speed: 3, steepness: 4.5 },
  { time: '00:09:00', speed: 3, steepness: 5 },
  { time: '00:09:30', speed: 3, steepness: 5.5 },
  { time: '00:10:00', speed: 3, steepness: 6 },
  { time: '00:10:30', speed: 3, steepness: 6.5 },
  { time: '00:11:00', speed: 3, steepness: 7 },
  { time: '00:11:30', speed: 3, steepness: 8 },
  { time: '00:12:00', speed: 3, steepness: 9 },
  { time: '00:12:30', speed: 3, steepness: 10 },
  { time: '00:13:00', speed: 3, steepness: 11 },
  { time: '00:13:30', speed: 3, steepness: 12 },
  { time: '00:14:00', speed: 3, steepness: 13 },
  { time: '00:14:30', speed: 3, steepness: 14 },
  { time: '00:15:00', speed: 3, steepness: 15 },
]

export const protocoloCastro2: Protocol = [
  { time: '00:00:00', speed: 2.8, steepness: 0 },
  { time: '00:00:30', speed: 3, steepness: 0 },
  { time: '00:01:00', speed: 3.2, steepness: 0 },
  { time: '00:01:30', speed: 3.4, steepness: 0 },
  { time: '00:02:00', speed: 3.6, steepness: 0 },
  { time: '00:02:30', speed: 3.7, steepness: 0 },
  { time: '00:03:00', speed: 3.9, steepness: 0 },
  { time: '00:03:30', speed: 4.1, steepness: 0 },
  { time: '00:04:00', speed: 4.3, steepness: 0 },
  { time: '00:04:30', speed: 4.3, steepness: 0.5 },
  { time: '00:05:00', speed: 4.3, steepness: 1 },
  { time: '00:05:30', speed: 4.3, steepness: 1.5 },
  { time: '00:06:00', speed: 4.3, steepness: 2 },
  { time: '00:06:30', speed: 4.3, steepness: 2.5 },
  { time: '00:07:00', speed: 4.3, steepness: 3 },
  { time: '00:07:30', speed: 4.3, steepness: 3.5 },
  { time: '00:08:00', speed: 4.4, steepness: 4 },
  { time: '00:08:30', speed: 4.4, steepness: 4.5 },
  { time: '00:09:00', speed: 4.4, steepness: 5 },
  { time: '00:09:30', speed: 4.5, steepness: 5 },
  { time: '00:10:00', speed: 4.5, steepness: 5.5 },
  { time: '00:10:30', speed: 4.5, steepness: 6 },
  { time: '00:11:00', speed: 4.5, steepness: 6.5 },
  { time: '00:11:30', speed: 4.5, steepness: 7 },
  { time: '00:12:00', speed: 4.5, steepness: 7.5 },
  { time: '00:12:30', speed: 4.5, steepness: 8 },
  { time: '00:13:00', speed: 4.5, steepness: 9 },
  { time: '00:13:30', speed: 4.5, steepness: 10 },
  { time: '00:14:00', speed: 4.5, steepness: 11 },
  { time: '00:14:30', speed: 4.5, steepness: 12 },
  { time: '00:15:00', speed: 4.5, steepness: 13 },
]

export const protocoloCAstro3: Protocol = [
  { time: '00:00:00', speed: 3, steepness: 1.5 },
  { time: '00:00:30', speed: 3.1, steepness: 2 },
  { time: '00:01:00', speed: 3.2, steepness: 2.5 },
  { time: '00:01:30', speed: 3.3, steepness: 3 },
  { time: '00:02:00', speed: 3.4, steepness: 3.5 },
  { time: '00:02:30', speed: 3.5, steepness: 4 },
  { time: '00:03:00', speed: 3.6, steepness: 4.5 },
  { time: '00:03:30', speed: 3.7, steepness: 5 },
  { time: '00:04:00', speed: 3.8, steepness: 5.5 },
  { time: '00:04:30', speed: 3.9, steepness: 5 },
  { time: '00:05:00', speed: 4, steepness: 5.5 },
  { time: '00:05:30', speed: 4, steepness: 6 },
  { time: '00:06:00', speed: 4.1, steepness: 6.5 },
  { time: '00:06:30', speed: 4.2, steepness: 6 },
  { time: '00:07:00', speed: 4.3, steepness: 6.5 },
  { time: '00:07:30', speed: 4.4, steepness: 7 },
  { time: '00:08:00', speed: 4.5, steepness: 7.5 },
  { time: '00:08:30', speed: 4.5, steepness: 8 },
  { time: '00:09:00', speed: 4.5, steepness: 8.5 },
  { time: '00:09:30', speed: 4.5, steepness: 9 },
  { time: '00:10:00', speed: 4.5, steepness: 9.5 },
  { time: '00:10:30', speed: 4.5, steepness: 10 },
  { time: '00:11:00', speed: 4.5, steepness: 10.5 },
  { time: '00:11:30', speed: 4.5, steepness: 11 },
  { time: '00:12:00', speed: 4.5, steepness: 11.5 },
  { time: '00:12:30', speed: 4.5, steepness: 12 },
  { time: '00:13:00', speed: 4.5, steepness: 13 },
  { time: '00:13:30', speed: 4.5, steepness: 14 },
  { time: '00:14:00', speed: 4.5, steepness: 15 },
  { time: '00:14:30', speed: 4.5, steepness: 16 },
  { time: '00:15:00', speed: 4.5, steepness: 17 },
]

export const protocoloCastro4: Protocol = [
  { time: '00:00:00', speed: 4, steepness: 0 },
  { time: '00:00:30', speed: 4.2, steepness: 0 },
  { time: '00:01:30', speed: 4.5, steepness: 0 },
  { time: '00:02:00', speed: 4.7, steepness: 0 },
  { time: '00:02:30', speed: 4.8, steepness: 0 },
  { time: '00:03:00', speed: 5, steepness: 0 },
  { time: '00:03:30', speed: 5.1, steepness: 0 },
  { time: '00:04:00', speed: 5.3, steepness: 0 },
  { time: '00:04:30', speed: 5.4, steepness: 0 },
  { time: '00:05:00', speed: 5.6, steepness: 0 },
  { time: '00:05:30', speed: 5.8, steepness: 0 },
  { time: '00:06:00', speed: 5.9, steepness: 0 },
  { time: '00:06:30', speed: 6.1, steepness: 0 },
  { time: '00:07:00', speed: 6.2, steepness: 0 },
  { time: '00:07:30', speed: 6.4, steepness: 0 },
  { time: '00:08:00', speed: 6.5, steepness: 0 },
  { time: '00:08:30', speed: 6.7, steepness: 0 },
  { time: '00:09:00', speed: 6.9, steepness: 0 },
  { time: '00:09:30', speed: 7, steepness: 0 },
  { time: '00:10:00', speed: 7.2, steepness: 0 },
  { time: '00:10:30', speed: 7.3, steepness: 0 },
  { time: '00:11:00', speed: 7.5, steepness: 0 },
  { time: '00:11:30', speed: 7.6, steepness: 0 },
  { time: '00:12:00', speed: 7.8, steepness: 0 },
  { time: '00:12:30', speed: 8, steepness: 0 },
  { time: '00:13:00', speed: 8.1, steepness: 0 },
  { time: '00:13:30', speed: 8.3001, steepness: 0 },
  { time: '00:14:00', speed: 8.4, steepness: 0 },
  { time: '00:14:30', speed: 8.6, steepness: 0 },
  { time: '00:15:00', speed: 8.699999999999999, steepness: 0 },
]

export const protocoloCastro5: Protocol = [
  { time: '00:00:00', speed: 4, steepness: 0 },
  { time: '00:00:30', speed: 4.2, steepness: 0 },
  { time: '00:01:00', speed: 4.5, steepness: 0 },
  { time: '00:01:30', speed: 4.7, steepness: 0 },
  { time: '00:02:00', speed: 4.9, steepness: 0 },
  { time: '00:02:30', speed: 5.1, steepness: 0 },
  { time: '00:03:00', speed: 5.4, steepness: 0 },
  { time: '00:03:30', speed: 5.6, steepness: 0 },
  { time: '00:04:00', speed: 5.8, steepness: 0 },
  { time: '00:04:30', speed: 6, steepness: 0 },
  { time: '00:05:00', speed: 6.3, steepness: 0 },
  { time: '00:05:30', speed: 6.5, steepness: 0 },
  { time: '00:06:00', speed: 6.7, steepness: 0 },
  { time: '00:06:30', speed: 6.9, steepness: 0 },
  { time: '00:07:00', speed: 7.2, steepness: 0 },
  { time: '00:07:30', speed: 7.4, steepness: 0 },
  { time: '00:08:00', speed: 7.6, steepness: 0 },
  { time: '00:08:30', speed: 7.8, steepness: 0 },
  { time: '00:09:00', speed: 8.1, steepness: 0 },
  { time: '00:09:30', speed: 8.3001, steepness: 0 },
  { time: '00:10:00', speed: 8.5, steepness: 0 },
  { time: '00:10:30', speed: 8.699999999999999, steepness: 0 },
  { time: '00:11:00', speed: 9, steepness: 0 },
  { time: '00:11:30', speed: 9.199999999999999, steepness: 0 },
  { time: '00:12:00', speed: 9.4, steepness: 0 },
  { time: '00:12:30', speed: 9.6, steepness: 0 },
  { time: '00:13:00', speed: 9.9, steepness: 0 },
  { time: '00:13:30', speed: 10.1, steepness: 0 },
  { time: '00:14:00', speed: 10.3, steepness: 0 },
  { time: '00:14:30', speed: 10.5, steepness: 0 },
  { time: '00:15:00', speed: 10.8, steepness: 0 },
]
