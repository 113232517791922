import { useCallback } from 'react'

import { MaskedInput } from '~/components/TextInput'
import { floatMask } from '~/components/TextInput/utils/mask'
import { corporalEvaluationAtom } from '~/modules/corporalEvaluation/data'
import {
  setCorporalEvaluationImageIndex,
  updateCorporalEvaluationData,
} from '~/modules/corporalEvaluation/services'

import { Mean } from './Mean'

export const MedialThighInput: React.FC = () => {
  const data = corporalEvaluationAtom.get()

  const handleUpdateField = useCallback(
    (index: number, value: string) => {
      const newArr = data ? data.medialThigh : []
      newArr[index] = +value
      updateCorporalEvaluationData({ medialThigh: newArr })
    },
    [data],
  )

  const handleFocus = useCallback(() => {
    setCorporalEvaluationImageIndex(3)
  }, [])

  return (
    <>
      <div className='whitespace-nowrap font-bold xl:font-normal'>
        Coxa Medial (mm) &nbsp;
      </div>
      {data?.medialThigh.map((val, index) => (
        <div key={`medialThigh-${index}`}>
          <p className='block xl:hidden mb-2'>Medida {index + 1}</p>
          <MaskedInput
            value={val || ''}
            mask='float'
            placeholder='0'
            onFocus={handleFocus}
            onChange={(v) => handleUpdateField(index, floatMask(v))}
          />
        </div>
      ))}
      <div>
        <p className='block xl:hidden mb-2'>Média</p>
        <Mean field='medialThigh' />
      </div>
    </>
  )
}
