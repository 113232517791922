import { useCallback, useMemo, useState } from 'react'
import { FiPlus, FiX } from 'react-icons/fi'
import { IoIosArrowUp, IoIosArrowDown, IoIosClose } from 'react-icons/io'

import { Button } from '~/components/Button'
import { Modal } from '~/components/Modal'
import { SchedulerActivity } from '~/modules/scheduler/types'
import { useScheduler } from '~/modules/scheduler/useScheduler'

type Props = {
  dayIndex: number
  data: SchedulerActivity[] | null
}

const daysInWeek = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
]

export const DaysActivitiesList: React.FC<Props> = ({ data, dayIndex }) => {
  const {
    moveItemInList,
    removeItemInList,
    data: schedulerData,
    toggleActivityInWeek,
  } = useScheduler()

  const [showModal, setShowModal] = useState<boolean>(false)

  const handleMoveUp = useCallback(
    (currentIndex: number, listIndex: number) => {
      moveItemInList('up', currentIndex, listIndex)
    },
    [moveItemInList],
  )

  const handleMoveDown = useCallback(
    (currentIndex: number, listIndex: number) => {
      moveItemInList('down', currentIndex, listIndex)
    },
    [moveItemInList],
  )

  const handleToggleModal = useCallback(() => {
    setShowModal((state) => !state)
  }, [])

  const handleAddAcitivity = useCallback(
    (activity: string) => {
      toggleActivityInWeek(dayIndex, activity)
      setShowModal(false)
    },
    [toggleActivityInWeek, dayIndex],
  )

  const filteredActivitiesList = useMemo(() => {
    const listRef = schedulerData?.activityWeek[dayIndex]

    return listRef
      ? schedulerData.activities.filter(
          (a) => !listRef.find((i) => i.name === a),
        )
      : schedulerData?.activities
  }, [schedulerData, dayIndex])

  return (
    <div className='flex flex-col gap-2 border border-slate-200 rounded-md p-3 grow-0 w-full'>
      <p className='font-bold'>{daysInWeek[dayIndex]}</p>
      <ul className='flex flex-col gap-3 overflow-y-scroll bg-slate-100 rounded-md p-2 h-36'>
        {data?.map((activity, index) => (
          <li
            key={`activity-${dayIndex}-${index}`}
            className='flex bg-gpa-blue-50 p-1.5 rounded-md'
          >
            <span className='flex-1'>{activity.name}</span>
            <div className='flex gap-1'>
              {index !== 0 ? (
                <button
                  className='p-1 rounded-full bg-gpa-blue-500 active:bg-blue-500 h-6 w-6'
                  onClick={() => handleMoveUp(index, dayIndex)}
                >
                  <IoIosArrowUp className='text-white text-md' />
                </button>
              ) : null}
              {index + 1 !== data.length ? (
                <button
                  className='p-1 rounded-full bg-gpa-blue-500 h-6 w-6 active:bg-blue-500'
                  onClick={() => handleMoveDown(index, dayIndex)}
                >
                  <IoIosArrowDown className='text-white text-md' />
                </button>
              ) : null}

              <button
                className='flex justify-center items-center rounded-full bg-gpa-red h-6 w-6 active:bg-red-600'
                onClick={() => removeItemInList(index, dayIndex)}
              >
                <IoIosClose className='text-white text-lg' />
              </button>
            </div>
          </li>
        ))}
      </ul>
      <div className='flex flex-wrap justify-center gap-3'>
        <Button className='flex-1' onClick={handleToggleModal}>
          Inserir atividade
        </Button>
      </div>

      <Modal visible={showModal}>
        <div className='flex flex-col'>
          <div className='flex items-center justify-between mb-3'>
            <h2 className='text-xl font-medium'>Inserir atividade</h2>
            <button
              className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
              onClick={handleToggleModal}
            >
              <FiX size={24} />
            </button>
          </div>
          <div className='flex gap-2 flex-wrap'>
            {filteredActivitiesList?.map((activity, index) => (
              <button
                key={`inner-activity-${index}`}
                className='flex gap-2 p-2 bg-gpa-blue-50 rounded-md self-start hover:bg-blue-200 active:bg-blue-300'
                onClick={() => handleAddAcitivity(activity)}
              >
                {activity}
                <span className='p-1 rounded-full bg-blue-400'>
                  <FiPlus className='text-white' />
                </span>
              </button>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  )
}
