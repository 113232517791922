import { atom } from '~/observers/jotai'

import {
  AerobicRow,
  ExerciseSeries,
  SessionMetadata,
  StandardizedSeries,
} from './types'

export const standardizedSeriesAtom = atom<StandardizedSeries | undefined>(
  undefined,
)

export const aerobicMacrocycleAtom = atom<AerobicRow[]>([])

export const strengthMacrocycleAtom = atom<
  Array<ExerciseSeries & SessionMetadata>[]
>([])
