import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useContext, useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { useForm } from 'react-hook-form'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '~/components/Button'
import { QuestionFrame } from '~/components/QuestionFrame'
import { TextInput } from '~/components/TextInput'
import { antropometryAtom } from '~/modules/antropometry/data'
import {
  patchAntropometry,
  updateAntropometryData,
} from '~/modules/antropometry/services'
import { Antropometry } from '~/modules/antropometry/types'
import { evaluationAtom } from '~/modules/evalutation/data'
import { useAtom } from '~/observers/jotai'
import { anamnesisRoute, corporalEvaluationRoute } from '~/routes/routes'
import { EvaluationRouteParams } from '~/routes/types'

import { formsLayoutContext } from '../../layout'
import { AntropometryFormType, antropometrySchema } from './schema'
import { getChartValuesService } from './services/getChartValuesService'
import { getSupportImageService } from './services/getSupportImageService'
import { chartOptions } from './variables'

export const AntropometryForm: React.FC = () => {
  const [evaluation] = useAtom(evaluationAtom)
  const [antropometry] = useAtom(antropometryAtom)
  const { scrollToTop } = useContext(formsLayoutContext)
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)

  const { evaluation_id, student_id } = useParams<EvaluationRouteParams>()

  const [supportImage, setSupportImage] = useState<string>(
    getSupportImageService(evaluation?.student.sex),
  )
  const chartData = getChartValuesService(antropometry)

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<AntropometryFormType>({
    defaultValues: antropometry,
    resolver: zodResolver(antropometrySchema),
  })

  const handleSubmitForm = useCallback(
    async (payload: AntropometryFormType) => {
      setLoading(true)
      if (!student_id || !evaluation_id) return
      patchAntropometry(student_id, evaluation_id, payload)
        .then((success) => {
          if (success) {
            scrollToTop()
            navigate(
              corporalEvaluationRoute
                .replace(':student_id', student_id || '')
                .replace(':evaluation_id', evaluation_id || ''),
              { replace: true },
            )
          }
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [scrollToTop, navigate, evaluation_id, student_id],
  )

  const handleGoBack = useCallback(() => {
    if (!student_id || !evaluation_id) return
    navigate(
      anamnesisRoute
        .replace('<subform>', 'stress')
        .replace(':student_id', student_id)
        .replace(':evaluation_id', evaluation_id),
      {
        replace: true,
      },
    )
    scrollToTop()
  }, [navigate, scrollToTop, student_id, evaluation_id])

  useEffect(() => {
    const { unsubscribe } = watch((formData) => {
      updateAntropometryData(formData as Antropometry)
    })

    return () => {
      unsubscribe()
    }
  }, [watch])

  return (
    <form
      onSubmit={handleSubmit(handleSubmitForm)}
      className='flex flex-col px-6 py-9 gap-6 rounded-2xl bg-white'
    >
      <QuestionFrame
        isValid={antropometrySchema.safeParse(watch()).success}
        errors={!!Object.keys(errors).length}
        title='Perimetria'
      >
        <div className='flex flex-1 gap-4'>
          <div className='relative w-[250px] hidden md:block bg-gpa-blue-50 bg-opacity-40 rounded-md h-full'>
            <div className='w-full bg-red-50 sticky top-5'>
              <picture>
                <source type='image/jpg' />
                <img
                  loading='eager'
                  decoding='sync'
                  src={supportImage}
                  alt='dummy'
                  className='w-full rounded-md'
                />
              </picture>
            </div>
          </div>
          <div className='flex flex-col flex-1 overflow-hidden'>
            <div className='flex flex-wrap gap-x-5'>
              <TextInput
                className='appearance-none'
                label='Peso Atual (kg)'
                type='number'
                {...register('currentWeight', {
                  valueAsNumber: true,
                })}
                error={errors.currentWeight?.message}
                onFocus={() =>
                  setSupportImage(
                    getSupportImageService(evaluation?.student.sex),
                  )
                }
              />
              <TextInput
                label='Estatura (cm)'
                type='number'
                {...register('stature', { valueAsNumber: true })}
                error={errors.stature?.message}
                onFocus={() =>
                  setSupportImage(
                    getSupportImageService(evaluation?.student.sex),
                  )
                }
              />
            </div>

            <div className='flex flex-col flex-1 gap-3'>
              <p className='w-full text-center font-bold'>Perimetria</p>
              <div className='grid grid-cols-[repeat(auto-fill,_minmax(200px,_1fr))] items-start gap-x-5'>
                <TextInput
                  label='1 - Ombro (cm)'
                  type='number'
                  {...register('shoulder', { valueAsNumber: true })}
                  error={errors.shoulder?.message}
                  onFocus={() =>
                    setSupportImage(
                      getSupportImageService(
                        evaluation?.student.sex,
                        'shoulder',
                      ),
                    )
                  }
                />
                <TextInput
                  label='2 - Peitoral Inspirado (cm)'
                  type='number'
                  {...register('chestInflated', { valueAsNumber: true })}
                  error={errors.chestInflated?.message}
                  onFocus={() =>
                    setSupportImage(
                      getSupportImageService(
                        evaluation?.student.sex,
                        'chestInflated',
                      ),
                    )
                  }
                />
                <TextInput
                  label='3 - Peitoral normal (cm)'
                  type='number'
                  {...register('chestRegular', { valueAsNumber: true })}
                  error={errors.chestRegular?.message}
                  onFocus={() =>
                    setSupportImage(
                      getSupportImageService(
                        evaluation?.student.sex,
                        'chestRegular',
                      ),
                    )
                  }
                />

                <TextInput
                  label='4 - Quadril (cm)'
                  type='number'
                  {...register('hip', {
                    valueAsNumber: true,
                  })}
                  error={errors.hip?.message}
                  onFocus={() =>
                    setSupportImage(
                      getSupportImageService(evaluation?.student.sex, 'hip'),
                    )
                  }
                />
                <TextInput
                  label='5 - Abdômen (cm)'
                  type='number'
                  {...register('abdomen', { valueAsNumber: true })}
                  error={errors.abdomen?.message}
                  onFocus={() =>
                    setSupportImage(
                      getSupportImageService(
                        evaluation?.student.sex,
                        'abdomen',
                      ),
                    )
                  }
                />
                <TextInput
                  label='6 - Cintura (cm)'
                  type='number'
                  {...register('waist', { valueAsNumber: true })}
                  error={errors.waist?.message}
                  onFocus={() =>
                    setSupportImage(
                      getSupportImageService(evaluation?.student.sex, 'waist'),
                    )
                  }
                />
              </div>
            </div>

            <div className='flex flex-wrap gap-x-5'>
              <div className='flex flex-1 flex-col gap-y-3'>
                <p className='font-bold'>Lado Direito</p>

                <div className='flex flex-col flex-1 flex-wrap'>
                  <TextInput
                    label='7 - Coxa Proximal Dir. (cm)'
                    type='number'
                    {...register('rightThigh', { valueAsNumber: true })}
                    error={errors.rightThigh?.message}
                    onFocus={() =>
                      setSupportImage(
                        getSupportImageService(
                          evaluation?.student.sex,
                          'rightThigh',
                        ),
                      )
                    }
                  />
                  <TextInput
                    label='9 - Perna Direita Dir. (cm)'
                    type='number'
                    {...register('rightLeg', { valueAsNumber: true })}
                    error={errors.rightLeg?.message}
                    onFocus={() =>
                      setSupportImage(
                        getSupportImageService(
                          evaluation?.student.sex,
                          'rightLeg',
                        ),
                      )
                    }
                  />
                  <TextInput
                    label='11 - Braço Dir. Contraído (cm)'
                    type='number'
                    {...register('rightArmContracted', { valueAsNumber: true })}
                    error={errors.rightArmContracted?.message}
                    onFocus={() =>
                      setSupportImage(
                        getSupportImageService(
                          evaluation?.student.sex,
                          'rightArmContracted',
                        ),
                      )
                    }
                  />
                  <TextInput
                    label='13 - Braço Dir. Relaxado (cm)'
                    type='number'
                    {...register('rightArmRelaxed', { valueAsNumber: true })}
                    error={errors.rightArmRelaxed?.message}
                    onFocus={() =>
                      setSupportImage(
                        getSupportImageService(
                          evaluation?.student.sex,
                          'rightArmRelaxed',
                        ),
                      )
                    }
                  />
                  <TextInput
                    label='15 - Antebraço Dir. (cm)'
                    type='number'
                    {...register('rightForearm', { valueAsNumber: true })}
                    error={errors.rightForearm?.message}
                    onFocus={() =>
                      setSupportImage(
                        getSupportImageService(
                          evaluation?.student.sex,
                          'rightForearm',
                        ),
                      )
                    }
                  />
                  <TextInput
                    label='17 - Punho Dir. (cm)'
                    type='number'
                    {...register('rightFist', { valueAsNumber: true })}
                    error={errors.rightFist?.message}
                    onFocus={() =>
                      setSupportImage(
                        getSupportImageService(
                          evaluation?.student.sex,
                          'rightFist',
                        ),
                      )
                    }
                  />
                </div>
              </div>

              <div className='flex flex-1 flex-col gap-y-3'>
                <p className='font-bold'>Lado Esquerdo</p>

                <div className='flex flex-col flex-1 flex-wrap'>
                  <TextInput
                    label='8 - Coxa Proximal Esq. (cm)'
                    type='number'
                    {...register('leftThigh', { valueAsNumber: true })}
                    error={errors?.leftThigh?.message}
                    onFocus={() =>
                      setSupportImage(
                        getSupportImageService(
                          evaluation?.student.sex,
                          'leftThigh',
                        ),
                      )
                    }
                  />
                  <TextInput
                    label='10 - Perna Esquerda Esq. (cm)'
                    type='number'
                    {...register('leftLeg', { valueAsNumber: true })}
                    error={errors?.leftLeg?.message}
                    onFocus={() =>
                      setSupportImage(
                        getSupportImageService(
                          evaluation?.student.sex,
                          'leftLeg',
                        ),
                      )
                    }
                  />
                  <TextInput
                    label='12 - Braço Esq. Contraído (cm)'
                    type='number'
                    {...register('leftArmContracted', { valueAsNumber: true })}
                    error={errors?.leftArmContracted?.message}
                    onFocus={() =>
                      setSupportImage(
                        getSupportImageService(
                          evaluation?.student.sex,
                          'leftArmContracted',
                        ),
                      )
                    }
                  />
                  <TextInput
                    label='14 - Braço Esq. Relaxado (cm)'
                    type='number'
                    {...register('leftArmRelaxed', { valueAsNumber: true })}
                    error={errors?.leftArmRelaxed?.message}
                    onFocus={() =>
                      setSupportImage(
                        getSupportImageService(
                          evaluation?.student.sex,
                          'leftArmRelaxed',
                        ),
                      )
                    }
                  />
                  <TextInput
                    label='16 - Antebraço Esq. (cm)'
                    type='number'
                    {...register('leftForearm', { valueAsNumber: true })}
                    error={errors?.leftForearm?.message}
                    onFocus={() =>
                      setSupportImage(
                        getSupportImageService(
                          evaluation?.student.sex,
                          'leftForearm',
                        ),
                      )
                    }
                  />
                  <TextInput
                    label='18 - Punho Esq. (cm)'
                    type='number'
                    {...register('leftFist', { valueAsNumber: true })}
                    error={errors?.leftFist?.message}
                    onFocus={() =>
                      setSupportImage(
                        getSupportImageService(
                          evaluation?.student.sex,
                          'leftFist',
                        ),
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </QuestionFrame>

      <div className='h-64'>
        <Chart
          type='bar'
          options={chartOptions}
          series={[{ data: chartData }]}
          height='100%'
        />
      </div>

      <div className='flex justify-between'>
        <Button
          type='button'
          loading={loading}
          disabled={loading}
          onClick={handleGoBack}
        >
          <FiChevronLeft size={24} />
          <span className='mb-1'>Voltar</span>
        </Button>
        <Button loading={loading} disabled={loading}>
          <span className='mb-1'>Salvar</span>
          <FiChevronRight size={24} />
        </Button>
      </div>
    </form>
  )
}
