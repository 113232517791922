import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { FiX } from 'react-icons/fi'
import zod from 'zod'

import { Button } from '~/components/Button'
import { Modal } from '~/components/Modal'
import { TextInput } from '~/components/TextInput'
import { addNewExercise } from '~/modules/aerobicExercises/services'

type Props = {
  visible: boolean
  onClose: () => void
}

type ActivityForm = {
  activity: string
}

const schema = zod.object({
  activity: zod
    .string({ required_error: 'Campo obrigatório' })
    .trim()
    .nonempty('Campo obrigatório')
    .max(15, 'Deve ter no máximo 15 caracteres'),
})

export const NewActivityModal: React.FC<Props> = ({ visible, onClose }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ActivityForm>({
    resolver: zodResolver(schema),
  })

  const onSumbitForm = useCallback(
    ({ activity }: ActivityForm) => {
      addNewExercise(activity)
      reset()
      onClose()
    },
    [reset, onClose],
  )

  const handleClose = useCallback(() => {
    reset()
    onClose()
  }, [reset, onClose])

  return (
    <Modal visible={visible}>
      <div className='flex items-center justify-between mb-3'>
        <h2 className='text-xl font-medium'>Novo Exercício</h2>
        <button
          className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
          onClick={handleClose}
        >
          <FiX size={24} />
        </button>
      </div>
      <form
        onSubmit={handleSubmit(onSumbitForm)}
        className='flex flex-col gap-3'
      >
        <TextInput {...register('activity')} error={errors.activity?.message} />
        <Button>Adicionar</Button>
      </form>
    </Modal>
  )
}
