import { AxiosError } from 'axios'

import { api } from '~/utils/api'

import { aerobicPrescriptionAtom } from '../aerobicPrescription/data'
import { insertToast } from '../toasts/services'
import { exercisesPrescriptionsAtom } from './data'
import { ExerciseGroupNames } from './types'

export async function saveExercisesPrescriptions(
  studentId: string,
  evaluationId: string,
) {
  const payload = { ...exercisesPrescriptionsAtom.get() }

  delete payload.id
  delete payload.evaluationId
  delete payload.createdAt
  delete payload.updatedAt

  return api
    .patch(
      `/personal-trainers/students/${studentId}/evaluations/${evaluationId}/exercises-prescriptions`,
      payload,
    )
    .then(({ data }) => {
      insertToast({
        duration: 3,
        type: 'success',
        message: 'Exercícios e Séries Padronizadas salvos!',
        title: 'Sucesso!',
      })
      data && exercisesPrescriptionsAtom.set(data)
      return !!data
    })
    .catch((error: AxiosError<APIError>) => {
      if (error.response?.data.message) {
        insertToast({
          duration: 3,
          type: 'error',
          message: error.response?.data.message,
          title: 'Erro ao tentar salvar as preescrição de exercícios',
        })
      }
      return false
    })
}

export function addExerciseToGroup(
  exercise: string,
  exerciseGroup: ExerciseGroupNames,
): boolean {
  const exercisesPrescriptions = exercisesPrescriptionsAtom.get()
  if (!exercisesPrescriptions) return false

  const index = exercisesPrescriptions.exercises.findIndex(
    (e) => e.groupName === exerciseGroup,
  )
  if (index < 0) return false

  if (
    exercisesPrescriptions.exercises[index].exercises.some(
      (e) => e === exercise,
    )
  ) {
    return false
  }

  exercisesPrescriptions.exercises[index].exercises.push(exercise)
  exercisesPrescriptionsAtom.set({ ...exercisesPrescriptions })

  return true
}

export function addExerciseToStandardizedSeries(
  exercise: string,
  group: string,
): boolean {
  const exercisesPrescriptions = exercisesPrescriptionsAtom.get()
  if (!exercisesPrescriptions) return false

  const index = exercisesPrescriptions.standardizedSeries.findIndex(
    (e) => e.groupName === group,
  )
  if (index < 0) return false

  if (
    exercisesPrescriptions.standardizedSeries[index].exercises.some(
      (e) => e === exercise,
    )
  ) {
    return false
  }

  exercisesPrescriptions.standardizedSeries[index].exercises.push(exercise)
  exercisesPrescriptionsAtom.set({ ...exercisesPrescriptions })

  return true
}

export function removeExerciseFromGroup(
  exercise: string,
  group: string,
): boolean {
  const exercisesPrescriptions = exercisesPrescriptionsAtom.get()
  if (!exercisesPrescriptions) return false

  const { exercises } = exercisesPrescriptions
  const index = exercises.findIndex((e) => e.groupName === group)

  if (index < 0) return false

  exercises[index].exercises = exercises[index].exercises.filter(
    (e) => e !== exercise,
  )

  exercisesPrescriptionsAtom.set({
    ...exercisesPrescriptions,
    exercises,
  })

  return true
}

export function removeExerciseFromStandardizedSeries(
  exercise: string,
  group: string,
): boolean {
  const exercisesPrescriptions = exercisesPrescriptionsAtom.get()
  if (!exercisesPrescriptions) return false

  const { standardizedSeries } = exercisesPrescriptions
  const index = standardizedSeries.findIndex((s) => s.groupName === group)

  if (index < 0) return false

  standardizedSeries[index].exercises = standardizedSeries[
    index
  ].exercises.filter((e) => e !== exercise)

  exercisesPrescriptionsAtom.set({
    ...exercisesPrescriptions,
    standardizedSeries,
  })

  return true
}

export function removeStandardizedSeries(group?: string) {
  const exercisesPrescriptions = exercisesPrescriptionsAtom.get()
  if (!exercisesPrescriptions || !group) return false

  const { standardizedSeries } = exercisesPrescriptions
  const filtered = standardizedSeries.filter((s) => s.groupName !== group)
  exercisesPrescriptionsAtom.set({
    ...exercisesPrescriptions,
    standardizedSeries: filtered,
  })
}

export function registerNewStandardizedSeries(group: string): boolean {
  const exercisesPrescriptions = exercisesPrescriptionsAtom.get()
  if (!exercisesPrescriptions) return false

  const { standardizedSeries } = exercisesPrescriptions
  const alreadyRegistered = standardizedSeries.some(
    (ss) => ss.groupName === group,
  )

  if (alreadyRegistered) return false

  standardizedSeries.push({
    exercises: [],
    groupName: group,
  })

  exercisesPrescriptionsAtom.set({
    ...exercisesPrescriptions,
    standardizedSeries,
  })

  return true
}

export function restoreStandardExercisesFromGroup(group?: ExerciseGroupNames) {
  if (!group) return
  const exercisesPrescriptions = exercisesPrescriptionsAtom.get()
  if (!exercisesPrescriptions) return false

  const index = exercisesPrescriptions.exercises.findIndex(
    (e) => e.groupName === group,
  )
  if (index < 0) return false

  const exercisesList = exercisesPrescriptions.exercises[index].exercises

  let missingExercises: string[] = []

  switch (group) {
    case 'Abdomen':
      missingExercises = [
        'Crunch com rotação',
        'Flexão lateral tronco',
        'Abd. infra-umbilical',
        'Crunch banco/polia',
      ].filter((item) => !exercisesList?.includes(item))
      break
    case 'Panturrilha':
      missingExercises = [
        'Panturrilha em pé',
        'Panturrilha sentado',
        'Panturrilha no Leg-press',
      ].filter((item) => !exercisesList?.includes(item))
      break
    case 'Coxas e gluteos':
      missingExercises = [
        'Meio Agachamento',
        'Agachamento',
        'Agachamento no Hack',
        'Leg-press horizontal',
        'Leg-press vertical',
        'Extensão de pernas',
        'Flexão de pernas',
        'Afundo',
        'Cadeira adutora',
        'Cadeira abdutora',
        'Levantamento Terra',
        'Mesa para Glúteos',
      ].filter((item) => !exercisesList?.includes(item))
      break
    case 'Peito':
      missingExercises = [
        'Supino reto',
        'Supino inclinado',
        'Supino decli/canad.',
        'Crucifixo reto',
        'Crucifixo inclinado',
        'Crucifixo declinado',
        'Pec deck (voador)',
        'Crossover',
        'Pullover',
      ].filter((item) => !exercisesList?.includes(item))
      break
    case 'Costas':
      missingExercises = [
        'Barra fixa',
        'Pulley alto post.',
        'Pulley alto anterior',
        'Remada sentado',
        'Remada unilateral',
        'Remada curvada',
        'Hiperextensão lombar',
      ].filter((item) => !exercisesList?.includes(item))
      break
    case 'Ombro':
      missingExercises = [
        'Desenvolv. p/ frente',
        'Desenvolv. p/ trás',
        'Desenvolv. halteres',
        'Elevação lateral',
        'Elevação frontal',
        'Remada alta',
        'Encolhimento ombros',
      ].filter((item) => !exercisesList?.includes(item))
      break
    case 'Biceps':
      missingExercises = [
        'Rosca direta(barra)',
        'Rosca simult.(halt.)',
        'Rosca concentrada',
        'Rosca Scott',
        'Rosca inversa',
      ].filter((item) => !exercisesList?.includes(item))
      break
    case 'Triceps':
      missingExercises = [
        'Tríceps pulley alto',
        'Tríceps na testa',
        'Tríceps coice(Kickb.)',
        'Supino fechado',
        'Tríceps francês',
        'Tríceps unilateral',
      ].filter((item) => !exercisesList?.includes(item))
      break
    case 'Antebraço':
      missingExercises = [
        'Rosca punho',
        'Rosca punho inversa',
        'Adução/Abdução punho',
      ].filter((item) => !exercisesList?.includes(item))
      break
    case 'Outros':
    default:
      break
  }

  const restoredList = [...exercisesList, ...missingExercises]
  exercisesPrescriptions.exercises[index].exercises = restoredList
  exercisesPrescriptionsAtom.set({ ...exercisesPrescriptions })

  return true
}

export function clearAerobicPrescriptions() {
  aerobicPrescriptionAtom.set(undefined)
}
