import { useCallback, useState } from 'react'
import { FiX } from 'react-icons/fi'

import { Button } from '~/components/Button'
import { Modal } from '~/components/Modal'
import { TextInput } from '~/components/TextInput'
import { useAreobicCapabilities } from '~/modules/aerobicCapabilities/useAerobicCapabilities'
import {
  restoreNumSeries,
  restoreRepetitionNumber,
  updateExerciseInSeriesService,
} from '~/modules/standardizedSeries/services'
import { Exercise } from '~/modules/standardizedSeries/types'

import { StrengthTestForm } from './StrengthTestForm'

type Props = {
  letter: string
  seriesIndex: number
  exercise: Exercise
  exerciseIndex: number
}

export const EditModal: React.FC<Props> = ({
  letter,
  exercise,
  seriesIndex,
  exerciseIndex,
}) => {
  const { score } = useAreobicCapabilities()
  const [show, setShow] = useState<boolean>(false)

  const handleToggleModal = useCallback(() => {
    setShow((state) => !state)
  }, [])

  const handleUpdateExerciseField = useCallback(
    (payload: Partial<Exercise>) => {
      updateExerciseInSeriesService(seriesIndex, exerciseIndex, payload)
    },
    [seriesIndex, exerciseIndex],
  )

  return (
    <>
      <Button className='flex-1' onClick={handleToggleModal}>
        Editar
      </Button>

      <Modal visible={show}>
        <div className='flex items-center justify-between mb-2'>
          <h2 className='text-xl font-medium truncate'>
            Série {letter} - {exercise.name}
          </h2>
          <button
            className='bg-white hover:bg-gpa-gray-50 p-1.5 rounded-full'
            onClick={handleToggleModal}
          >
            <FiX size={24} />
          </button>
        </div>

        <StrengthTestForm
          exerciseIndex={exerciseIndex}
          seriesIndex={seriesIndex}
        />

        <div className='flex flex-col mt-5'>
          <div className='flex gap-3 flex-wrap'>
            <TextInput
              label='Carga Inicial'
              value={exercise.initialCharge}
              readOnly
            />
            <TextInput
              label='Carga Máxima'
              value={exercise.maximumCharge}
              onChange={(e) => {
                handleUpdateExerciseField({
                  maximumCharge: +e.target.value.replace(/\D/, ''),
                })
              }}
            />
          </div>
          <div className='flex gap-3 flex-wrap'>
            <TextInput
              label='Séries'
              value={exercise.numSeries}
              onChange={(e) => {
                handleUpdateExerciseField({
                  numSeries: +e.target.value.replace(/\D/, ''),
                })
              }}
            />
            <TextInput
              label='Repetições'
              value={exercise.repetitions}
              onChange={(e) =>
                handleUpdateExerciseField({
                  repetitions: +e.target.value.replace(/\D/, ''),
                })
              }
            />
          </div>
          <div className='flex gap-3 flex-wrap'>
            <TextInput
              label='Intervalo'
              value={exercise.interval}
              onChange={(e) =>
                handleUpdateExerciseField({
                  interval: e.target.value.trim(),
                })
              }
            />
            <TextInput
              label='Velocidade'
              value={exercise.speed}
              onChange={(e) =>
                handleUpdateExerciseField({
                  speed: e.target.value.trim(),
                })
              }
            />
          </div>
          <div className='flex gap-3 flex-wrap'>
            <TextInput
              label='Ajuste/Obs'
              value={exercise.adjustments}
              onChange={(e) =>
                handleUpdateExerciseField({
                  adjustments: e.target.value,
                })
              }
            />
          </div>
        </div>

        <div className='my-3'>
          <Button className='w-full' onClick={handleToggleModal}>
            Finalizar
          </Button>
        </div>

        <div className='flex gap-3 flex-wrap'>
          <Button
            className='flex-1'
            onClick={() => restoreNumSeries(seriesIndex, exerciseIndex, score)}
          >
            Restaurar número de Séries
          </Button>

          <Button
            className='flex-1'
            onClick={() => restoreRepetitionNumber(seriesIndex, exerciseIndex)}
          >
            Restaurar número de repetições
          </Button>
        </div>
      </Modal>
    </>
  )
}
