import { useCallback, useMemo, useState } from 'react'
import { FiX } from 'react-icons/fi'

import { Button } from '~/components/Button'
import { Modal } from '~/components/Modal'
import { SelectInput } from '~/components/SelectInput'
import { removeStandardizedSeries } from '~/modules/exercisesPrescriptions/services'
import { useExercisesPrescriptions } from '~/modules/exercisesPrescriptions/useExercisesPrescriptions'

import { CreateGroupForm } from './CreateGroupForm'
import { EditGroupForm } from './EditGroupForm'

export const Standardized: React.FC = () => {
  const { data, standardizedSeriesOptions } = useExercisesPrescriptions()
  const [selectedGroup, setSelectedGroup] = useState<string>()
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false)
  const [showCreateSeriesModal, setShowCreateSeriesModal] =
    useState<boolean>(false)

  const exercises = useMemo(() => {
    const section = data?.standardizedSeries.find(
      (e) => e.groupName === selectedGroup,
    )
    return section?.exercises ?? []
  }, [data, selectedGroup])

  const handleToggleEditModal = useCallback(() => {
    setShowEditModal((state) => !state)
  }, [])

  const handleToggleSeriesModal = useCallback(() => {
    setShowCreateSeriesModal((state) => !state)
  }, [])

  const handleRemoveStandardizedSeries = useCallback(() => {
    setConfirmDeleteModal(true)
  }, [])

  const handleCloseConfirmDeleteModal = useCallback(() => {
    setConfirmDeleteModal(false)
  }, [])

  const handleConfirmDeleteSeries = useCallback(() => {
    setShowEditModal(false)
    setConfirmDeleteModal(false)
    removeStandardizedSeries(selectedGroup)
    setSelectedGroup('')
  }, [selectedGroup])

  return (
    <div className='flex flex-col gap-3'>
      <div className='flex items-center justify-between'>
        <h2 className='font-bold'>
          Aqui você pode verificar e editar a lista de séries padronizadas
        </h2>
        <Button onClick={handleToggleSeriesModal}>
          Nova Série padronizada
        </Button>
      </div>
      <div className='flex gap-3 flex-wrap items-center'>
        <SelectInput
          label='Séries'
          options={standardizedSeriesOptions}
          value={selectedGroup}
          onChange={(v) => setSelectedGroup(v)}
        />
        {selectedGroup ? (
          <Button className='flex-1' onClick={handleToggleEditModal}>
            Editar Série
          </Button>
        ) : null}
      </div>
      <div className='grid grid-cols-[repeat(auto-fit,_minmax(_180px,auto))] gap-3 min-h-[80px] bg-slate-100 p-3 rounded-lg max-h-[300px] overflow-y-auto items-center'>
        {exercises.map((e) => (
          <span
            key={`exercise-${e.replace(' ', '-')}`}
            className='bg-blue-100 p-2 rounded-lg text-center'
          >
            {e}
          </span>
        ))}
      </div>

      <Modal visible={showCreateSeriesModal}>
        <div className='flex flex-col gap-4'>
          <div className='flex justify-between'>
            <h2 className='text-xl font-medium'>
              Cadastrar Nova Série Padronizada
            </h2>
            <button
              className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
              onClick={handleToggleSeriesModal}
            >
              <FiX size={24} />
            </button>
          </div>

          <CreateGroupForm onSubmit={handleToggleSeriesModal} />
        </div>
      </Modal>

      <Modal visible={confirmDeleteModal}>
        <div className='flex flex-col gap-4'>
          <div className='flex justify-between'>
            <h2 className='text-xl font-medium'>Atenção</h2>
            <button
              className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
              onClick={handleCloseConfirmDeleteModal}
            >
              <FiX size={24} />
            </button>
          </div>

          <p>
            Deseja realmente apagar esta série e todas as atividades dela? Você
            não poderá recupera-las mais tarde se salvar a prescrição de
            exercícios por inteiro.
          </p>

          <Button color='error' onClick={handleConfirmDeleteSeries}>
            Apagar {selectedGroup}
          </Button>
        </div>
      </Modal>

      <Modal visible={showEditModal}>
        <div className='flex flex-col gap-4'>
          <div className='flex justify-between'>
            <h2 className='text-xl font-medium'>
              Editar Série Padronizada <b>{selectedGroup}</b>
            </h2>
            <button
              className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
              onClick={handleToggleEditModal}
            >
              <FiX size={24} />
            </button>
          </div>

          {selectedGroup ? <EditGroupForm group={selectedGroup} /> : null}
        </div>
        <div className='mt-5 flex justify-between items-center'>
          <Button onClick={handleRemoveStandardizedSeries} color='error'>
            Apagar série
          </Button>
          <Button onClick={handleToggleEditModal}>Concluir</Button>
        </div>
      </Modal>
    </div>
  )
}
