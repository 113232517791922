import { useCallback, useContext, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '~/components/Button'
import { QuestionFrame } from '~/components/QuestionFrame'
import { RadioButtonArray } from '~/components/RadioButtonArray'
import { TextInput } from '~/components/TextInput'
import { anamnesisAtom } from '~/modules/anamnesis/data'
import {
  saveHemodynamicMeasurements,
  updateHemodynamicMeasurementsData,
} from '~/modules/hemodynamicMeasurements/services'
import { useHemodynamicMeasurements } from '~/modules/hemodynamicMeasurements/useHemodynamicMeasurements'
import { useAtom } from '~/observers/jotai'
import {
  aerobicCapabilitiesRoute,
  corporalEvaluationRoute,
} from '~/routes/routes'
import { EvaluationRouteParams } from '~/routes/types'

import { formsLayoutContext } from '../../layout'

export const HemodynamicMeasuresForm: React.FC = () => {
  const navigate = useNavigate()
  const { scrollToTop } = useContext(formsLayoutContext)
  const { evaluation_id, student_id } = useParams<EvaluationRouteParams>()
  const { freqCardiacaMaximaSugerida, data } = useHemodynamicMeasurements()
  const [anamnesis] = useAtom(anamnesisAtom)
  const [loading, setLoading] = useState<boolean>(false)

  const handleSave = useCallback(() => {
    setLoading(true)
    if (!evaluation_id || !student_id) return
    saveHemodynamicMeasurements(student_id, evaluation_id).then((success) => {
      if (success) {
        scrollToTop()
        navigate(
          aerobicCapabilitiesRoute
            .replace(':student_id', student_id)
            .replace(':evaluation_id', evaluation_id),
          { replace: true },
        )
      }
      setLoading(false)
    })
  }, [evaluation_id, student_id, navigate, scrollToTop])

  const handleGoBack = useCallback(() => {
    if (!student_id || !evaluation_id) return
    navigate(
      corporalEvaluationRoute
        .replace(':student_id', student_id)
        .replace(':evaluation_id', evaluation_id),
      {
        replace: true,
      },
    )
    scrollToTop()
  }, [navigate, scrollToTop, student_id, evaluation_id])

  return (
    <div className='flex flex-col px-6 py-9 gap-6 rounded-2xl bg-white flex-wrap'>
      <QuestionFrame title='Medidas' isValid={false}>
        <div className='grid gap-5 grid-cols-[repeat(auto-fit,minmax(250px,1fr))] md:block'>
          <div className='group flex flex-wrap items-center gap-3'>
            <TextInput
              label='Frequência Cardíaca Máxima (bpm)'
              placeholder='0'
              value={data?.maxHeartFrequency || 0}
              onChange={(e) =>
                updateHemodynamicMeasurementsData({
                  maxHeartFrequency: +e.target.value.replaceAll(/\D/g, ''),
                })
              }
            />
            <div>
              <Button onClick={freqCardiacaMaximaSugerida} className='mt-4'>
                Sugerir
              </Button>
            </div>
          </div>
          <TextInput
            label='Pressão Sistólica (mmHg)'
            placeholder='Ex.: 120'
            value={
              anamnesis?.arterialSystolicPressure?.systolic ||
              data?.sistolicPressure ||
              0
            }
            onChange={(e) =>
              updateHemodynamicMeasurementsData({
                sistolicPressure: +e.target.value.replaceAll(/\D/g, ''),
              })
            }
          />
          <TextInput
            label='Pressão Diastólica (mmHg)'
            placeholder='Ex.: 80'
            value={
              anamnesis?.arterialSystolicPressure?.diastolic ||
              data?.diastolicPressure ||
              0
            }
            onChange={(e) =>
              updateHemodynamicMeasurementsData({
                diastolicPressure: +e.target.value.replaceAll(/\D/g, ''),
              })
            }
          />
          <TextInput
            label='Frequência Cardíaca em Repouso (bpm)'
            placeholder='0'
            value={data?.restingHeartFrequency || 0}
            onChange={(e) =>
              updateHemodynamicMeasurementsData({
                restingHeartFrequency: +e.target.value.replaceAll(/\D/g, ''),
              })
            }
          />
          <div className='flex flex-col flex-1 gap-3 min-w-[80px]'>
            <span>Haverá prescrição na água?</span>
            <RadioButtonArray
              value={data?.hasInWaterPrescription}
              onChange={(value) =>
                updateHemodynamicMeasurementsData({
                  hasInWaterPrescription: value,
                })
              }
              options={[
                { label: 'Não', value: false },
                { label: 'Sim', value: true },
              ]}
            />
            <span>&nbsp;</span>
          </div>
          {data?.hasInWaterPrescription ? (
            <TextInput
              label='Frequência Cardíaca em Repouso na Água (bpm)'
              placeholder='0'
              value={data?.restingHeartFrequencyInWater || 0}
              onChange={(e) =>
                updateHemodynamicMeasurementsData({
                  restingHeartFrequencyInWater: +e.target.value.replaceAll(
                    /\D/g,
                    '',
                  ),
                })
              }
            />
          ) : null}
        </div>
      </QuestionFrame>
      <div className='flex justify-between'>
        <Button
          type='button'
          loading={loading}
          disabled={loading}
          onClick={handleGoBack}
        >
          <FiChevronLeft size={24} />
          <span className='mb-1'>Voltar</span>
        </Button>
        <Button
          loadingMessage='Salvando...'
          loading={loading}
          disabled={loading}
          onClick={handleSave}
        >
          <span className='mb-1'>Salvar</span>
          <FiChevronRight size={24} />
        </Button>
      </div>
    </div>
  )
}
