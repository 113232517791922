import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { PageContainer } from './PageContainer'
import { TopBar } from './TopBar'
import { LayoutContextData } from './types'

export const LayoutContext = createContext<LayoutContextData>(
  {} as LayoutContextData,
)

export const LayoutProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const mainRef = useRef<HTMLDivElement>(null)
  const [hasScrolled, setHasScrolled] = useState<boolean>(false)

  const scrollToTop = useCallback(() => {
    mainRef.current?.scrollTo({
      top: 0,
    })
  }, [])

  useEffect(() => {
    const pageContainer = mainRef.current

    const handleScroll = () => {
      if (pageContainer) {
        setHasScrolled(pageContainer.scrollTop > 0)
      }
    }

    if (pageContainer) {
      mainRef.current.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (pageContainer) {
        pageContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  return (
    <PageContainer ref={mainRef}>
      <LayoutContext.Provider value={{ scrollToTop, hasScrolled }}>
        <TopBar />
        <main className='print:block flex flex-1 bg-gpa-gray-50 print:overflow-visible overflow-hidden'>
          {children}
        </main>
      </LayoutContext.Provider>
    </PageContainer>
  )
}
