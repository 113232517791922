import 'swiper/css'
import 'swiper/css/scrollbar'
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'

import { EvaluationContext } from '~/contexts/studentEvaluationContext'

import { AerobicCapabilitiesForm } from './components/aerobicCapabitiliesForm'
import { AerobicExercises } from './components/aerobicExercises'
import { AerobicPrescription } from './components/aerobicPrescription'
import { AnamnesisForm } from './components/anamnesisForm'
import { AntropometryForm } from './components/antropometryForm'
import { CorporalEvaluationForm } from './components/corporalEvaluationForm'
import { ExercisesPrescription } from './components/exercisesPrescription'
import { HemodynamicMeasuresForm } from './components/hemodynamicMeasuresForm'
import { Macrocycles } from './components/macrocycles'
import { Scheduler } from './components/scheduler'
import { SeriesEdit } from './components/SeriesEdit'
import { FormsLayout } from './layout'

export const EvaluationForms: React.FC = () => {
  return (
    <EvaluationContext>
      <Routes>
        <Route
          path='/'
          element={
            <FormsLayout>
              <Outlet />
            </FormsLayout>
          }
        >
          <Route path='anamnese' element={<AnamnesisForm />} />
          <Route path='antropometria' element={<AntropometryForm />} />
          <Route
            path='avaliacao_corporal'
            element={<CorporalEvaluationForm />}
          />
          <Route
            path='medidas_hemodinamicas'
            element={<HemodynamicMeasuresForm />}
          />
          <Route
            path='capacidade_aerobica'
            element={<AerobicCapabilitiesForm />}
          />
          <Route path='prescricao_aerobica' element={<AerobicPrescription />} />
          <Route path='agendamento' element={<Scheduler />} />
          <Route path='exercicios_aerobicos' element={<AerobicExercises />} />
          <Route
            path='prescricao_de_exercicios'
            element={<ExercisesPrescription />}
          />
          <Route path='personalizacao_da_serie' element={<SeriesEdit />} />

          <Route path='macrociclos' element={<Macrocycles />} />

          <Route path='/' element={<Navigate to={'anamnese'} replace />} />
        </Route>
      </Routes>
    </EvaluationContext>
  )
}
