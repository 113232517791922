import { generateReportBody } from '~/modules/corporalEvaluation/services'
import { useCorporalEvaluation } from '~/modules/corporalEvaluation/useCorporalEvaluation'

import { ChartBodyCompositionAnalysis } from './ChartBodyCompositionAnalysis'
import { Header } from './Header'

export const CorporalCompositionAnalysis: React.FC = () => {
  const {
    data: corporalEvaluation,
    percentualDeGorduraMedido,
    MCMPercent,
    MCM,
    peso,
    pesoGordo,
    pesoGordoSugerido,
    pesoGordoExcedenteDoProposto,
    CalcPesoSugerido,
    MCMSugerido,
    sugerePercentGordura,
    MCMPercentSugerido,
    MCMCarencia,
    classificacaoPercentualGorduraEMCM,
  } = useCorporalEvaluation()

  return (
    <div className='flex flex-col px-8  py-7 w-full page-break'>
      <Header title='Análise da Composição Corporal' />

      {/* Section Medidas */}
      <div className='flex justify-center border border-black bg-zinc-300 my-4'>
        <span className='font-bold text-sm'>Medidas</span>
      </div>

      <div className='grid grid-cols-2'>
        <div>
          <div className='flex justify-between'>
            <span className='font-bold'>Medida</span>
            <span className='font-bold'>Média</span>
          </div>
          {generateReportBody().map((row, index) => (
            <div key={`protocolRow-${index}`} className='flex justify-between'>
              <span>{row[0]}</span>
              <span>{row[1]}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Section Protocolo */}
      <div className='flex border border-black bg-zinc-300'>
        <span className='font-bold text-sm pl-2'>
          {corporalEvaluation?.protocol}
        </span>
      </div>

      {/* Section Parameters */}
      <div className='flex justify-between gap-2 font-bold border-l border-black'>
        <div className='flex gap-2 py-3 pl-3'>
          <div className='flex flex-col space-y-1'>
            <span className='mt-5'>% Gordura</span>
            <span>% MCM</span>
            <span>MCM (kg)</span>
            <span>Peso total de gordura - Kg</span>
            <span>Peso (Kg)</span>
          </div>

          <div className='flex flex-col'>
            <div className='font-bold flex justify-center'>Medido</div>
            <div className='flex flex-col justify-end gap-1 w-24'>
              <div className='flex justify-end gap-2 border border-black pr-1 pl-6'>
                {percentualDeGorduraMedido.toFixed(2)}
              </div>
              <div className='flex justify-end gap-2 border border-black pr-1'>
                {MCMPercent}
              </div>
              <div className='flex justify-end gap-2 border border-black pr-1'>
                {MCM}
              </div>
              <div className='flex justify-end gap-2 border border-black pr-1'>
                {pesoGordo ?? ''}
              </div>
              <div className='flex justify-end gap-2 border border-black pr-1'>
                {peso ?? ''}
              </div>
            </div>
          </div>
        </div>

        <div className='border-r border-black'></div>

        <div className='flex flex-col mt-12 gap-1 w-64 py-3'>
          <div className='border border-black pr-1 flex justify-end'>
            {MCMCarencia ?? ''} Kg (Carência Muscular)
          </div>
          <div className='border border-black pr-1 flex justify-end'>
            {pesoGordoExcedenteDoProposto ?? ''} Kg (Gordura Excedente)
          </div>
        </div>

        <div className='border-r border-black'></div>

        <div className='flex flex-col py-3'>
          <div className='font-bold flex justify-center'>Sugerido</div>
          <div className='flex flex-col justify-end gap-1 w-24'>
            <div className='flex justify-end gap-2 border border-black pr-1'>
              {sugerePercentGordura.toFixed(2) ?? ''}
            </div>
            <div className='flex justify-end gap-2 border border-black pr-1'>
              {MCMPercentSugerido ?? ''}
            </div>
            <div className='flex justify-end gap-2 border border-black pr-1'>
              {MCMSugerido ?? ''}
            </div>
            <div className='flex justify-end gap-2 border border-black pr-1'>
              {pesoGordoSugerido ?? ''}
            </div>
            <div className='flex justify-end gap-2 border border-black pr-1'>
              {CalcPesoSugerido ?? ''}
            </div>
          </div>
        </div>

        <div className='pr-1 border-r border-black'></div>
      </div>

      {/* Section Cassification */}
      <div className='font-bold border-t border-black'>
        <div className='my-4'>
          <span className='mr-4'>Classificação do % de Gordura</span>
          <span className='border border-black pl-14 px-2'>
            {classificacaoPercentualGorduraEMCM ?? ''}
          </span>
        </div>
      </div>

      {/* Section Graph */}
      <ChartBodyCompositionAnalysis />
    </div>
  )
}
