import { useMemo } from 'react'

import { useAtom } from '~/observers/jotai'
import { Option } from '~/types/types'

import { exercisesPrescriptionsAtom } from './data'

export function useExercisesPrescriptions() {
  const [data] = useAtom(exercisesPrescriptionsAtom)

  const exercisesOptions = useMemo<Option<string>[]>(
    () =>
      !data?.exercises
        ? []
        : data?.exercises.map<Option<string>>((e) => ({
            label: e.groupName,
            value: e.groupName,
          })),
    [data],
  )

  const standardizedSeriesOptions = useMemo<Option<string>[]>(
    () =>
      data?.standardizedSeries
        ? data.standardizedSeries.map<Option<string>>((e) => ({
            label: e.groupName,
            value: e.groupName,
          }))
        : [],
    [data],
  )

  return {
    data,
    exercisesOptions,
    standardizedSeriesOptions,
  }
}
