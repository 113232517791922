import ApexChart from 'react-apexcharts'

import { antropometryAtom } from '~/modules/antropometry/data'
import { useAtom } from '~/observers/jotai'

export const ChartAnthropometricMeasurements: React.FC = () => {
  const [antropometry] = useAtom(antropometryAtom)

  const categories = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
  ]

  const series = [
    {
      name: 'series',
      data: [
        antropometry?.shoulder || 0, // 1
        antropometry?.chestInflated || 0, // 2
        antropometry?.chestRegular || 0, // 3
        antropometry?.hip || 0, // 4
        antropometry?.abdomen || 0, // 5
        antropometry?.waist || 0, // 6
        antropometry?.rightThigh || 0, // 7
        antropometry?.leftThigh || 0, // 8
        antropometry?.rightLeg || 0, // 9
        antropometry?.leftLeg || 0, // 10
        antropometry?.rightArmContracted || 0, // 11
        antropometry?.leftArmContracted || 0, // 12
        antropometry?.rightArmRelaxed || 0, // 13
        antropometry?.leftArmRelaxed || 0, // 14
        antropometry?.rightForearm || 0, // 15
        antropometry?.leftForearm || 0, // 16
      ],
    },
  ]

  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      id: 'basic-bar',
    },

    legend: {
      show: false,
    },
    xaxis: {
      categories,
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
    colors: [
      '#9c4c4c',
      '#FFA000',
      '#388E3C',
      '#689F38',
      '#00B8D4',
      '#0c94e9',
      '#170ce9',
      '#730ce9',
      '#b20ce9',
      '#e90cd3',
      '#e90c59',
      '#c72626',
      '#88ad38',
      '#46a23c',
      '#3da077',
      '#3a6196',
      '#3b3993',
      '#8d3b86',
    ],
    plotOptions: {
      bar: {
        columnWidth: '65%',
        distributed: true,
      },
    },
  }

  return (
    <div className='h-96 w-full max-w-[190mm]'>
      <ApexChart
        type='bar'
        height={320}
        width='100%'
        series={series}
        options={chartOptions}
      />
    </div>
  )
}
