import { useEffect, useMemo, useRef } from 'react'

import { useHemodynamicMeasurements } from '~/modules/hemodynamicMeasurements/useHemodynamicMeasurements'
import { useAtom } from '~/observers/jotai'
import { computeAgeService } from '~/services/computeAgeService'
import { Option } from '~/types/types'

import { useAreobicCapabilities } from '../aerobicCapabilities/useAerobicCapabilities'
import { antropometryAtom } from '../antropometry/data'
import { evaluationAtom } from '../evalutation/data'
import { aerobicPrescriptionAtom } from './data'
import { updateAerobicPrescriptionsData } from './services'

export function useAerobicPrescription() {
  const [evaluation] = useAtom(evaluationAtom)
  const { data: hemodynamicData } = useHemodynamicMeasurements()
  const [antropometry] = useAtom(antropometryAtom)
  const { reserved } = useAreobicCapabilities()
  const [data] = useAtom(aerobicPrescriptionAtom)

  const intensityZoneMin = useMemo(() => {
    if (data?.targetZone) {
      switch (data.objectiveIndex) {
        case 0:
          return 28
        case 1:
          return 43
        case 2:
          return 57
        case 3:
          return 71
        case 4:
          return 84
        default:
          return 0
      }
    } else {
      switch (data?.objectiveIndex) {
        case 0:
          return 50
        case 1:
          return 61
        case 2:
          return 71
        case 3:
          return 81
        case 4:
          return 91
        default:
          return 0
      }
    }
  }, [data])

  const intensityZoneMax = useMemo(() => {
    if (data?.targetZone) {
      switch (data.objectiveIndex) {
        case 0:
          return 42
        case 1:
          return 56
        case 2:
          return 70
        case 3:
          return 83
        case 4:
          return 100
        default:
          return 100
      }
    } else {
      switch (data?.objectiveIndex) {
        case 0:
          return 60
        case 1:
          return 70
        case 2:
          return 80
        case 3:
          return 90
        case 4:
          return 100
        default:
          return 100
      }
    }
  }, [data])

  const intensityOptions = useMemo<Option<number>[]>(() => {
    const options: Option<number>[] = []
    for (let i = intensityZoneMin; i <= intensityZoneMax; i += 1) {
      options.push({
        label: '' + i,
        value: i,
      })
    }
    return options
  }, [intensityZoneMin, intensityZoneMax])

  const highlightedCellsIndex = useMemo(() => {
    if (data?.targetZone) {
      if (data.intensity >= 20 && data.intensity <= 39) {
        return [4, 5]
      }
      if (data.intensity >= 40 && data.intensity <= 59) {
        return [6, 7]
      }
      if (data.intensity >= 60 && data.intensity <= 84) {
        return [8, 9, 10]
      }
      if (data.intensity >= 85 && data.intensity <= 99) {
        return [11, 12, 13]
      }
      return [14]
    }
    if ((data?.intensity || 0) >= 35 && (data?.intensity || 0) <= 54) {
      return [4, 5]
    }
    if ((data?.intensity || 0) >= 55 && (data?.intensity || 0) <= 69) {
      return [6, 7]
    }
    if ((data?.intensity || 0) >= 70 && (data?.intensity || 0) <= 89) {
      return [8, 9, 10]
    }
    if ((data?.intensity || 0) >= 90 && (data?.intensity || 0) <= 99) {
      return [11, 12, 13]
    }
    return [14]
  }, [data?.targetZone, data?.intensity])

  const maxMinorHeartFrequency = useMemo(() => {
    const DP = computeAgeService(evaluation?.student.birthday) < 26 ? 10 : 12
    return (hemodynamicData?.maxHeartFrequency ?? 0) - DP
  }, [hemodynamicData?.maxHeartFrequency, evaluation])

  const maxMajorHeartFrequency = useMemo(() => {
    const DP = computeAgeService(evaluation?.student.birthday) < 26 ? 10 : 12
    return (hemodynamicData?.maxHeartFrequency ?? 0) + DP
  }, [hemodynamicData?.maxHeartFrequency, evaluation])

  const minorHeartfrequencyReserve = useMemo(() => {
    if (!maxMinorHeartFrequency || !hemodynamicData?.restingHeartFrequency) {
      return 0
    }
    return maxMinorHeartFrequency - hemodynamicData?.restingHeartFrequency
  }, [hemodynamicData, maxMinorHeartFrequency])

  const majorHeartFrequencyReserve = useMemo(() => {
    if (!maxMajorHeartFrequency || !hemodynamicData?.restingHeartFrequency) {
      return 0
    }
    return maxMajorHeartFrequency - hemodynamicData?.restingHeartFrequency
  }, [maxMajorHeartFrequency, hemodynamicData])

  const minorHeartfrequencyReserveInWater = useMemo(() => {
    if (
      !maxMinorHeartFrequency ||
      !hemodynamicData?.restingHeartFrequencyInWater
    ) {
      return 0
    }
    return (
      maxMinorHeartFrequency - hemodynamicData?.restingHeartFrequencyInWater
    )
  }, [hemodynamicData, maxMinorHeartFrequency])

  const majorHeartFrequencyReserveInWater = useMemo(() => {
    if (
      !maxMajorHeartFrequency ||
      !hemodynamicData?.restingHeartFrequencyInWater
    ) {
      return 0
    }
    return (
      maxMajorHeartFrequency - hemodynamicData?.restingHeartFrequencyInWater
    )
  }, [maxMajorHeartFrequency, hemodynamicData])

  const minorTrainingZone = useMemo(() => {
    if (data?.targetZone) {
      return +(
        minorHeartfrequencyReserve * (data.intensity / 100) +
        (hemodynamicData?.restingHeartFrequency || 0)
      ).toFixed()
    }
    return +(maxMinorHeartFrequency * ((data?.intensity || 0) / 100)).toFixed()
  }, [
    data?.targetZone,
    data?.intensity,
    hemodynamicData?.restingHeartFrequency,
    minorHeartfrequencyReserve,
    maxMinorHeartFrequency,
  ])

  const majorTrainingZone = useMemo(() => {
    if (data?.targetZone) {
      return +(
        majorHeartFrequencyReserve * (data.intensity / 100) +
        (hemodynamicData?.restingHeartFrequency || 0)
      ).toFixed()
    }
    return +(maxMajorHeartFrequency * ((data?.intensity || 0) / 100)).toFixed()
  }, [
    data?.targetZone,
    data?.intensity,
    hemodynamicData?.restingHeartFrequency,
    majorHeartFrequencyReserve,
    maxMajorHeartFrequency,
  ])

  const minorTrainingZoneInWater = useMemo(() => {
    if (data?.targetZone) {
      return +(
        minorHeartfrequencyReserveInWater * (data.intensity / 100) +
        (hemodynamicData?.restingHeartFrequencyInWater || 0)
      ).toFixed()
    }
    return +(maxMinorHeartFrequency * ((data?.intensity || 0) / 100)).toFixed()
  }, [
    data?.targetZone,
    data?.intensity,
    hemodynamicData?.restingHeartFrequencyInWater,
    minorHeartfrequencyReserveInWater,
    maxMinorHeartFrequency,
  ])

  const majorTrainingZoneInWater = useMemo(() => {
    if (data?.targetZone) {
      return +(
        majorHeartFrequencyReserveInWater * (data.intensity / 100) +
        (hemodynamicData?.restingHeartFrequencyInWater || 0)
      ).toFixed()
    }
    return +(maxMajorHeartFrequency * ((data?.intensity || 0) / 100)).toFixed()
  }, [
    data?.targetZone,
    data?.intensity,
    hemodynamicData?.restingHeartFrequencyInWater,
    majorHeartFrequencyReserveInWater,
    maxMajorHeartFrequency,
  ])

  const trainingVO2 = useMemo(() => {
    return +((reserved * (data?.intensity || 0)) / 100 + 3.5).toFixed(1)
  }, [reserved, data?.intensity])

  const trainingMETS = useMemo(() => {
    return +(trainingVO2 / 3.5).toFixed(1)
  }, [trainingVO2])

  const energyPerMinute = useMemo(() => {
    return +(
      (trainingMETS * 3.5 * (antropometry?.currentWeight || 0)) /
      200
    ).toFixed(1)
  }, [antropometry, trainingMETS])

  const initialSessionDuration = useMemo(() => {
    return +((data?.proposedEnergy || 0) / energyPerMinute).toFixed()
  }, [energyPerMinute, data?.proposedEnergy])

  const minimumKgcalForTrainging = useMemo(() => {
    return +((300 * (antropometry?.currentWeight || 0)) / 70).toFixed()
  }, [antropometry])

  const oldIndex = useRef<number | undefined>(data?.objectiveIndex)
  useEffect(() => {
    if (oldIndex.current === data?.objectiveIndex) return
    oldIndex.current = data?.objectiveIndex
    updateAerobicPrescriptionsData({ intensity: intensityZoneMin })
  }, [data?.objectiveIndex, data?.targetZone, intensityZoneMin])

  return {
    data,
    highlightedCellsIndex,
    intensityZoneMin,
    intensityOptions,
    intensityZoneMax,
    minorTrainingZone,
    majorTrainingZone,
    minorTrainingZoneInWater,
    majorTrainingZoneInWater,
    trainingVO2,
    trainingMETS,
    initialSessionDuration,
    minimumKgcalForTrainging,
    energyPerMinute,
  }
}
