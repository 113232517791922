import { api } from '~/utils/api'

import { stepCounterAtom } from '../evalutation/data'
import { insertToast } from '../toasts/services'
import { aerobicExercisesAtom, defaultAerobicExercises } from './data'
import { AerobicExercises } from './types'

export async function saveAerobicExercises(
  studentId: string,
  evaluationId: string,
): Promise<boolean> {
  const payload = { ...aerobicExercisesAtom.get() }
  if (!payload) return false

  delete payload.id
  delete payload.evaluationId
  delete payload.createdAt
  delete payload.updatedAt

  return api
    .patch(
      `personal-trainers/students/${studentId}/evaluations/${evaluationId}/aerobic-exercises`,
      payload,
    )
    .then(({ data }) => {
      if (data) {
        aerobicExercisesAtom.set(data)
        stepCounterAtom.get() < 8 && stepCounterAtom.set(8)
        return true
      }
      return false
    })
    .catch((e) => {
      if (e.response?.data.message) {
        insertToast({
          duration: 3,
          message: e.response?.data.message,
          title: 'Erro ao salvar',
        })
      } else {
        insertToast({
          duration: 3,
          message: 'Erro interno',
          title: 'Erro ao salvar',
        })
      }
      return false
    })
}

export function addNewExercise(exercise: string) {
  if (!aerobicExercisesAtom.get()) return
  if (aerobicExercisesAtom.get()?.exercises.includes(exercise)) return
  const newState = { ...(aerobicExercisesAtom.get() as AerobicExercises) }
  newState.exercises = [...(newState.exercises || []), exercise]
  aerobicExercisesAtom.set(newState)
}

export function selectExercise(exercise: string) {
  if (!aerobicExercisesAtom.get()) return
  const newState = { ...(aerobicExercisesAtom.get() as AerobicExercises) }
  newState.exercisesOrder = [...(newState.exercisesOrder || []), exercise]
  aerobicExercisesAtom.set(newState)
}

export function removeExerciseFromOrder(index: number) {
  if (!aerobicExercisesAtom.get()) return
  const newState = { ...(aerobicExercisesAtom.get() as AerobicExercises) }
  newState.exercisesOrder.splice(index, 1)
  aerobicExercisesAtom.set(newState)
}

export function removeExercise(exercise: string) {
  if (!aerobicExercisesAtom.get()) return
  const newState = { ...(aerobicExercisesAtom.get() as AerobicExercises) }
  newState.exercises = newState.exercises.filter((e) => e !== exercise)
  newState.exercisesOrder = newState.exercisesOrder.filter(
    (e) => e !== exercise,
  )
  aerobicExercisesAtom.set(newState)
}

export function recoverStandardAerobicExercises() {
  const oldData = aerobicExercisesAtom.get()
  const newActivities = defaultAerobicExercises.filter(
    (item) => !oldData?.exercises.includes(item),
  )

  if (aerobicExercisesAtom.get()) {
    aerobicExercisesAtom.set({
      ...(oldData as AerobicExercises),
      exercises: [...(oldData?.exercises ?? []), ...newActivities],
    })
  }
}

export function clearAerobicExercises() {
  aerobicExercisesAtom.set(undefined)
}
