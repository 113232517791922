import axios, { AxiosError } from 'axios'

import { insertToast } from '~/modules/toasts/services'
import { api } from '~/utils/api'

import { CreateInvitationLinkDTO } from './schemas'
import {
  InvitationLink,
  InvitationLinksQuery,
  InvitationLinkVerificationDTO,
  ListInvitationLinksDTO,
  PatchInvitationLinkDTO,
} from './types'

export async function listInvitationLinks(
  params: InvitationLinksQuery,
  controller: AbortController,
) {
  return api
    .get<ListInvitationLinksDTO>('/admin/registration-links', {
      params,
      signal: controller.signal,
    })
    .then(({ data }) => data)
    .catch((e: AxiosError<APIError>) => {
      if (e.response?.data.message) {
        insertToast({
          title: 'Erro ao listar convites',
          message: e.response?.data.message,
          type: 'error',
          duration: 3,
        })
      }
    })
}

export async function createInvitationLink(payload: CreateInvitationLinkDTO) {
  return api
    .post('/admin/registration-links', payload)
    .then(({ data }) => {
      insertToast({
        title: 'Sucesso!',
        message: 'Link de Cadastro Gerado com Sucesso',
        type: 'success',
        duration: 3,
      })
      return data
    })
    .catch((e: AxiosError<APIError>) => {
      console.log(e)
    })
}

export async function toggleInvitationLinkActiveStatus(
  payload: PatchInvitationLinkDTO,
) {
  return api
    .patch<InvitationLink>(`/admin/registration-links/${payload.id}`, {
      active: payload.active,
    })
    .then(({ data }) => data)
    .catch((e: AxiosError<APIError>) => {
      if (e.response?.data.message) {
        insertToast({
          title: 'Erro ao listar convites',
          message: e.response?.data.message,
          type: 'error',
          duration: 3,
        })
      }
    })
}

export async function validateInvitationLink(
  id: string,
  controller: AbortController,
) {
  return axios
    .get<InvitationLinkVerificationDTO>(
      `/personal-trainers/registration-link/${id}`,
      {
        baseURL: import.meta.env.VITE_BASE_URL_API,
        signal: controller.signal,
      },
    )
    .then(({ data }) => data)
    .catch((e: AxiosError<APIError>) => {
      if (e.response?.data.message) {
        insertToast({
          message: e.response?.data.message,
          title: 'Falha ao validar convite',
          duration: 6,
          type: 'error',
        })
      }
      return undefined
    })
}
