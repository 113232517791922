import { AxiosError } from 'axios'

import { swapItemsInArrayService } from '~/services/swapItemsInArrayService'
import { api } from '~/utils/api'

import { stepCounterAtom } from '../evalutation/data'
import { exercisesPrescriptionsAtom } from '../exercisesPrescriptions/data'
import { saveExercisesPrescriptions } from '../exercisesPrescriptions/services'
import { MoveActivityDirection } from '../scheduler/types'
import { insertToast } from '../toasts/services'
import {
  aerobicMacrocycleAtom,
  standardizedSeriesAtom,
  strengthMacrocycleAtom,
} from './data'
import { Exercise, ExerciseSeries, ExerciseType } from './types'

export async function saveStandardizedSeries(
  studentId: string,
  evaluationId: string,
) {
  const payload = { ...standardizedSeriesAtom.get() }

  delete payload.id
  delete payload.evaluationId
  delete payload.createdAt
  delete payload.updatedAt

  if (!payload.series?.length) {
    insertToast({
      duration: 3,
      type: 'error',
      message: 'Nenhuma série inicializada',
      title: 'Quantidade de séries inválida',
    })

    return
  }

  await saveExercisesPrescriptions(studentId, evaluationId)
  return api
    .patch(
      `personal-trainers/students/${studentId}/evaluations/${evaluationId}/standardized-series`,
      payload,
    )
    .then(({ data }) => {
      if (data) {
        stepCounterAtom.get() < 9 && stepCounterAtom.set(9)
        standardizedSeriesAtom.set(data)
      }
      return !!data
    })
    .catch((error: AxiosError<APIError>) => {
      if (error.response?.data.message) {
        insertToast({
          duration: 3,
          type: 'error',
          message: error.response?.data.message,
          title: 'Erro ao tentar criar séries periodizadas',
        })
      }
      return false
    })
}

export function getIntensityRange(exerciseType: ExerciseType) {
  switch (exerciseType) {
    case 'Endurance':
      return [20, 35]
    case 'Força Dinâmica - Hipertrofia':
      return [70, 85]
    case 'Força Explosiva':
      return [30, 60]
    case 'R.M.L. - Resistência Muscular Localizada':
      return [40, 60]
    case 'Força Pura':
      return [90, 100]
    default:
      return [0, 100]
  }
}

export function suggestRepetitionNumber(exercise: string, intensity: number) {
  const exercises = exercisesPrescriptionsAtom.get()?.exercises || []

  const exerciseGroupName = exercises.find((group) =>
    group.exercises.includes(exercise),
  )?.groupName
  const group1 = ['Panturrilha', 'Coxas e gluteos', 'Abdomen']
  const group2 = ['Peito', 'Costas', 'Ombro', 'Biceps', 'Triceps', 'Antebraço']

  if (!exerciseGroupName) return 0

  if (group1.includes(exerciseGroupName)) return +(800 / intensity).toFixed()
  else if (group2.includes(exerciseGroupName))
    return +(600 / intensity).toFixed()
  else return 1
}

export function restoreRepetitionNumber(
  seriesIndex: number,
  exerciseIndex: number,
) {
  const standardizedSeries = standardizedSeriesAtom.get()
  if (!standardizedSeries) return
  const series = [...standardizedSeries.series]

  if (!series.length || !series[seriesIndex].exercises.length) return

  series[seriesIndex].exercises[exerciseIndex].repetitions =
    suggestRepetitionNumber(
      series[seriesIndex].exercises[exerciseIndex].name,
      series[seriesIndex].intensity,
    )

  standardizedSeriesAtom.set({
    ...standardizedSeries,
    series,
  })
}

export function restoreNumSeries(
  seriesIndex: number,
  exerciseIndex: number,
  score: number,
) {
  const standardizedSeries = standardizedSeriesAtom.get()
  if (!standardizedSeries) return
  const series = standardizedSeries.series

  if (!series.length || !series[seriesIndex].exercises.length) return

  let numSeries = 1
  if (score === 0 || score === 1) numSeries = 3
  if (score === 2 || score === 3) numSeries = 2

  series[seriesIndex].exercises[exerciseIndex].numSeries = numSeries
  standardizedSeriesAtom.set({
    ...standardizedSeries,
    series,
  })
}

export function updateSeriesService(
  payload: Partial<ExerciseSeries>,
  seriesIndex: number,
) {
  const standardizedSeries = standardizedSeriesAtom.get()
  if (!standardizedSeries) return

  const series = standardizedSeries.series
  series[seriesIndex] = { ...series[seriesIndex], ...payload }

  if (payload.intensity) {
    series[seriesIndex].exercises = series[seriesIndex].exercises.map((e) => ({
      ...e,
      repetitions: suggestRepetitionNumber(e.name, payload.intensity as number),
    }))
  }

  standardizedSeriesAtom.set({
    ...standardizedSeries,
    series,
  })
}

export function updateExerciseInSeriesService(
  seriesIndex: number,
  exerciseIndex: number,
  payload: Partial<Exercise>,
) {
  const standardizedSeries = standardizedSeriesAtom.get()
  if (!standardizedSeries) return

  const series = standardizedSeries.series
  series[seriesIndex].exercises[exerciseIndex] = {
    ...series[seriesIndex].exercises[exerciseIndex],
    ...payload,
    initialCharge: +(
      (payload?.maximumCharge ?? 0) *
      (series[seriesIndex].intensity / 100)
    ).toFixed(0),
  }

  standardizedSeriesAtom.set({
    ...standardizedSeries,
    series,
  })
}

export function updateSuggestedNumberOfSeriesService(score: number) {
  const standardizedSeries = standardizedSeriesAtom.get()
  if (!standardizedSeries) return

  let numSeries = 1
  if (score === 0 || score === 1) numSeries = 3
  if (score === 2 || score === 3) numSeries = 2

  standardizedSeries.series.forEach((s) => {
    s.exercises.forEach((e) => {
      e.numSeries = numSeries
    })
  })

  standardizedSeriesAtom.set({ ...standardizedSeries })
}

export function removeExerciseFromSeries(
  exerciseIndex: number,
  seriesIndex: number,
) {
  const standardizedSeries = standardizedSeriesAtom.get()
  if (!standardizedSeries) return

  const { series } = standardizedSeries

  if (!series[seriesIndex]) return false
  if (!series[seriesIndex].exercises[exerciseIndex]) return false

  series[seriesIndex].exercises.splice(exerciseIndex, 1)

  standardizedSeriesAtom.set({
    ...standardizedSeries,
    series,
  })
  return true
}

export function clearAllExercisesFromSeriesService(seriesIndex: number) {
  const standardizedSeries = standardizedSeriesAtom.get()
  if (!standardizedSeries) return

  const { series } = standardizedSeries
  if (!series[seriesIndex]) return false
  series[seriesIndex].exercises = []
  standardizedSeriesAtom.set({ ...standardizedSeries, series })
}

export function moveExerciseInList(
  direction: MoveActivityDirection,
  exerciseIndex: number,
  seriesIndex: number,
) {
  const standardizedSeries = standardizedSeriesAtom.get()
  if (!standardizedSeries) return

  const { series } = standardizedSeries

  if (
    !series.length ||
    !series[seriesIndex] ||
    !series[seriesIndex].exercises.length ||
    (direction === 'up' && exerciseIndex === 0) ||
    (direction === 'down' &&
      series[seriesIndex].exercises.length === exerciseIndex + 1)
  ) {
    return
  }

  const newExercises = [...series[seriesIndex].exercises]

  direction === 'up'
    ? swapItemsInArrayService(newExercises, exerciseIndex, exerciseIndex - 1)
    : swapItemsInArrayService(newExercises, exerciseIndex, exerciseIndex + 1)

  series[seriesIndex].exercises = newExercises

  standardizedSeriesAtom.set({ ...standardizedSeries, series })
}

export function computeValuesViaStrengthTest(
  weight: number,
  numRepetitions: number,
  seriesIndex: number,
  exerciseIndex: number,
) {
  const standardizedSeries = standardizedSeriesAtom.get()
  if (!standardizedSeries) return

  const series = standardizedSeries.series
  const intensity = series[seriesIndex].intensity

  let computedWeight = 0
  switch (numRepetitions) {
    case 1:
      computedWeight = weight * 1
      break
    case 2:
      computedWeight = weight * 1.07
      break
    case 3:
      computedWeight = weight * 1.1
      break
    case 4:
      computedWeight = weight * 1.13
      break
    case 5:
      computedWeight = weight * 1.16
      break
    case 6:
      computedWeight = weight * 1.2
      break
    case 7:
      computedWeight = weight * 1.23
      break
    case 8:
      computedWeight = weight * 1.27
      break
    case 9:
      computedWeight = weight * 1.32
      break
    case 10:
      computedWeight = weight * 1.36
      break
    default:
      computedWeight = 0
      break
  }

  series[seriesIndex].exercises[exerciseIndex] = {
    ...series[seriesIndex].exercises[exerciseIndex],
    ...{
      maximumCharge: +computedWeight.toFixed(),
      initialCharge: +(computedWeight * (intensity / 100)).toFixed(),
    },
  }

  standardizedSeriesAtom.set({
    ...standardizedSeries,
    series,
  })
}

export function divideSeries(totalValue: number, numSeries: number) {
  if (!totalValue) return '0'

  const result = []
  const remainder = totalValue % numSeries

  for (let i = 0; i < numSeries; i++) {
    result.push(Math.floor(totalValue / numSeries))
  }

  for (let i = 0; i < remainder; i++) {
    result[i] += 1
  }

  return result.join('x')
}

export function clearStandardizedSeries() {
  standardizedSeriesAtom.set(undefined)
  aerobicMacrocycleAtom.set([])
  strengthMacrocycleAtom.set([])
}
