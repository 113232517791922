import { useEffect, useState } from 'react'

import { TextInput } from '~/components/TextInput'
import { aerobicCapabilitiesAtom } from '~/modules/aerobicCapabilities/data'
import { updateAerobicCapabilitiesData } from '~/modules/aerobicCapabilities/services'
import { MedicalProtocolData } from '~/modules/aerobicCapabilities/types'

export const Medical: React.FC = () => {
  const data = aerobicCapabilitiesAtom.get()
  const [value, setValue] = useState<MedicalProtocolData>({
    heartRate: data?.medical?.heartRate,
    maxVO2Consumtion: data?.medical?.maxVO2Consumtion,
  })

  useEffect(() => {
    updateAerobicCapabilitiesData({ medical: value })
  }, [value])

  return (
    <div className='flex gap-3'>
      <TextInput
        label='Consumo Máximo de VO2'
        placeholder='0'
        defaultValue={value?.maxVO2Consumtion}
        onChange={(e) =>
          setValue((state) => ({
            ...state,
            maxVO2Consumtion: +e.target.value.replace(/\D/, ''),
          }))
        }
      />
      <TextInput
        label='Frequência Cardíaca Final (bpm)'
        placeholder='0'
        defaultValue={value?.heartRate}
        onChange={(e) =>
          setValue((state) => ({
            ...state,
            heartRate: +e.target.value.replace(/\D/, ''),
          }))
        }
      />
    </div>
  )
}
