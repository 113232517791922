import { useCallback } from 'react'

import { MaskedInput } from '~/components/TextInput'
import { corporalEvaluationAtom } from '~/modules/corporalEvaluation/data'
import {
  setCorporalEvaluationImageIndex,
  updateCorporalEvaluationData,
} from '~/modules/corporalEvaluation/services'

export const Direto: React.FC = () => {
  const data = corporalEvaluationAtom.get()

  const handleFocus = useCallback(() => {
    setCorporalEvaluationImageIndex(0)
  }, [])

  return (
    <div>
      <MaskedInput
        value={data?.direct || 0}
        mode='horizontal'
        label='Percentual de Gordura'
        placeholder='0,0'
        mask='float'
        onFocus={handleFocus}
        onChange={(v) => {
          updateCorporalEvaluationData({
            direct: +v,
          })
        }}
      />
    </div>
  )
}
