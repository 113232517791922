import { useEffect, useState } from 'react'

import { TextInput } from '~/components/TextInput'
import { aerobicCapabilitiesAtom } from '~/modules/aerobicCapabilities/data'
import { updateAerobicCapabilitiesData } from '~/modules/aerobicCapabilities/services'
import { CooperProtocolData } from '~/modules/aerobicCapabilities/types'

export const Cooper: React.FC = () => {
  const data = aerobicCapabilitiesAtom.get()
  const [value, setValue] = useState<CooperProtocolData>({
    distance: data?.cooper?.distance,
  })

  useEffect(() => {
    updateAerobicCapabilitiesData({
      cooper: value,
    })
  }, [value])

  return (
    <div>
      <TextInput
        defaultValue={value?.distance}
        label='Distância (m)'
        placeholder='0'
        onChange={(e) => {
          setValue({
            distance: +e.target.value.replace(/[\D]/, ''),
          })
        }}
      />
    </div>
  )
}
