import { AxiosError } from 'axios'

import { api } from '~/utils/api'

import { stepCounterAtom } from '../evalutation/data'
import { aerobicPrescriptionAtom } from './data'
import { AerobicPrescription } from './types'

export function updateAerobicPrescriptionsData(
  payload: Partial<AerobicPrescription>,
) {
  if (!aerobicPrescriptionAtom.get()) return
  aerobicPrescriptionAtom.set({
    ...aerobicPrescriptionAtom.get(),
    ...(payload as AerobicPrescription),
  })
}

export async function saveAerobicPrescriptions(
  studentId: string,
  evaluationId: string,
) {
  const payload = { ...aerobicPrescriptionAtom.get() }

  delete payload.id
  delete payload.evaluationId
  delete payload.createdAt
  delete payload.updatedAt

  return api
    .patch(
      `/personal-trainers/students/${studentId}/evaluations/${evaluationId}/aerobic-prescriptions`,
      payload,
    )
    .then(({ data }) => {
      if (data) {
        aerobicPrescriptionAtom.set(data)
        stepCounterAtom.get() < 6 && stepCounterAtom.set(6)
      }

      return true
    })
    .catch((e: AxiosError<APIError>) => {
      console.log(e)

      return false
    })
}

export function clearAerobicPrescriptions() {
  aerobicPrescriptionAtom.set(undefined)
}
