import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { EvaluationForms } from './components/formsContainer'
import { InfoBoards } from './components/InfoBoards'

export const StudentEvaluation: React.FC = () => {
  const { pathname } = useLocation()

  const shouldHide = useMemo(() => pathname.endsWith('macrociclos'), [pathname])

  return (
    <div className='flex flex-1 gap-x-4 px-4 flex-wrap overflow-y-auto'>
      <EvaluationForms />
      {shouldHide ? null : <InfoBoards />}
    </div>
  )
}
