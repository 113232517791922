import ApexChart from 'react-apexcharts'

import { antropometryAtom } from '~/modules/antropometry/data'
import { useCorporalEvaluation } from '~/modules/corporalEvaluation/useCorporalEvaluation'
import { useAtom } from '~/observers/jotai'

const categories = [
  '% de Gordura',
  '% MCM',
  'MCM Kg',
  'Peso Gordo',
  'Peso (Kg)',
]

const chartOptions: ApexCharts.ApexOptions = {
  chart: {
    id: 'basic-bar',
  },
  legend: {
    show: false,
  },
  xaxis: {
    categories,
    labels: {
      style: {
        fontSize: '0px',
      },
    },
  },
  colors: [
    '#9c4c4c',
    '#e9d70c',
    '#7be90c',
    '#0ce91f',
    '#0ce9c4',
    '#0c94e9',
    '#170ce9',
    '#730ce9',
    '#b20ce9',
    '#e90cd3',
    '#e90c59',
    '#c72626',
    '#88ad38',
    '#46a23c',
    '#3da077',
    '#3a6196',
    '#3b3993',
    '#8d3b86',
  ],
  plotOptions: {
    bar: {
      columnWidth: '45%',
      distributed: true,
    },
  },
}

export const Chart: React.FC = () => {
  const [antropometry] = useAtom(antropometryAtom)
  const {
    percentualDeGorduraMedido,
    pesoGordo,
    MCMPercent,
    CalcPesoSugerido,
    sugerePercentGordura,
    MCMSugerido,
    MCM,
    MCMPercentSugerido,
    pesoGordoSugerido,
  } = useCorporalEvaluation()

  return (
    <div className='overflow-x-auto h-full'>
      <div className='h-full overflow-y-hidden min-w-[600px]'>
        <ApexChart
          type='bar'
          height='100%'
          series={[
            {
              data: [
                +percentualDeGorduraMedido.toFixed(1),
                MCMPercent,
                MCM,
                pesoGordo,
                antropometry?.currentWeight || 0,
              ],
              name: 'Medido',
            },
            {
              data: [
                +sugerePercentGordura.toFixed(1),
                MCMPercentSugerido,
                MCMSugerido,
                pesoGordoSugerido,
                CalcPesoSugerido,
              ],
              name: 'Proposto',
            },
          ]}
          options={chartOptions}
        />
      </div>
    </div>
  )
}
