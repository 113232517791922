import { useAtom } from '~/observers/jotai'

import { aerobicExercisesAtom } from './data'

export function useAerobicExercises() {
  const [data] = useAtom(aerobicExercisesAtom)

  return {
    data,
  }
}
