import { useCallback, useMemo } from 'react'

import { useIMCFormula } from '~/modules/antropometry/useIMCData'
import { useCorporalEvaluation } from '~/modules/corporalEvaluation/useCorporalEvaluation'
import { useAtom } from '~/observers/jotai'
import { computeAgeService } from '~/services/computeAgeService'

import { antropometryAtom } from '../antropometry/data'
import { evaluationAtom } from '../evalutation/data'
import { aerobicCapabilitiesAtom } from './data'

export function useAreobicCapabilities() {
  const [evaluation] = useAtom(evaluationAtom)
  const { percentualDeGorduraMedido } = useCorporalEvaluation()
  const { calcIMC } = useIMCFormula()
  const [data] = useAtom(aerobicCapabilitiesAtom)
  const [antropometry] = useAtom(antropometryAtom)

  const mets = useMemo(() => {
    const age = computeAgeService(evaluation?.student.birthday)
    return 4.7 + 0.97 * (data?.slope?.vsaq ?? 0) - 0.06 * age
  }, [evaluation, data?.slope?.vsaq])

  const velocityInKmPerHour = useMemo(() => {
    return (data?.slope?.speed || 0) * 1.609344
  }, [data?.slope])

  const vo2 = useMemo(() => {
    switch (data?.protocol) {
      case 'Bruno Balk - Teste de Pista':
        if (!data?.brunoBalk?.distance || !data.brunoBalk?.time) return 0
        return (
          33.3 + (data.brunoBalk.distance / data.brunoBalk.time - 133) * 0.17
        )
      case 'Cooper - Teste de Pista':
        if (!data?.cooper?.distance) return 0
        return (data.cooper.distance - 504.1) / 44.8
      case 'Margaria - Teste de Pista':
        if (!data?.margaria?.distance || !data?.margaria?.time) return 0
        return (data.margaria.distance / data.margaria.time + 30) / 5
      case 'Rockport - Teste de Caminhada':
        if (
          !data?.rockport?.time ||
          !data?.rockport?.heartRate ||
          !antropometry?.currentWeight
        )
          return 0
        return (
          132.853 -
          0.0769 * antropometry.currentWeight * 2.2 -
          0.3877 * computeAgeService(evaluation?.student.birthday) +
          6.315 * (evaluation?.student.sex === 'masculino' ? 1 : 0) -
          3.2649 * data?.rockport.time -
          0.1565 * data?.rockport.heartRate
        )
      case 'UKK - Pessoas Sedentárias':
        if (!data?.ukk?.heartRate || !data?.ukk?.time || !calcIMC()) return 0
        return evaluation?.student.sex === 'masculino'
          ? 184 -
              4.65 * data.ukk.time -
              0.22 * data.ukk.heartRate -
              0.26 * computeAgeService(evaluation?.student.birthday) -
              1.05 * calcIMC()
          : 116.2 -
              2.98 * data.ukk.time -
              0.11 * data.ukk.heartRate -
              0.14 * computeAgeService(evaluation?.student.birthday) -
              0.39 * calcIMC()
      case 'Médico - VO2 e Freq. Máxima':
        if (!data.medical?.maxVO2Consumtion) return 0
        return data.medical?.maxVO2Consumtion
      case 'Polar - OwnIndex':
        if (!data.ownIndex?.maxVO2Consumtion) return 0
        return data.ownIndex.maxVO2Consumtion
      case 'Questionário de Duke': {
        let r = 0
        if (data.duke[0]) r += 2.75
        if (data.duke[1]) r += 1.75
        if (data.duke[2]) r += 2.75
        if (data.duke[3]) r += 5.5
        if (data.duke[4]) r += 8
        if (data.duke[5]) r += 2.7
        if (data.duke[6]) r += 3.5
        if (data.duke[7]) r += 8
        if (data.duke[8]) r += 4.5
        if (data.duke[9]) r += 5.25
        if (data.duke[10]) r += 6
        if (data.duke[11]) r += 7.5
        return 0.43 * r + 9.6
      }
      case 'Rampa Manual Individualizada': {
        if (data?.slope?.speed === 0 || mets === 0) {
          return 0
        }

        const metersPerMin = +(velocityInKmPerHour * 16.666666667).toFixed()

        if (mets < 9) {
          return (
            0.1 * metersPerMin +
            (1.8 * metersPerMin * data?.slope?.steepness) / 100 +
            3.5
          )
        }
        return (
          0.2 * metersPerMin +
          0.9 * metersPerMin * (data?.slope?.steepness / 100) +
          3.5
        )
      }

      default:
        return 0
    }
  }, [data, evaluation, mets, velocityInKmPerHour, antropometry, calcIMC])

  const foreseenVO2 = useMemo(
    () =>
      57.5 -
      0.31 * computeAgeService(evaluation?.student.birthday) -
      0.37 * percentualDeGorduraMedido,
    [evaluation, percentualDeGorduraMedido],
  )

  const reserved = useMemo(() => vo2 - 3.5, [vo2])

  const METSMax = useMemo(() => vo2 / 3.5, [vo2])

  const suggestVO2 = useCallback(() => {
    const age = computeAgeService(evaluation?.student.birthday)

    if (evaluation?.student.sex === 'masculino') {
      if (age <= 29) {
        if (vo2 > 48.2) return vo2
        else if (vo2 > 44.23) return 51
        else if (vo2 > 37.13) return 48
        else return 44
      } else if (age <= 39) {
        if (vo2 > 46.75) return vo2
        else if (vo2 > 42.42) return 50
        else if (vo2 > 35.35) return 46
        else return 42
      } else if (age <= 49) {
        if (vo2 > 43.89) return vo2
        else if (vo2 > 39.89) return 47
        else if (vo2 > 33.04) return 43
        else return 39
      } else if (age <= 59) {
        if (vo2 > 40.98) return vo2
        else if (vo2 > 36.65) return 44
        else if (vo2 > 30.15) return 40
        else return 36
      } else {
        if (vo2 > 38.09) return vo2
        else if (vo2 > 33.59) return 42
        else if (vo2 > 26.54) return 38
        return 33
      }
    } else {
      if (age <= 29) {
        if (vo2 > 40.98) return vo2
        else if (vo2 > 36.65) return 43
        else if (vo2 > 30.63) return 40
        else if (vo2 > 25.89) return 36
        else return 34
      } else if (age <= 39) {
        if (vo2 > 38.57) return vo2
        else if (vo2 > 34.6) return 41
        else if (vo2 > 28.7) return 38
        else return 34
      } else if (age <= 49) {
        if (vo2 > 36.28) return vo2
        else if (vo2 > 32.31) return 39
        else if (vo2 > 29.45) return 36
        else return 32
      } else if (age <= 59) {
        if (vo2 > 32.31) return vo2
        else if (vo2 > 29.43) return 35
        else if (vo2 > 24.25) return 32
        else return 29
      } else {
        if (vo2 > 31.23) return vo2
        else if (vo2 > 27.21) return 34
        else if (vo2 > 22.78) return 31
        return 27
      }
    }
  }, [evaluation, vo2])

  const basal = useMemo<number>(() => {
    if (!antropometry?.currentWeight || !antropometry?.stature) return 0

    const age = computeAgeService(evaluation?.student.birthday)

    if (evaluation?.student.sex === 'masculino') {
      return +(
        66.47 +
        13.75 * antropometry.currentWeight +
        5 * antropometry.stature -
        6.76 * age
      ).toFixed()
    } else {
      return (
        655.1 +
        9.56 * antropometry.currentWeight +
        1.85 * antropometry.stature -
        4.68 * age
      )
    }
  }, [evaluation, antropometry])

  const active = useMemo(() => +(basal * 1.7).toFixed(), [basal])

  const moderatelyActive = useMemo(() => (basal * 1.5).toFixed(), [basal])

  const starter = useMemo(() => +(basal * 1.3).toFixed(), [basal])

  const score = useMemo(() => {
    const age = computeAgeService(evaluation?.student.birthday)
    if (evaluation?.student.sex === 'masculino') {
      if (age <= 29) {
        if (vo2 > 53) return 0
        else if (vo2 > 43) return 1
        else if (vo2 > 34) return 2
        else if (vo2 > 25) return 3
        else return 4
      }
      if (age <= 39) {
        if (vo2 > 49) return 0
        else if (vo2 > 39) return 1
        else if (vo2 > 31) return 2
        else if (vo2 > 23) return 3
        else return 4
      }
      if (age <= 49) {
        if (vo2 > 45) return 0
        else if (vo2 > 36) return 1
        else if (vo2 > 27) return 2
        else if (vo2 > 20) return 3
        else return 4
      }
      if (age <= 59) {
        if (vo2 > 43) return 0
        else if (vo2 > 34) return 1
        else if (vo2 > 25) return 2
        else if (vo2 > 18) return 3
        else return 4
      } else {
        if (vo2 > 41) return 0
        else if (vo2 > 31) return 1
        else if (vo2 > 23) return 2
        else if (vo2 > 16) return 3
        else return 4
      }
    } else {
      if (age <= 29) {
        if (vo2 > 49) return 0
        else if (vo2 > 38) return 1
        else if (vo2 > 31) return 2
        else if (vo2 > 24) return 3
        else return 4
      }
      if (age <= 39) {
        if (vo2 > 45) return 0
        else if (vo2 > 34) return 1
        else if (vo2 > 28) return 2
        else if (vo2 > 20) return 3
        else return 4
      }
      if (age <= 49) {
        if (vo2 > 42) return 0
        else if (vo2 > 31) return 1
        else if (vo2 > 24) return 2
        else if (vo2 > 17) return 3
        else return 4
      }
      if (age <= 59) {
        if (vo2 > 38) return 0
        else if (vo2 > 28) return 1
        else if (vo2 > 21) return 2
        else if (vo2 > 15) return 3
        else return 4
      } else {
        if (vo2 > 35) return 0
        else if (vo2 > 24) return 1
        else if (vo2 > 18) return 2
        else if (vo2 > 13) return 3
        else return 4
      }
    }
  }, [vo2, evaluation])

  const highlightIndex = useMemo(() => {
    if (score <= 1) return 2
    if (score <= 3) return 1
    if (score <= 4) return 0
  }, [score])

  const classification = useMemo(() => {
    switch (score) {
      case 0:
        return {
          label: 'Excelente',
          color: 'green',
          index: 5,
          completion: +((5 / 5) * 100).toFixed(2),
        }

      case 1:
        return {
          label: 'Boa',
          color: 'lime',
          index: 4,
          completion: +((4 / 5) * 100).toFixed(2),
        }
      case 2:
        return {
          label: 'Regular',
          color: 'amber',
          index: 3,
          completion: +((3 / 5) * 100).toFixed(2),
        }
      case 3:
        return {
          label: 'Fraca',
          color: 'orange',
          index: 2,
          completion: +((2 / 5) * 100).toFixed(2),
        }
      case 4:
        return {
          label: 'Muito Fraca',
          color: 'red',
          index: 1,
          completion: +((1 / 5) * 100).toFixed(2),
        }
      default:
        return {
          label: '',
          color: 'gpa-blue',
          index: 0,
          completion: 0,
        }
    }
  }, [score])

  return {
    data,
    vo2,
    mets,
    score,
    velocityInKmPerHour,
    basal,
    active,
    starter,
    moderatelyActive,
    classification,
    foreseenVO2,
    reserved,
    METSMax,
    highlightIndex,
    suggestVO2,
  }
}
