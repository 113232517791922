import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { FiX } from 'react-icons/fi'
import zod from 'zod'

import { Button } from '~/components/Button'
import { ExerciseTag } from '~/components/ExerciseTag'
import { Modal } from '~/components/Modal'
import { TextInput } from '~/components/TextInput'
import { schedulerAtom } from '~/modules/scheduler/data'
import {
  deleteActivity,
  recoverStandardActivities,
  updateSchedulerData,
} from '~/modules/scheduler/services'
import { SchedulerActivity } from '~/modules/scheduler/types'
import { useAtom } from '~/observers/jotai'

type Props = {
  visible: boolean
  onClose: () => void
}

const schema = zod.object({
  name: zod
    .string({ required_error: 'Obrigatório' })
    .nonempty({ message: 'Obrigatório' }),
})

export const ActivitiesModal: React.FC<Props> = ({ visible, onClose }) => {
  const [data] = useAtom(schedulerAtom)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SchedulerActivity>({
    resolver: zodResolver(schema),
  })

  const onFormSubmit = useCallback(
    (payload: SchedulerActivity) => {
      updateSchedulerData({
        activities: [...(data?.activities || []), payload.name],
      })
      reset()
    },

    [data?.activities, reset],
  )

  return (
    <Modal visible={visible}>
      <div>
        <div className='flex items-center justify-between mb-3'>
          <h2 className='text-xl font-medium'>Nova Atividade</h2>

          <button
            className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
            onClick={onClose}
          >
            <FiX size={24} />
          </button>
        </div>

        <form className='flex gap-3' onSubmit={handleSubmit(onFormSubmit)}>
          <TextInput {...register('name')} error={errors.name?.message} />

          <Button>Adicionar atividade</Button>
        </form>

        <div className='flex flex-col gap-3 bg-slate-50 p-2.5 mb-4 rounded-xl'>
          <h2 className='text-xl font-semibold'>
            Remover Atividades (da sua lista)
          </h2>
          <p>
            Estas são as atividades que você irá selecionar para o dia de treino
            nos quadros de cada dia da semana. Os botões abaixo servem para
            removê-las da sua lista de atividades, então tome cuidado. Você só
            poderá recuperar as atividades que vem por padrão no GPA.
          </p>
        </div>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-wrap gap-3'>
            {data?.activities.map((a) => (
              <ExerciseTag
                exercise={a}
                onDelete={() => deleteActivity(a)}
                key={`exercise-modal-${a.replace(' ', '-')}`}
              />
            ))}
          </div>
          <div className='self-end'>
            <Button type='button' onClick={recoverStandardActivities}>
              Recuperar atividades padrão do GPA
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
