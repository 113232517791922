import { useEffect, useState } from 'react'

import { TextInput } from '~/components/TextInput'
import { floatMask } from '~/components/TextInput/utils/mask'
import { aerobicCapabilitiesAtom } from '~/modules/aerobicCapabilities/data'
import { updateAerobicCapabilitiesData } from '~/modules/aerobicCapabilities/services'
import { RockportProtocolData } from '~/modules/aerobicCapabilities/types'

export const Rockport: React.FC = () => {
  const data = aerobicCapabilitiesAtom.get()
  const [value, setValue] = useState<RockportProtocolData>({
    heartRate: data?.rockport?.heartRate,
    time: data?.rockport?.time,
  })

  useEffect(() => {
    updateAerobicCapabilitiesData({ rockport: value })
  }, [value])

  return (
    <div className='flex gap-3'>
      <TextInput
        label='Tempo (min)'
        placeholder='0'
        defaultValue={value.time}
        onChange={(e) => {
          setValue((state) => ({
            ...state,
            time: +floatMask(e.target.value),
          }))
        }}
      />
      <TextInput
        label='Frequência Cardíaca Final (bpm)'
        placeholder='0'
        defaultValue={value.heartRate}
        onChange={(e) => {
          setValue((state) => ({
            ...state,
            heartRate: +e.target.value.replace(/\D/, ''),
          }))
        }}
      />
    </div>
  )
}
