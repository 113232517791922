import { useCallback, useMemo, useState } from 'react'
import { FiX } from 'react-icons/fi'

import { Button } from '~/components/Button'
import { ExerciseTag } from '~/components/ExerciseTag'
import { Modal } from '~/components/Modal'
import { SelectInput } from '~/components/SelectInput'
import { useExercisesPrescriptions } from '~/modules/exercisesPrescriptions/useExercisesPrescriptions'
import {
  clearAllExercisesFromSeriesService,
  removeExerciseFromSeries,
} from '~/modules/standardizedSeries/services'
import { usePeriodizedSeries } from '~/modules/standardizedSeries/usePeriodizedSeries'

type Props = {
  visible: boolean
  onClose: () => void
  index: number
}

type TabValues = 'exercise' | 'standardized'

export const ExercisesModal: React.FC<Props> = ({
  visible,
  onClose,
  index,
}) => {
  const { data, exercisesOptions, standardizedSeriesOptions } =
    useExercisesPrescriptions()
  const {
    data: periodizedSeries,
    addExerciseToSeries,
    addStandardizedSeriesToExerciseSeries,
  } = usePeriodizedSeries()
  const [tab, setTab] = useState<TabValues>('exercise')
  const [exerciseGroup, setExerciseGroup] = useState<string>('')
  const [standardizedSeries, setStandardizedSeries] = useState<string>('')

  const listedExercises = useMemo<string[]>(() => {
    return periodizedSeries
      ? periodizedSeries.series[index].exercises.map((e) => e.name)
      : []
  }, [periodizedSeries, index])

  const groupExercises = useMemo<string[]>(() => {
    const exercises = periodizedSeries?.series[index].exercises.map(
      (e) => e.name,
    )

    return (
      data?.exercises
        .find((e) => e.groupName === exerciseGroup)
        ?.exercises.filter(
          (e) => !exercises?.find((selected) => e === selected),
        ) ?? []
    )
  }, [data?.exercises, exerciseGroup, periodizedSeries, index])

  const standardizedSeriesExercises = useMemo<string[]>(() => {
    return (
      data?.standardizedSeries.find((s) => s.groupName === standardizedSeries)
        ?.exercises ?? []
    )
  }, [data, standardizedSeries])

  const handleClearAll = useCallback(() => {
    clearAllExercisesFromSeriesService(index)
  }, [index])

  const handleAddStandardizedExercises = useCallback(() => {
    addStandardizedSeriesToExerciseSeries(index, standardizedSeries)
    onClose()
  }, [
    onClose,
    addStandardizedSeriesToExerciseSeries,
    index,
    standardizedSeries,
  ])

  return (
    <Modal visible={visible}>
      <div className='flex flex-col gap-4'>
        <div className='flex justify-between'>
          <h2 className='text-xl font-medium'>Adicionar Exercícios</h2>
          <button
            className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
            onClick={onClose}
          >
            <FiX size={24} />
          </button>
        </div>

        <div className='flex gap-4'>
          <button
            data-active={tab === 'exercise'}
            className='data-[active=true]:font-semibold data-[active=true]:bg-gpa-blue-50 px-2 py-1 rounded-lg'
            onClick={() => setTab('exercise')}
          >
            Exercícios
          </button>
          <button
            data-active={tab === 'standardized'}
            className='data-[active=true]:font-semibold data-[active=true]:bg-gpa-blue-50 px-2 py-1 rounded-lg'
            onClick={() => setTab('standardized')}
          >
            Séries Padronizadas
          </button>
        </div>

        {tab === 'exercise' ? (
          <div className='flex flex-col gap-3'>
            <div>
              <p>
                Selecione o grupo de exercícios para adicionar suas atividades
              </p>
              <SelectInput
                options={exercisesOptions}
                value={exerciseGroup}
                onChange={(v) => setExerciseGroup(v)}
              />
            </div>
            <div className='flex flex-wrap p-2 bg-slate-100 rounded-lg max-h-[200px] overflow-y-auto gap-2'>
              {groupExercises.map((e) => (
                <ExerciseTag
                  key={`exercise-${index}-${e.replace(' ', '_')}`}
                  exercise={e}
                  onAdd={() => addExerciseToSeries(e, index)}
                />
              ))}
            </div>

            <p>
              Exercícios desta série (clique nos botões para removê-los da
              série)
            </p>
            <div className='flex flex-wrap p-2 bg-slate-100 rounded-lg max-h-[200px] overflow-y-auto gap-2'>
              {listedExercises.map((e, i) => (
                <ExerciseTag
                  key={`exercise-series-${index}-${e.replace(' ', '_')}`}
                  exercise={e}
                  onDelete={() => removeExerciseFromSeries(i, index)}
                />
              ))}
            </div>

            <div className='flex justify-between'>
              <Button
                className='bg-gpa-red enabled:active:bg-red-500'
                onClick={handleClearAll}
              >
                Limpar tudo
              </Button>
              <Button onClick={onClose}>Finalizar edição</Button>
            </div>
          </div>
        ) : (
          <div className='flex flex-col gap-3'>
            <div>
              <p>
                Selecione a série padronizada para adicionar suas atividades
              </p>
              <SelectInput
                options={standardizedSeriesOptions}
                value={standardizedSeries}
                onChange={(v) => setStandardizedSeries(v)}
              />
            </div>
            <div>
              <p className='mb-3'>
                Exercícios presentes nesta série padronizada (Ao adicionar uma
                série padronizada, todos seus exercícios serão transferidos e
                substituirão os atuais nesta série de exercícios &quot;Serie{' '}
                {periodizedSeries?.series[index].letter}&quot;
              </p>
              <div className='grid grid-cols-[repeat(auto-fit,_minmax(_180px,auto))] gap-3 min-h-[80px] bg-slate-100 p-3 rounded-lg max-h-[300px] overflow-y-auto items-center'>
                {standardizedSeriesExercises?.map((e, i) => (
                  <p
                    className='bg-blue-100 p-2 rounded-lg text-center'
                    key={`exercise-series-${i}-${e.replace(' ', '_')}`}
                  >
                    {e}
                  </p>
                ))}
              </div>
            </div>

            <div className='flex justify-between'>
              <Button
                className='bg-gpa-red enabled:active:bg-red-500'
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button onClick={handleAddStandardizedExercises}>
                Adicionar Exercícios
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}
