import { AxiosError } from 'axios'

import { AntropometryFormType } from '~/pages/authenticated/personalTrainer/studentEvaluation/components/formsContainer/components/antropometryForm/schema'
import { api } from '~/utils/api'

import { stepCounterAtom } from '../evalutation/data'
import { insertToast } from '../toasts/services'
import { antropometryAtom } from './data'
import { Antropometry } from './types'

export function updateAntropometryData(data: Antropometry) {
  antropometryAtom.set({
    ...antropometryAtom.get(),
    ...data,
  })
}

export async function patchAntropometry(
  student_id: string,
  evaluation_id: string,
  payload: AntropometryFormType,
) {
  return api
    .patch<Antropometry>(
      `personal-trainers/students/${student_id}/evaluations/${evaluation_id}/antropometry`,
      payload,
    )
    .then(({ data }) => {
      antropometryAtom.set(data)
      stepCounterAtom.get() < 2 && stepCounterAtom.set(2)
      return true
    })
    .catch((error: AxiosError<APIError>) => {
      if (error && error.response?.data?.message) {
        insertToast({
          duration: 3,
          title: 'Erro',
          type: 'error',
          message: error.response.data.message,
        })
      }
      return false
    })
}

export function clearAntropometry() {
  antropometryAtom.set(undefined)
}
