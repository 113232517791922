import { useMemo } from 'react'

import { useAreobicCapabilities } from '~/modules/aerobicCapabilities/useAerobicCapabilities'
import { anamnesisAtom } from '~/modules/anamnesis/data'
import { useCoronarianRiskFactorFormula } from '~/modules/anamnesis/useCoronarianRiskFactorFormula'
import { useStressFormula } from '~/modules/anamnesis/useStressFormula'
import { antropometryAtom } from '~/modules/antropometry/data'
import { useIMCFormula } from '~/modules/antropometry/useIMCData'
import { useCorporalEvaluation } from '~/modules/corporalEvaluation/useCorporalEvaluation'
import { useHemodynamicMeasurements } from '~/modules/hemodynamicMeasurements/useHemodynamicMeasurements'
import { useAtom } from '~/observers/jotai'

import { colors, colorsArray } from './variables'

export const FormsPreview: React.FC = () => {
  const [antropometry] = useAtom(antropometryAtom)
  const { coronarianRiskFactorResult } = useCoronarianRiskFactorFormula()
  const analiseDeStress = useStressFormula()
  const {
    pesoGordo,
    pesoGordoSugerido,
    pesoGordoExcedenteDoProposto,
    classificacaoPercentGorduraIndex,
    sugerePercentGordura,
    classificacaoPercentualGorduraEMCM,
    percentualDeGorduraMedido,
    MCMPercent,
    MCMSugerido,
    MCMCarencia,
    MCM,
    MCMPercentSugerido,
  } = useCorporalEvaluation()
  const { calcIMC, calcIMCClassificacao, calcICM_CC_Classificacao, calcIRCQ } =
    useIMCFormula()

  const {
    calcClassificacaoDaPressaoAterial,
    calcFreqCardiacaReservaNaAgua,
    calcFreqCardiacaReserva,
  } = useHemodynamicMeasurements()

  const { vo2, classification, foreseenVO2, METSMax, basal, data } =
    useAreobicCapabilities()

  const [anamnesis] = useAtom(anamnesisAtom)

  const parQResult = useMemo(() => {
    if (!anamnesis) return false
    return (
      anamnesis.bloodPressureMedication ||
      anamnesis.boneAche ||
      anamnesis.chestPain ||
      anamnesis.chestPainLastMonth ||
      anamnesis.dizziness ||
      anamnesis.reasonNotToExercise ||
      anamnesis.supervisedPhysicalActivity
    )
  }, [anamnesis])

  return (
    <>
      <h1 className='text-xl py-1 text-white text-center bg-gpa-blue-500 rounded-[4px]'>
        Par-Q
      </h1>

      {anamnesis ? (
        <div className='my-4'>
          {parQResult ? (
            <p className='p-1 text-white bg-gpa-red'>Procure um médico</p>
          ) : (
            <p className='p-1 text-black bg-gpa-green'>
              Atividade física liberada.
            </p>
          )}
        </div>
      ) : (
        <br />
      )}

      <h1 className='text-xl py-1 text-white text-center bg-gpa-blue-500 rounded-[4px]'>
        Risco Coronariano
      </h1>

      {Object.keys(coronarianRiskFactorResult).length ? (
        <div className='my-4'>
          <p className='text-sm'>{coronarianRiskFactorResult.message}</p>
          <p className='text-sm'>
            Pressão: {coronarianRiskFactorResult.arterialPressureScore}
          </p>
          <div
            className={`h-6 px-2 ${
              colors[coronarianRiskFactorResult.color as Color]
            }`}
            style={{
              width: coronarianRiskFactorResult.totalScore + '%',
            }}
          >
            {coronarianRiskFactorResult.totalScore}
          </div>
        </div>
      ) : (
        <br />
      )}

      <h1 className='text-xl py-1 text-white text-center bg-gpa-blue-500 rounded-[4px]'>
        Stress
      </h1>

      {anamnesis ? (
        <div className='my-4 flex'>
          <span
            data-color={analiseDeStress.color}
            className='p-2 w-full text-center font-bold bg-gpa-green data-[color=yellow]:bg-yellow-300 data-[color=red]:bg-gpa-red data-[color=orange]:bg-orange-400 data-[color=purple]:bg-purple-400'
          >
            {analiseDeStress.message}
          </span>
        </div>
      ) : (
        <br />
      )}

      <h1 className='text-xl py-1 text-white text-center bg-gpa-blue-500 rounded-[4px]'>
        I.M.C (O.M.S)
      </h1>

      <div className='flex my-2 gap-1'>
        <p className='text-sm'>{calcIMC()}</p>
        <p className={`text-sm ${colors[calcIMCClassificacao().color]} px-1`}>
          {calcIMCClassificacao().label}
        </p>
      </div>

      <h1 className='text-xl py-1 text-white text-center bg-gpa-blue-500 rounded-[4px]'>
        I.M.C (C.C)
      </h1>

      <p
        className={`self-start px-2 text-sm ${
          colors[calcICM_CC_Classificacao().color]
        }`}
      >
        {calcICM_CC_Classificacao().label}
      </p>

      <h1 className='text-xl py-1 text-white text-center bg-gpa-blue-500 rounded-[4px]'>
        I.R.C.Q
      </h1>

      <p className='text-sm'>
        Valor: {calcIRCQ().value?.toFixed(2)}
        <span className={`${colors[calcIRCQ().color]} px-1 mx-1`}>
          {calcIRCQ().label}
        </span>
      </p>

      <h1 className='text-xl py-1 text-white text-center bg-gpa-blue-500 rounded-[4px]'>
        Análise da Composição Corporal
      </h1>

      <div className='text-sm gap-1 flex flex-col'>
        <p>Nome do protocolo</p>
        <p
          className={`${colorsArray[classificacaoPercentGorduraIndex]} font-bold`}
        >
          % Gordura: {percentualDeGorduraMedido.toFixed(1)} (
          {classificacaoPercentualGorduraEMCM}) Proposto ={' '}
          {sugerePercentGordura.toFixed(1)}
        </p>
        <p>
          Peso Gordura (Kg): {pesoGordo} - Proposto = {pesoGordoSugerido},
          Excedente = {pesoGordoExcedenteDoProposto}
        </p>
        <p>
          % MCM : {MCMPercent.toFixed(1)} ({classificacaoPercentualGorduraEMCM})
          Proposto = {MCMPercentSugerido.toFixed(1)}
        </p>
        <p>
          MCM (Kg) - Atual {MCM.toFixed(1)} &#x2022; Carência{' '}
          {MCMCarencia.toFixed(1)} &#x2022; Proposto = {MCMSugerido.toFixed(1)}
        </p>
        <p>
          Peso &nbsp;(Kg): {antropometry?.currentWeight.toFixed(1)} &#x2022;
          Proposto = {MCMSugerido}
        </p>
      </div>

      <h1 className='text-xl py-1 text-white text-center bg-gpa-blue-500 rounded-[4px]'>
        Análises Hemodinâmicas
      </h1>

      <div>
        <p>Frequência Cardíaca de Reserva: {calcFreqCardiacaReserva()}</p>
        <p>
          Frequência Cardíaca de Reserva na Água:{' '}
          {calcFreqCardiacaReservaNaAgua()}
        </p>

        <p>
          Pressão:{' '}
          <span
            className={`px-1 ${
              colors[calcClassificacaoDaPressaoAterial().color]
            }`}
          >
            {calcClassificacaoDaPressaoAterial().label}
          </span>
        </p>
      </div>

      <h1 className='text-xl py-1 text-white text-center bg-gpa-blue-500 rounded-[4px]'>
        Capacidade Aeróbia
      </h1>

      <div className='grid grid-cols-2 gap-y-1'>
        <p className='font-bold'>VO2 Medido: {vo2.toFixed(2)}</p>
        <div className='relative flex max-w-56 border border-black items-center justify-center'>
          <span
            className={`absolute z-0 inset-0 bg-${classification.color}-500`}
            style={{ width: classification.completion + '%' }}
          />
          <span className='z-10 font-bold'>
            {classification.label} ({classification.index}/5)
          </span>
        </div>
        <p className='font-bold'>VO2 Previsto: {foreseenVO2.toFixed(2)}</p>
        <p className='font-bold'>METS Máximo: {METSMax.toFixed(1)}</p>
        <p className='font-bold'>VO2 Alvo: {data?.targetVO2.toFixed(2)}</p>
        <p className='font-bold'>Tx Met. Basal: {basal.toFixed()}</p>
      </div>
    </>
  )
}
