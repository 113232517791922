import { AntropometryFormType } from '../schema'

export function getChartValuesService(formData?: AntropometryFormType) {
  if (!formData) {
    return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  }
  return [
    formData.shoulder || 0,
    formData.chestInflated || 0,
    formData.chestRegular || 0,
    formData.hip || 0,
    formData.abdomen || 0,
    formData.waist || 0,
    formData.rightThigh || 0,
    formData.leftThigh || 0,
    formData.rightLeg || 0,
    formData.leftLeg || 0,
    formData.rightArmContracted || 0,
    formData.leftArmContracted || 0,
    formData.rightArmRelaxed || 0,
    formData.leftArmRelaxed || 0,
    formData.rightForearm || 0,
    formData.leftForearm || 0,
    formData.rightFist || 0,
    formData.leftFist || 0,
  ]
}
