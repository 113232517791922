import { api } from '~/utils/api'

import { stepCounterAtom } from '../evalutation/data'
import { aerobicCapabilitiesAtom } from './data'
import { AerobicCapabilities } from './types'

export async function updateAerobicCapabilitiesData(
  payload: Partial<AerobicCapabilities>,
) {
  if (!aerobicCapabilitiesAtom.get()) return
  aerobicCapabilitiesAtom.set({
    ...aerobicCapabilitiesAtom.get(),
    ...(payload as AerobicCapabilities),
  })
}

export async function saveAerobicCapabilities(
  studentId: string,
  evaluationId: string,
) {
  const payload = { ...aerobicCapabilitiesAtom.get() }

  delete payload.id
  delete payload.evaluationId
  delete payload.createdAt
  delete payload.updatedAt

  return api
    .patch(
      `personal-trainers/students/${studentId}/evaluations/${evaluationId}/aerobic-capabilities`,
      payload,
    )
    .then(({ data }) => {
      if (data) {
        aerobicCapabilitiesAtom.set(data)
        stepCounterAtom.get() < 5 && stepCounterAtom.set(5)
      }
      return !!data
    })
    .catch((e) => {
      console.log(e)
      return false
    })
}

export function generateAerobicCapabilitiesReportBody(): Array<
  [string, number]
> {
  const data = aerobicCapabilitiesAtom.get()
  if (!data) return []

  const {
    brunoBalk,
    cooper,
    margaria,
    rockport,
    ukk,
    medical,
    protocol,
    ownIndex,
  } = data

  switch (protocol) {
    case 'Bruno Balk - Teste de Pista':
      return [
        ['Tempo (min)', brunoBalk.time ?? 0],
        ['Distância (m)', brunoBalk.distance ?? 0],
      ]
    case 'Cooper - Teste de Pista':
      return [['Distância (m)', cooper.distance ?? 0]]
    case 'Margaria - Teste de Pista':
      return [
        ['Tempo (min)', margaria.time ?? 0],
        ['Distância (m)', margaria.distance ?? 0],
      ]
    case 'Rockport - Teste de Caminhada':
      return [
        ['Tempo (min)', rockport.time ?? 0],
        ['Frequência Cardíaca Final (bpm)', rockport.heartRate ?? 0],
      ]
    case 'UKK - Pessoas Sedentárias':
      return [
        ['Tempo (min)', ukk.time ?? 0],
        ['Frequência Cardíaca Final (bpm)', ukk.heartRate ?? 0],
      ]
    case 'Médico - VO2 e Freq. Máxima':
      return [
        ['Consumo Máximo de VO2', medical.maxVO2Consumtion ?? 0],
        ['Frequência Cardíaca Final (bpm)', medical.heartRate ?? 0],
      ]
    case 'Polar - OwnIndex':
      return [['Consumo Máximo de VO2', ownIndex.maxVO2Consumtion ?? 0]]
    case 'Questionário de Duke':
    case 'Rampa Manual Individualizada':
    default:
      return []
  }
}

export function convertTimeToFloat(time: string) {
  const [, minutes, seconds] = time.split(':')
  let result = 0
  result += +minutes
  result += +seconds ? 0.5 : 0
  return result
}

export function clearAerobicCapabilities() {
  aerobicCapabilitiesAtom.set(undefined)
}
