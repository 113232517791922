export const numberMask = (value: string) => value.replace(/\D/g, '')

export const socialDocumentNumberMask = (value: string) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')

export function floatMask(input: string) {
  // Step 1: Replace all commas with dots
  let result = input.replace(/,/g, '.')

  // Step 2: Remove any characters that are not digits or dots
  result = result.replace(/[^0-9.]/g, '')

  // Step 3: Remove dots that are not surrounded by digits
  // This will allow a trailing dot but will clean up multiple dots or misplaced dots
  result = result.replace(/(?<!\d)\.|(?<=\.)\./g, '')

  // Step 4: Ensure the dots are properly placed by using a regex pattern
  const validPattern = /^(\d+(\.\d*)?|\.\d+)$/
  if (!validPattern.test(result)) {
    result = result.replace(/(\.)(?!\d)|(?<!\d)(\.)(?=\d)|\.(?=\.)/g, '')
  }

  return result
}

export const phoneMask = (value = '') =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1')

export const documentNumberMask = (value: string) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')

export const dateMask = (value: string) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1')
