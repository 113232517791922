import { useCallback, useState } from 'react'
import { IoIosArrowDown, IoIosArrowUp, IoIosClose } from 'react-icons/io'

import { Button } from '~/components/Button'
import { SelectInput } from '~/components/SelectInput'
import { TextInput } from '~/components/TextInput'
import {
  getIntensityRange,
  moveExerciseInList,
  removeExerciseFromSeries,
  updateSeriesService,
} from '~/modules/standardizedSeries/services'
import {
  ExerciseSeries,
  ExerciseType,
} from '~/modules/standardizedSeries/types'
import { exerciseTypesOptions } from '~/modules/standardizedSeries/variables'

import { ExercisesModal } from './ExercisesModal'

type Props = {
  data: ExerciseSeries
  index: number
}

export const Series: React.FC<Props> = ({ data, index }) => {
  const [showModal, setShowModal] = useState<boolean>(false)

  const handleToggleModal = useCallback(() => {
    setShowModal((state) => !state)
  }, [])

  const handleUpdateValue = useCallback(
    (payload: Partial<ExerciseSeries>) => {
      updateSeriesService(payload, index)
    },
    [index],
  )

  return (
    <>
      <div className='flex flex-col border-2 p-4 rounded-lg w-full'>
        <h1>Série {data.letter}</h1>
        <SelectInput
          options={exerciseTypesOptions}
          label='Tipo de Trabalho'
          value={data.type}
          onChange={(v) => {
            handleUpdateValue({
              type: v,
              intensity: getIntensityRange(v as ExerciseType)[0],
            })
          }}
        />

        <TextInput
          mode='horizontal'
          label='Descrição'
          value={data.description}
          onChange={(e) => handleUpdateValue({ description: e.target.value })}
        />

        <div className='flex gap-3'>
          <div className=''>
            <TextInput
              mode='horizontal'
              label='Intensidade'
              value={data.intensity}
              onChange={(e) => {
                const value = +e.target.value.replace(/\D/, '')
                handleUpdateValue({ intensity: value })
              }}
            />
          </div>
          <p className='mt-1.5 flex-1 whitespace-nowrap'>
            {getIntensityRange(data.type).join(' ~ ')}
          </p>
        </div>

        <div className='flex flex-col gap-3'>
          <div className='bg-slate-100 p-2 rounded-md gap-3 h-[200px] overflow-y-auto'>
            <ul className='flex flex-col gap-3 bg-slate-100 rounded-md p-2'>
              {data.exercises?.map((exercise, i) => (
                <li
                  key={`exercise-${index}-${i}`}
                  className='flex bg-gpa-blue-50 p-1.5 rounded-md gap-2'
                >
                  <span className='flex-1'>{exercise.name}</span>
                  <div className='flex gap-1'>
                    {i !== 0 ? (
                      <button
                        className='p-1 rounded-full bg-gpa-blue-500 active:bg-blue-500 h-6 w-6'
                        onClick={() => moveExerciseInList('up', i, index)}
                      >
                        <IoIosArrowUp className='text-white text-md' />
                      </button>
                    ) : null}
                    {i + 1 !== data.exercises.length ? (
                      <button
                        className='p-1 rounded-full bg-gpa-blue-500 h-6 w-6 active:bg-blue-500'
                        onClick={() => moveExerciseInList('down', i, index)}
                      >
                        <IoIosArrowDown className='text-white text-md' />
                      </button>
                    ) : null}

                    <button
                      className='flex justify-center items-center rounded-full bg-gpa-red h-6 w-6 active:bg-red-600'
                      onClick={() => removeExerciseFromSeries(i, index)}
                    >
                      <IoIosClose className='text-white text-lg' />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <Button onClick={handleToggleModal}>
            Editar Exercícios da Série
          </Button>
        </div>
      </div>
      <ExercisesModal
        visible={showModal}
        onClose={handleToggleModal}
        index={index}
      />
    </>
  )
}
