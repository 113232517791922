import { useMemo } from 'react'

import { useAtom } from '~/observers/jotai'
import { computeAgeService } from '~/services/computeAgeService'

import { antropometryAtom } from '../antropometry/data'
import { evaluationAtom } from '../evalutation/data'
import { corporalEvaluationAtom } from './data'

export function useCorporalEvaluation() {
  const [evaluation] = useAtom(evaluationAtom)
  const [antropometry] = useAtom(antropometryAtom)
  const [data] = useAtom(corporalEvaluationAtom)

  const percentualDeGorduraMedido = useMemo(() => {
    if (!data?.protocol) return 0
    switch (data.protocol) {
      case 'Direto':
        return data.direct
      case 'Faulkner': {
        const ab = data.abdominal.reduce((p, c) => p + c, 0) / 3
        const tr = data.triceptal.reduce((p, c) => p + c, 0) / 3
        const si = data.suprailiac.reduce((p, c) => p + c, 0) / 3
        const se = data.suprailiac.reduce((p, c) => p + c, 0) / 3

        return (tr + se + si + ab) * 0.153 + 5.783
      }
      case 'Perímetros Coté e Wilmore Masculino': {
        if (!antropometry) return 0
        const PC = antropometry.currentWeight
        const CA = data.abdomen
        const CP = data.rightFist
        if (!PC || !CA || !CP) return 0
        const MCM = 41.955 + 1.038786 * PC - 0.82816 * (CA - CP)
        return ((PC - MCM) / PC) * 100
      }
      case 'Perímetros Penroe, Nelson e Fisher (1985) Feminino': {
        if (!antropometry) return 0
        const A = antropometry.stature
        const CQ = data.waist
        const CA = data.abdomen
        return 0.55 * CQ - 0.24 * A + 0.28 * CA - 8.43
      }
      case 'Pollock & Jackson 3 Dobras': {
        if (!evaluation?.student) return 0
        const age = computeAgeService(evaluation.student.birthday)
        let DENS = 0
        const PE = (data.chest[0] + data.chest[1] + data.chest[2]) / 3
        const TR =
          (data.triceptal[0] + data.triceptal[1] + data.triceptal[2]) / 3
        const AB =
          (data.abdominal[0] + data.abdominal[1] + data.abdominal[2]) / 3
        const CX =
          (data.medialThigh[0] + data.medialThigh[1] + data.medialThigh[2]) / 3
        const SI =
          (data.suprailiac[0] + data.suprailiac[1] + data.suprailiac[2]) / 3

        if (evaluation?.student.sex === 'masculino') {
          DENS =
            1.10938 -
            0.0008267 * (PE + AB + CX) +
            0.0000016 * (PE + AB + CX) ** 2 -
            0.0002574 * age
        } else {
          DENS =
            1.0994921 -
            0.0009929 * (TR + CX + SI) +
            0.0000023 * (TR + CX + SI) ** 2 -
            0.0001392 * age
        }

        return (4.95 / DENS - 4.5) * 100
      }
      case 'Pollock & Jackson 7 Dobras': {
        if (!evaluation?.student) return 0
        const age = computeAgeService(evaluation.student.birthday)
        let dens = 0
        const se = data.subscapular.reduce((p, c) => c + p, 0) / 3
        const am = data.axilla.reduce((p, c) => p + c, 0) / 3
        const ti = data.triceptal.reduce((p, c) => p + c, 0) / 3
        const cm = data.medialThigh.reduce((p, c) => p + c, 0) / 3
        const si = data.suprailiac.reduce((p, c) => p + c, 0) / 3
        const ab = data.abdominal.reduce((p, c) => p + c, 0) / 3
        const pe = data.chest.reduce((p, c) => p + c, 0) / 3
        const sumdobras = se + am + ti + cm + si + ab + pe
        if (evaluation.student.sex === 'masculino') {
          dens =
            1.112 -
            0.00043499 * sumdobras +
            0.00000055 * sumdobras ** 2 -
            0.00028826 * age
        } else {
          dens =
            1.097 -
            0.00046971 * sumdobras +
            0.00000056 * sumdobras ** 2 -
            0.00012828 * age
        }
        return (4.95 / dens - 4.5) * 100
      }

      case 'Wetman e col., para pessoas obesas': {
        if (!antropometry) return 0
        const P = antropometry.currentWeight
        const A = antropometry.stature
        const abdomen = data.abdomen
        if (evaluation?.student.sex === 'masculino') {
          return 0.31457 * abdomen - 0.10969 * P + 10.8336
        } else {
          return 0.11077 * abdomen - 0.17666 * A + 0.14354 * P + 51.03301
        }
      }
      default:
        return 0
    }
  }, [data, evaluation, antropometry])

  const pesoGordo = useMemo(() => {
    if (!antropometry) return 0
    return +(
      (antropometry?.currentWeight * percentualDeGorduraMedido) /
      100
    ).toFixed(1)
  }, [percentualDeGorduraMedido, antropometry])

  const sugerePercentGordura = useMemo(() => {
    if (!evaluation?.student.birthday) return 0
    const age = computeAgeService(evaluation.student.birthday)
    if (evaluation.student.sex === 'masculino') {
      if (age <= 29) {
        if (percentualDeGorduraMedido <= 14.1) return percentualDeGorduraMedido
        if (percentualDeGorduraMedido <= 17.4) return 13.0
        if (percentualDeGorduraMedido <= 29.1) return 15.0
        return 22.0
      }
      if (age <= 39) {
        if (percentualDeGorduraMedido <= 17.5) return percentualDeGorduraMedido
        if (percentualDeGorduraMedido <= 24.2) return 15.0
        if (percentualDeGorduraMedido <= 29.9) return 18.0
        return 21.0
      }
      if (age <= 49) {
        if (percentualDeGorduraMedido <= 19.6) return percentualDeGorduraMedido
        if (percentualDeGorduraMedido <= 22.5) return 17.0
        if (percentualDeGorduraMedido <= 31.5) return 20.0
        return 23.0
      }
      if (age <= 59) {
        if (percentualDeGorduraMedido <= 21.3) return percentualDeGorduraMedido
        if (percentualDeGorduraMedido <= 24.1) return 19
        if (percentualDeGorduraMedido <= 32.4) return 22
        return 25
      }
      if (percentualDeGorduraMedido <= 22.0) return percentualDeGorduraMedido
      if (percentualDeGorduraMedido <= 25.0) return 19
      if (percentualDeGorduraMedido <= 33.4) return 22
      return 25
    } else {
      if (age <= 29) {
        if (percentualDeGorduraMedido <= 20.6) return percentualDeGorduraMedido
        if (percentualDeGorduraMedido <= 23.7) return 18
        if (percentualDeGorduraMedido <= 35.4) return 21
        return 24
      }
      if (age <= 39) {
        if (percentualDeGorduraMedido <= 21.6) return percentualDeGorduraMedido
        if (percentualDeGorduraMedido <= 24.9) return 19
        if (percentualDeGorduraMedido <= 35.7) return 22
        return 25
      }
      if (age <= 49) {
        if (percentualDeGorduraMedido <= 24.9) return percentualDeGorduraMedido
        if (percentualDeGorduraMedido <= 28.1) return 22
        if (percentualDeGorduraMedido <= 37.8) return 25
        return 29
      }
      if (age <= 59) {
        if (percentualDeGorduraMedido <= 28.5) return percentualDeGorduraMedido
        if (percentualDeGorduraMedido <= 32.6) return 27
        if (percentualDeGorduraMedido <= 39.6) return 29
        return 33
      }
      if (percentualDeGorduraMedido <= 29.3) return percentualDeGorduraMedido
      if (percentualDeGorduraMedido <= 32.7) return 28
      if (percentualDeGorduraMedido <= 40.5) return 30
      return 33
    }
  }, [evaluation, percentualDeGorduraMedido])

  const MCMPercent = useMemo(() => {
    if (!percentualDeGorduraMedido) return 0
    return +(100 - percentualDeGorduraMedido).toFixed(1)
  }, [percentualDeGorduraMedido])

  const MCM = useMemo(() => {
    if (!antropometry?.currentWeight) return 0
    return +(antropometry?.currentWeight - pesoGordo).toFixed(1)
  }, [pesoGordo, antropometry])

  const MCMCarencia = useMemo(() => {
    if (!evaluation) return 0
    let result = 0
    if (evaluation.student.sex === 'masculino') {
      result = ((81 - MCMPercent) / 100) * MCM
    } else {
      result = ((77 - MCMPercent) / 100) * MCM
    }

    if (result < 0) return 0
    if ((data?.weightAdjustment || 0) > 0)
      return result + (data?.weightAdjustment || 0)
    return +result.toFixed(1)
  }, [MCM, MCMPercent, evaluation, data?.weightAdjustment])

  const MCMSugerido = useMemo(() => {
    return +(MCM + MCMCarencia).toFixed(1)
  }, [MCM, MCMCarencia])

  const pesoGordoExcedenteDoProposto = useMemo(() => {
    if (!antropometry?.currentWeight) return 0
    return +(
      ((percentualDeGorduraMedido - (data?.proposedFatPercentage || 0)) *
        antropometry?.currentWeight) /
      100
    ).toFixed(1)
  }, [antropometry, percentualDeGorduraMedido, data?.proposedFatPercentage])

  const MCMPercentSugerido = useMemo(() => {
    if (!data?.proposedFatPercentage) return 0
    return +(100 - data?.proposedFatPercentage).toFixed(1)
  }, [data?.proposedFatPercentage])

  const CalcPesoSugerido = useMemo(() => {
    if (!percentualDeGorduraMedido) return 0
    if (MCMCarencia < 0) {
      return MCM / (1 - (data?.proposedFatPercentage || 0) * 0.01)
    } else {
      return +(
        MCM / (1 - (data?.proposedFatPercentage || 0) * 0.01) +
        MCMCarencia
      ).toFixed(1)
    }
  }, [MCM, MCMCarencia, data?.proposedFatPercentage, percentualDeGorduraMedido])

  const classificacaoPercentGorduraIndex = useMemo((): number => {
    if (!evaluation) return 0
    const age = computeAgeService(evaluation.student.birthday)
    if (evaluation.student.sex === 'masculino') {
      if (age <= 29) {
        if (percentualDeGorduraMedido <= 9.4) return 1
        if (percentualDeGorduraMedido <= 14.1) return 2
        if (percentualDeGorduraMedido <= 17.4) return 3
        if (percentualDeGorduraMedido <= 22.4) return 4
        if (percentualDeGorduraMedido <= 29.1) return 5
        return 6
      }
      if (age <= 39) {
        if (percentualDeGorduraMedido <= 13.9) return 1
        if (percentualDeGorduraMedido <= 17.5) return 2
        if (percentualDeGorduraMedido <= 20.5) return 3
        if (percentualDeGorduraMedido <= 24.2) return 4
        if (percentualDeGorduraMedido <= 29.9) return 5
        return 6
      }
      if (age <= 49) {
        if (percentualDeGorduraMedido <= 16.3) return 1
        if (percentualDeGorduraMedido <= 19.6) return 2
        if (percentualDeGorduraMedido <= 22.5) return 3
        if (percentualDeGorduraMedido <= 26.1) return 4
        if (percentualDeGorduraMedido <= 31.5) return 5
        return 6
      }
      if (age <= 59) {
        if (percentualDeGorduraMedido <= 17.9) return 1
        if (percentualDeGorduraMedido <= 21.3) return 2
        if (percentualDeGorduraMedido <= 24.1) return 3
        if (percentualDeGorduraMedido <= 27.5) return 4
        if (percentualDeGorduraMedido <= 32.4) return 5
        return 6
      }
      if (percentualDeGorduraMedido <= 18.4) return 1
      if (percentualDeGorduraMedido <= 22.0) return 2
      if (percentualDeGorduraMedido <= 25.0) return 3
      if (percentualDeGorduraMedido <= 28.5) return 4
      if (percentualDeGorduraMedido <= 33.4) return 5
      return 6
    } else {
      if (age <= 29) {
        if (percentualDeGorduraMedido <= 17.1) return 1
        if (percentualDeGorduraMedido <= 20.6) return 2
        if (percentualDeGorduraMedido <= 23.7) return 3
        if (percentualDeGorduraMedido <= 27.7) return 4
        if (percentualDeGorduraMedido <= 35.4) return 5
        return 6
      }
      if (age <= 39) {
        if (percentualDeGorduraMedido <= 18.0) return 1
        if (percentualDeGorduraMedido <= 21.6) return 2
        if (percentualDeGorduraMedido <= 24.9) return 3
        if (percentualDeGorduraMedido <= 29.3) return 4
        if (percentualDeGorduraMedido <= 35.7) return 5
        return 6
      }
      if (age <= 49) {
        if (percentualDeGorduraMedido <= 21.3) return 1
        if (percentualDeGorduraMedido <= 24.9) return 2
        if (percentualDeGorduraMedido <= 28.1) return 3
        if (percentualDeGorduraMedido <= 32.1) return 4
        if (percentualDeGorduraMedido <= 37.8) return 5
        return 6
      }
      if (age <= 59) {
        if (percentualDeGorduraMedido <= 25.0) return 1
        if (percentualDeGorduraMedido <= 28.5) return 2
        if (percentualDeGorduraMedido <= 32.6) return 3
        if (percentualDeGorduraMedido <= 35.6) return 4
        if (percentualDeGorduraMedido <= 39.6) return 5
        return 6
      }
      if (percentualDeGorduraMedido <= 25.1) return 1
      if (percentualDeGorduraMedido <= 29.3) return 2
      if (percentualDeGorduraMedido <= 32.7) return 3
      if (percentualDeGorduraMedido <= 36.6) return 4
      if (percentualDeGorduraMedido <= 40.5) return 5
      return 6
    }
  }, [evaluation, percentualDeGorduraMedido])

  const classificacaoPercentualGorduraEMCM = useMemo(() => {
    switch (classificacaoPercentGorduraIndex) {
      case 1:
        return 'superior'
      case 2:
        return 'excelente'
      case 3:
        return 'bom'
      case 4:
        return 'razoável'
      case 5:
        return 'gordo'
      case 6:
        return 'gordo.'
      default:
        return 'inválido'
    }
  }, [classificacaoPercentGorduraIndex])

  const pesoGordoSugerido = useMemo((): number => {
    return +(
      (CalcPesoSugerido * (data?.proposedFatPercentage || 0)) / 100 -
      MCMCarencia
    ).toFixed(1)
  }, [CalcPesoSugerido, data?.proposedFatPercentage, MCMCarencia])

  //   procedure TfrmAvaliacaoGPS.porcgordgrid;
  const linhaNaTabelaPercentualdeGordura = useMemo(() => {
    const cells: string[][] = []
    if (!antropometry) return cells

    cells[0] = ['', 'Superior']
    cells[1] = ['', 'Excelente']
    cells[2] = ['', 'Bom']
    cells[3] = ['', 'Razoável']
    cells[4] = ['', 'Gordo']
    cells[5] = ['', 'Gordo.']

    const age = computeAgeService(evaluation?.student.birthday)

    if (evaluation?.student.sex === 'masculino') {
      if (age <= 29) {
        cells[0][0] = '< 9,4%'
        cells[1][0] = '9,4 - 14,1%'
        cells[2][0] = '14,2 - 17,4%'
        cells[3][0] = '17,5 - 22,4%'
        cells[4][0] = '22,5 - 29,1%'
        cells[5][0] = '> 29,1%'
        return cells
      }
      if (age <= 39) {
        cells[0][0] = '< 13,9'
        cells[1][0] = '13,9 - 17,5%'
        cells[2][0] = '17,6 - 20,5%'
        cells[3][0] = '20,6 - 24,2%'
        cells[4][0] = '24,3 - 29,9%'
        cells[5][0] = '> 29,9%'
        return cells
      }
      if (age <= 49) {
        cells[0][0] = '< 16,3%'
        cells[1][0] = '16,3 - 19,6%'
        cells[2][0] = '19,7 - 22,5%'
        cells[3][0] = '22,6 - 26,1%'
        cells[4][0] = '26,2 - 31,5%'
        cells[5][0] = '> 31,5%'
        return cells
      }
      if (age <= 59) {
        cells[0][0] = '< 17,9%'
        cells[1][0] = '17,9 - 21,3%'
        cells[2][0] = '21,4 - 24,1%'
        cells[3][0] = '24,2 - 27,5%'
        cells[4][0] = '27,6 - 32,4%'
        cells[5][0] = '> 32,4%'
        return cells
      }

      cells[0][0] = '< 18,4%'
      cells[1][0] = '18,4 - 22,0%'
      cells[2][0] = '22,1 - 25,0%'
      cells[3][0] = '25,1 - 28,5%'
      cells[4][0] = '28,6 - 33,4%'
      cells[5][0] = '> 33,4%'
      return cells
    } else {
      if (age <= 29) {
        cells[0][0] = '< 17,1%'
        cells[1][0] = '17,1 - 20,6%'
        cells[2][0] = '20,7 - 23,7%'
        cells[3][0] = '23,8 - 27,7%'
        cells[4][0] = '27,8 - 35,4%'
        cells[5][0] = '> 35,4%'
        return cells
      }
      if (age <= 39) {
        cells[0][0] = '< 18,0%'
        cells[1][0] = '18,0 - 21,6%'
        cells[2][0] = '21,7 - 24,9%'
        cells[3][0] = '25,0 - 29,3%'
        cells[4][0] = '29,4 - 35,7%'
        cells[5][0] = '> 35,7%'
        return cells
      }
      if (age <= 49) {
        cells[0][0] = '< 21,3%'
        cells[1][0] = '21,3 - 24,9%'
        cells[2][0] = '25,0 - 28,1%'
        cells[3][0] = '28,2 - 32,1%'
        cells[4][0] = '32,2 - 37,8%'
        cells[5][0] = '> 37,8%'
        return cells
      }
      if (age <= 59) {
        cells[0][0] = '< 25,0%'
        cells[1][0] = '25,0 - 28,5%'
        cells[2][0] = '28,6 - 32,6%'
        cells[3][0] = '32,7 - 35,6%'
        cells[4][0] = '35,7 - 39,6%'
        cells[5][0] = '> 39,6%'
        return cells
      }

      cells[0][0] = '25,1% <'
      cells[1][0] = '25,1 - 29,3%'
      cells[2][0] = '29,4 - 32,5%'
      cells[3][0] = '32,6 - 36,6%'
      cells[4][0] = '36,7 - 40,5%'
      cells[5][0] = '> 40,5%'
      return cells
    }
  }, [evaluation, antropometry])

  return {
    pesoGordo,
    sugerePercentGordura,
    MCM,
    MCMPercent,
    MCMCarencia,
    MCMSugerido,
    pesoGordoExcedenteDoProposto,
    MCMPercentSugerido,
    CalcPesoSugerido,
    classificacaoPercentGorduraIndex,
    classificacaoPercentualGorduraEMCM,
    pesoGordoSugerido,
    linhaNaTabelaPercentualdeGordura,
    percentualDeGorduraMedido,
    peso: antropometry?.currentWeight,
    data,
  }
}
