import { useCallback } from 'react'

import { MaskedInput } from '~/components/TextInput'
import { floatMask } from '~/components/TextInput/utils/mask'
import { corporalEvaluationAtom } from '~/modules/corporalEvaluation/data'
import {
  setCorporalEvaluationImageIndex,
  updateCorporalEvaluationData,
} from '~/modules/corporalEvaluation/services'

import { Mean } from './Mean'

export const ChestInput: React.FC = () => {
  const data = corporalEvaluationAtom.get()

  const handleUpdateField = useCallback(
    (index: number, value: number) => {
      const newArr = data ? data.chest : []
      newArr[index] = +value
      updateCorporalEvaluationData({ chest: newArr })
    },
    [data],
  )

  const handleFocus = useCallback(() => {
    setCorporalEvaluationImageIndex(1)
  }, [])

  return (
    <>
      <div className='whitespace-nowrap font-bold xl:font-normal'>
        Peitoral (mm) &nbsp;
      </div>
      {data?.chest.map((val, index) => (
        <div key={`chest-${index}`}>
          <p className='block xl:hidden mb-2'>Medida {index + 1}</p>
          <MaskedInput
            onFocus={handleFocus}
            value={val || ''}
            placeholder='0'
            mask='float'
            onChange={(v) => handleUpdateField(index, +floatMask(v))}
          />
        </div>
      ))}
      <div>
        <p className='block xl:hidden mb-2'>Média</p>
        <Mean field='chest' />
      </div>
    </>
  )
}
