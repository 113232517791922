import { useCallback, useContext, useMemo, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '~/components/Button'
import { Checkbox } from '~/components/Checkbox'
import { SelectInput } from '~/components/SelectInput'
import { anamnesisAtom } from '~/modules/anamnesis/data'
import {
  saveScheduler,
  updateSchedulerData,
} from '~/modules/scheduler/services'
import { useScheduler } from '~/modules/scheduler/useScheduler'
import { strengthExercisesAmountOptions } from '~/modules/scheduler/variables'
import { useAtom } from '~/observers/jotai'
import {
  aerobicExercisesRoute,
  aerobicPrescriptionRoute,
} from '~/routes/routes'
import { EvaluationRouteParams } from '~/routes/types'
import { getWeekdayIndex, weekDaysOrder } from '~/utils/weekdays'

import { formsLayoutContext } from '../../layout'
import { ActivitiesManager } from './components/ActivitiesManager'
import { DaysActivitiesList } from './components/DaysActivitiesList'

export const Scheduler: React.FC = () => {
  const navigate = useNavigate()
  const { scrollToTop } = useContext(formsLayoutContext)
  const { evaluation_id, student_id } = useParams<EvaluationRouteParams>()
  const [anamnesis] = useAtom(anamnesisAtom)
  const { data, toggleActivityInWeek } = useScheduler()
  const [loading, setLoading] = useState<boolean>(false)
  const availableDaysInWeek = useMemo(
    () =>
      anamnesis?.availableWeekDaysForPhysical?.map((day) =>
        getWeekdayIndex(day),
      ),
    [anamnesis?.availableWeekDaysForPhysical],
  )

  const handleSave = useCallback(async () => {
    if (!evaluation_id || !student_id) return
    setLoading(true)
    await saveScheduler(student_id, evaluation_id).then((success) => {
      setLoading(false)
      if (success) {
        navigate(
          aerobicExercisesRoute
            .replace(':student_id', student_id)
            .replace(':evaluation_id', evaluation_id),
          { replace: true },
        )
      }
    })
  }, [evaluation_id, student_id, navigate])

  const handleGoBack = useCallback(() => {
    if (!student_id || !evaluation_id) return
    navigate(
      aerobicPrescriptionRoute
        .replace(':student_id', student_id)
        .replace(':evaluation_id', evaluation_id),
      {
        replace: true,
      },
    )
    scrollToTop()
  }, [navigate, scrollToTop, student_id, evaluation_id])

  return (
    <div className='flex flex-col px-6 py-9 gap-6 rounded-2xl bg-white'>
      <div className='flex flex-col flex-1 border rounded-md border-slate-200 p-3'>
        <p className='font-semibold text-xl'>Exercícios Aeróbicos</p>

        <p className='mb-4'>
          Entre os dias disponíveis, selecione os de sua preferência
        </p>
        <div className='grid grid-cols-[repeat(auto-fit,minmax(150px,1fr))] gap-4'>
          {weekDaysOrder
            .filter((d) => anamnesis?.availableWeekDaysForPhysical?.includes(d))
            ?.map((c) => (
              <Checkbox
                key={`weekday-strength-${c}`}
                label={c}
                checked={
                  !!data?.activityWeek[getWeekdayIndex(c)]?.some(
                    (item) => item.name === 'Trabalho Aeróbico',
                  )
                }
                onChange={() => toggleActivityInWeek(c, 'Trabalho Aeróbico')}
              />
            ))}
        </div>
      </div>

      <div className='flex flex-col flex-1 gap-4 border rounded-md border-slate-200 p-3'>
        <div className='flex flex-col gap-x-10 gap-y-1'>
          <p className='font-semibold text-xl'>Exercícios de Força</p>
          <p className='mb-4'>
            Selecione o número de séries de exercícios (Treinos A, B, C, D e E)
            de musculação (Mantenha 0 caso não queira usar treino de força)
          </p>
          <SelectInput
            value={data?.numSeries}
            options={strengthExercisesAmountOptions}
            onChange={(v) => updateSchedulerData({ numSeries: v })}
          />
        </div>
        <div className='grid grid-cols-[repeat(auto-fit,minmax(150px,1fr))] gap-4'>
          {weekDaysOrder
            .filter((d) => anamnesis?.availableWeekDaysForPhysical?.includes(d))
            ?.map((c) => (
              <Checkbox
                key={`weekday-aerobic-${c}`}
                label={c}
                checked={
                  !!data?.activityWeek[getWeekdayIndex(c)]?.some(
                    (item) => item.name === 'Trabalho de Musculação',
                  )
                }
                onChange={() =>
                  toggleActivityInWeek(c, 'Trabalho de Musculação')
                }
              />
            ))}
        </div>
      </div>

      <ActivitiesManager />

      <div className='grid grid-cols-1 lg:grid-cols-2 flex-wrap gap-4 justify-center'>
        {data?.activityWeek.map((week, index) =>
          availableDaysInWeek?.includes(index) ? (
            <DaysActivitiesList
              key={`activity-week-${index}`}
              data={week}
              dayIndex={index}
            />
          ) : null,
        )}
      </div>

      <div className='flex justify-between w-full'>
        <Button
          type='button'
          loading={loading}
          disabled={loading}
          onClick={handleGoBack}
        >
          <FiChevronLeft size={24} />
          <span className='mb-1'>Voltar</span>
        </Button>
        <Button
          loadingMessage='Salvando...'
          loading={loading}
          disabled={loading}
          onClick={handleSave}
        >
          <span className='mb-1'>Salvar</span>
          <FiChevronRight size={24} />
        </Button>
      </div>
    </div>
  )
}
