import { AxiosError } from 'axios'

import { api } from '~/utils/api'

import { aerobicCapabilitiesAtom } from '../aerobicCapabilities/data'
import { clearAerobicCapabilities } from '../aerobicCapabilities/services'
import { aerobicExercisesAtom } from '../aerobicExercises/data'
import { clearAerobicExercises } from '../aerobicExercises/services'
import { aerobicPrescriptionAtom } from '../aerobicPrescription/data'
import { clearAerobicPrescriptions } from '../aerobicPrescription/services'
import { anamnesisAtom } from '../anamnesis/data'
import { clearAnamnesis } from '../anamnesis/services'
import { antropometryAtom } from '../antropometry/data'
import { clearAntropometry } from '../antropometry/services'
import { corporalEvaluationAtom } from '../corporalEvaluation/data'
import { cleanUpCorporalEvaluation } from '../corporalEvaluation/services'
import { exercisesPrescriptionsAtom } from '../exercisesPrescriptions/data'
import { hemodynamicMeasurementsAtom } from '../hemodynamicMeasurements/data'
import { clearHemodynamicMeasurementsData } from '../hemodynamicMeasurements/services'
import { schedulerAtom } from '../scheduler/data'
import { clearScheduler } from '../scheduler/services'
import { standardizedSeriesAtom } from '../standardizedSeries/data'
import { clearStandardizedSeries } from '../standardizedSeries/services'
import { insertToast } from '../toasts/services'
import { evaluationAtom, stepCounterAtom } from './data'
import { Evaluation } from './types'

export async function createEvaluation(
  studentId: string,
): Promise<Evaluation | void> {
  return api
    .post<Evaluation>(`/personal-trainers/students/${studentId}/evaluations`)
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      if (error.response?.data.message) {
        insertToast({
          title: 'Falha ao autenticar',
          duration: 3,
          message: error.response.data.message,
          type: 'error',
        })
      }
    })
}

export async function deleteEvaluation(
  studentId: string,
  evaluationId: string,
): Promise<boolean> {
  return api
    .delete(
      `/personal-trainers/students/${studentId}/evaluations/${evaluationId}`,
    )
    .then(() => {
      return true
    })
    .catch((error: AxiosError<APIError>) => {
      if (error.response?.data.message) {
        insertToast({
          duration: 3,
          message: error.response.data.message,
          title: 'Erro',
          type: 'error',
        })
      }
      return false
    })
}

export async function getEvaluationData(
  studentId: string,
  evaluationId: string,
) {
  return api
    .get<Evaluation>(
      `/personal-trainers/students/${studentId}/evaluations/${evaluationId}`,
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      console.log(error.response?.data.message)
      return undefined
    })
}

export async function openEvaluation(
  studentId: string,
  evaluationId: string,
  controller: AbortController,
) {
  return api
    .get<Evaluation>(
      `/personal-trainers/students/${studentId}/evaluations/${evaluationId}`,
      {
        signal: controller.signal,
      },
    )
    .then(({ data }) => {
      evaluationAtom.set(data)
      anamnesisAtom.set(data.anamnesis)
      antropometryAtom.set(data.antropometry)
      corporalEvaluationAtom.set(data.corporalEvaluation)
      hemodynamicMeasurementsAtom.set(data.hemodynamicMeasurements)
      aerobicCapabilitiesAtom.set(data.aerobicCapabilities)
      aerobicPrescriptionAtom.set(data.aerobicPrescriptions)
      schedulerAtom.set(data.scheduler)
      aerobicExercisesAtom.set(data.aerobicExercises)
      exercisesPrescriptionsAtom.set(data.exercisesPrescriptions)
      standardizedSeriesAtom.set(data.standardizedSeries)
      return data
    })
    .catch((error: AxiosError<APIError>) => {
      console.log(error.response?.data.message)
      return undefined
    })
}

export function closeEvaluation() {
  evaluationAtom.set(undefined)
  stepCounterAtom.set(0)
  clearAnamnesis()
  clearAntropometry()
  cleanUpCorporalEvaluation()
  clearHemodynamicMeasurementsData()
  clearAerobicCapabilities()
  clearAerobicPrescriptions()
  clearScheduler()
  clearAerobicExercises()
  clearAerobicPrescriptions()
  clearStandardizedSeries()
}
