import { useCallback, useMemo } from 'react'

import { useAtom } from '~/observers/jotai'

import { useAreobicCapabilities } from '../aerobicCapabilities/useAerobicCapabilities'
import { exercisesPrescriptionsAtom } from '../exercisesPrescriptions/data'
import { standardizedSeriesAtom } from './data'
import { suggestRepetitionNumber } from './services'

export function usePeriodizedSeries() {
  const { score } = useAreobicCapabilities()
  const [data] = useAtom(standardizedSeriesAtom)

  const numberOfSeries = useMemo(() => {
    if (score === 0 || score === 1) return 3
    if (score === 2 || score === 3) return 2
    return 1
  }, [score])

  const addExerciseToSeries = useCallback(
    (exercise: string, seriesIndex: number) => {
      const standardizedSeries = standardizedSeriesAtom.get()
      if (!standardizedSeries) return

      const { series } = standardizedSeries

      if (!series[seriesIndex]) return false
      if (series[seriesIndex].exercises.some((e) => e.name === exercise)) {
        return false
      }

      series[seriesIndex].exercises.push({
        adjustments: '',
        initialCharge: 0,
        interval: '0',
        maximumCharge: 0,
        name: exercise,
        numSeries: numberOfSeries,
        repetitions: suggestRepetitionNumber(
          exercise,
          series[seriesIndex].intensity,
        ),
        speed: '0',
      })

      standardizedSeriesAtom.set({ ...standardizedSeries, series })
    },
    [numberOfSeries],
  )

  const addStandardizedSeriesToExerciseSeries = useCallback(
    (seriesIndex: number, standardizedGroup: string) => {
      const standardizedSeries = standardizedSeriesAtom.get()
      if (!standardizedSeries) return

      const exercisesPrescriptions = exercisesPrescriptionsAtom.get()
      if (!exercisesPrescriptions) return

      const { series } = standardizedSeries

      const exercises = exercisesPrescriptions.standardizedSeries.find(
        (s) => s.groupName === standardizedGroup,
      )?.exercises

      exercises?.forEach((e) => {
        series[seriesIndex].exercises.push({
          adjustments: '',
          initialCharge: 0,
          interval: '0',
          maximumCharge: 0,
          name: e,
          numSeries: numberOfSeries,
          repetitions: suggestRepetitionNumber(
            e,
            series[seriesIndex].intensity,
          ),
          speed: '0',
        })
      })
    },
    [numberOfSeries],
  )

  return { data, addExerciseToSeries, addStandardizedSeriesToExerciseSeries }
}
