import { useMemo, useState } from 'react'

import { ExerciseTag } from '~/components/ExerciseTag'
import { SelectInput } from '~/components/SelectInput'
import {
  addExerciseToStandardizedSeries,
  removeExerciseFromStandardizedSeries,
} from '~/modules/exercisesPrescriptions/services'
import { useExercisesPrescriptions } from '~/modules/exercisesPrescriptions/useExercisesPrescriptions'

import { CreateExerciseForm } from './CreateExerciseForm'

type Props = {
  group: string
}

export const EditGroupForm: React.FC<Props> = ({ group }) => {
  const { data, exercisesOptions } = useExercisesPrescriptions()
  const [selectedExerciseGroup, setSelectedExerciseGroup] = useState<string>('')

  const exercisesToSelect = useMemo(() => {
    const exercisesFromGroup =
      data?.exercises.find((e) => e.groupName === selectedExerciseGroup)
        ?.exercises ?? []

    const exercisesFromSeries =
      data?.standardizedSeries.find((e) => e.groupName === group)?.exercises ??
      []

    return exercisesFromGroup.filter(
      (e) => !exercisesFromSeries.find((s) => s === e),
    )
  }, [selectedExerciseGroup, data, group])

  const exercisesInSeries = useMemo(
    () =>
      data?.standardizedSeries.find((e) => e.groupName === group)?.exercises ??
      [],
    [data, group],
  )

  return (
    <div className='flex flex-col'>
      <p>Selecione um grupo para adicionar atividades</p>
      <div className='flex flex-col gap-3'>
        <SelectInput
          options={exercisesOptions}
          value={selectedExerciseGroup}
          onChange={(v) => setSelectedExerciseGroup(v)}
        />
        <div className='flex flex-wrap p-2 bg-slate-100 rounded-lg max-h-[200px] overflow-y-auto gap-2'>
          {exercisesToSelect.map((e) => (
            <ExerciseTag
              key={`exercise-${e.replace(' ', '_')}`}
              onAdd={() => addExerciseToStandardizedSeries(e, group)}
              exercise={e}
            />
          ))}
        </div>

        <div>
          <p>Adicione um novo exercício para a série (opcional)</p>
          <CreateExerciseForm group={group} />
        </div>

        <p>Exercícios da série</p>
        <div className='flex flex-wrap p-2 bg-slate-100 rounded-lg max-h-[200px] overflow-y-auto gap-2'>
          {exercisesInSeries.map((e) => (
            <ExerciseTag
              key={`exercise-${e.replace(' ', '_')}`}
              onDelete={() => removeExerciseFromStandardizedSeries(e, group)}
              exercise={e}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
