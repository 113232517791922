import { AxiosError } from 'axios'

import { CoronarianRiskFactorFormType } from '~/pages/authenticated/personalTrainer/studentEvaluation/components/formsContainer/components/anamnesisForm/components/coronarianRiskFactorForm/schema'
import { GeneralFormType } from '~/pages/authenticated/personalTrainer/studentEvaluation/components/formsContainer/components/anamnesisForm/components/generalForm/schema'
import { ParQFormType } from '~/pages/authenticated/personalTrainer/studentEvaluation/components/formsContainer/components/anamnesisForm/components/parqForm/schemas'
import { StressFormType } from '~/pages/authenticated/personalTrainer/studentEvaluation/components/formsContainer/components/anamnesisForm/components/stressForm/schema'
import { api } from '~/utils/api'

import { insertToast } from '../toasts/services'
import { anamnesisAtom } from './data'
import { Anamnesis } from './types'

export function updateAnamnesisData(data: Anamnesis) {
  anamnesisAtom.set({
    ...anamnesisAtom.get(),
    ...data,
  })
}

export async function patchAnamnesis(
  studentId: string,
  evaluationId: string,
  endpoint: string,
  data:
    | GeneralFormType
    | ParQFormType
    | CoronarianRiskFactorFormType
    | StressFormType,
) {
  return api
    .patch<Anamnesis>(
      `/personal-trainers/students/${studentId}/evaluations/${evaluationId}/anamneses/${endpoint}`,
      data,
    )
    .then(({ data }) => {
      anamnesisAtom.set(data)
      return true
    })
    .catch((error: AxiosError<APIError>) => {
      if (error.response?.data.message) {
        insertToast({
          title: 'Erro!',
          type: 'error',
          duration: 3,
          message: error.response.data.message,
        })
      }
      return false
    })
}

export function clearAnamnesis() {
  anamnesisAtom.set(undefined)
}
