import { useQuery } from '~/hooks/useQuery'

import { AnamnesisForms } from '../types'
import { CoronarianRiskFactorForm } from './coronarianRiskFactorForm'
import { GeneralForm } from './generalForm'
import { ParQForm } from './parqForm'
import { StressForm } from './stressForm'

export const Forms: React.FC = () => {
  const query = useQuery()
  const subform = query.get('subform') as AnamnesisForms | undefined

  switch (subform) {
    case 'fatorDeRiscoCoronariano':
      return <CoronarianRiskFactorForm />
    case 'parQ':
      return <ParQForm />
    case 'stress':
      return <StressForm />
    case 'geral':
    default:
      return <GeneralForm />
  }
}
