import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  anamnesisAtom,
  anamnesisStepCounterAtom,
} from '~/modules/anamnesis/data'

import { coronarianRiskFactorSchema } from './components/coronarianRiskFactorForm/schema'
import { Forms } from './components/Forms'
import { generalFormSchema } from './components/generalForm/schema'
import { Navigator } from './components/Navigator'
import { parqFormSchema } from './components/parqForm/schemas'

export const AnamnesisForm: React.FC = () => {
  const navigate = useNavigate()

  const mountedRef = useRef<boolean>(false)
  useEffect(() => {
    // if (mountedRef.current) return

    if (coronarianRiskFactorSchema.safeParse(anamnesisAtom.get()).success) {
      // navigate('?subform=stress', { replace: true })
      anamnesisStepCounterAtom.set(3)
    } else if (parqFormSchema.safeParse(anamnesisAtom.get()).success) {
      // navigate('?subform=fatorDeRiscoCoronariano', { replace: true })
      anamnesisStepCounterAtom.set(2)
    } else if (generalFormSchema.safeParse(anamnesisAtom.get()).success) {
      // navigate('?subform=parQ', { replace: true })
      anamnesisStepCounterAtom.set(1)
    } else {
      // navigate('?subform=geral', { replace: true })
      anamnesisStepCounterAtom.set(0)
    }
    navigate('?subform=geral', { replace: true })
    mountedRef.current = true

    return () => {
      anamnesisStepCounterAtom.set(0)
    }
  }, [navigate])

  return (
    <div className='flex flex-col px-6 py-9 gap-6 rounded-2xl bg-white'>
      <Navigator />
      <Forms />
    </div>
  )
}
