import { AxiosError } from 'axios'

import { api } from '~/utils/api'

import { stepCounterAtom } from '../evalutation/data'
import { corporalEvaluationAtom, corporalEvaluationImageAtom } from './data'
import { CorporalEvaluation } from './types'

export function updateCorporalEvaluationData(
  payload: Partial<CorporalEvaluation>,
) {
  if (!corporalEvaluationAtom.get()) return
  corporalEvaluationAtom.set({
    ...corporalEvaluationAtom.get(),
    ...(payload as CorporalEvaluation),
  })
}

export async function saveCorporalEvaluation(
  studentId: string,
  evaluationId: string,
) {
  const payload = { ...corporalEvaluationAtom.get() }

  delete payload.id
  delete payload.evaluationId
  delete payload.createdAt
  delete payload.updatedAt

  return api
    .patch(
      `personal-trainers/students/${studentId}/evaluations/${evaluationId}/corporal-evaluation`,
      payload,
    )
    .then(({ data }) => {
      if (data) {
        corporalEvaluationAtom.set(data)
        stepCounterAtom.get() < 3 && stepCounterAtom.set(3)
      }

      return !!data
    })
    .catch((e: AxiosError<APIError>) => {
      console.log(e)
      return false
    })
}

export function generateReportBody(): Array<[string, number]> {
  const data = corporalEvaluationAtom.get()
  if (!data) return []

  const {
    protocol,
    direct,
    subscapular,
    triceptal,
    suprailiac,
    abdominal,
    abdomen,
    rightFist,
    waist,
    chest,
    medialThigh,
    axilla,
  } = data

  switch (protocol) {
    case 'Direto':
      return [['Percentual de Gordura', direct]]
    case 'Faulkner':
      return [
        ['Subscapular (mm)', subscapular.reduce((p, c) => p + c, 0) / 3],
        ['Tríceps (mm)', triceptal.reduce((p, c) => p + c, 0) / 3],
        ['Supra-ilíaca (mm)', suprailiac.reduce((p, c) => p + c, 0) / 3],
        ['Abdominal (mm)', abdominal.reduce((p, c) => p + c, 0) / 3],
      ]
    case 'Perímetros Coté e Wilmore Masculino':
      return [
        ['Abdômen (cm)', abdomen],
        ['Punho Direito (cm)', rightFist],
      ]
    case 'Perímetros Penroe, Nelson e Fisher (1985) Feminino':
      return [
        ['Abdômen (cm)', abdomen],
        ['Quadril (cm)', waist],
      ]
    case 'Pollock & Jackson 3 Dobras':
      return [
        ['Peitoral (mm)', chest.reduce((p, c) => p + c, 0) / 3],
        ['Abdominal (mm)', abdominal.reduce((p, c) => p + c, 0) / 3],
        ['Coxa Medial (mm)', medialThigh.reduce((p, c) => p + c, 0) / 3],
      ]
    case 'Pollock & Jackson 7 Dobras':
      return [
        ['Subscapular (mm)', subscapular.reduce((p, c) => p + c, 0) / 3],
        ['Tríceps (mm)', triceptal.reduce((p, c) => p + c, 0) / 3],
        ['Peitoral (mm)', chest.reduce((p, c) => p + c, 0) / 3],
        ['Axila Média (mm)', axilla.reduce((p, c) => p + c, 0) / 3],
        ['Supra-ilíaca (mm)', suprailiac.reduce((p, c) => p + c, 0) / 3],
        ['Abdominal (mm)', abdominal.reduce((p, c) => p + c, 0) / 3],
        ['Coxa Medial (mm)', medialThigh.reduce((p, c) => p + c, 0) / 3],
      ]
    case 'Wetman e col., para pessoas obesas':
    default:
      return [['Abdômen (cm)', abdomen]]
  }
}

export function cleanUpCorporalEvaluation() {
  corporalEvaluationAtom.set(undefined)
  corporalEvaluationImageAtom.set(0)
}

export function setCorporalEvaluationImageIndex(index: number) {
  corporalEvaluationImageAtom.set(index)
}
