import { useNavigate } from 'react-router-dom'

import { SubnavItem } from '~/components/Subnav'
import { useQuery } from '~/hooks/useQuery'
import { anamnesisStepCounterAtom } from '~/modules/anamnesis/data'
import { useAtom } from '~/observers/jotai'

import { FormList } from '../../types'

export const formList: FormList[] = [
  { label: 'Geral', key: 'geral' },
  { label: 'Par-Q', key: 'parQ' },
  { label: 'Fator de Risco Coronariano', key: 'fatorDeRiscoCoronariano' },
  { label: 'Stress', key: 'stress' },
]

export const Navigator: React.FC = () => {
  const query = useQuery()
  const menu = query.get('subform')
  const navigate = useNavigate()
  const [step] = useAtom(anamnesisStepCounterAtom)

  return (
    <nav className='flex overflow-x-auto gap-2 p-2'>
      {formList.map((item, index) => (
        <SubnavItem
          key={item.key}
          title={item.label}
          active={menu === item.key}
          disabled={step < index}
          onClick={() => {
            navigate('?subform=' + item.key, {
              replace: true,
            })
          }}
        />
      ))}
    </nav>
  )
}
