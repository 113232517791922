import { useCallback, useEffect, useState } from 'react'

import { MaskedInput } from '~/components/TextInput'
import { antropometryAtom } from '~/modules/antropometry/data'
import { corporalEvaluationAtom } from '~/modules/corporalEvaluation/data'
import {
  setCorporalEvaluationImageIndex,
  updateCorporalEvaluationData,
} from '~/modules/corporalEvaluation/services'

export const WetmanCol: React.FC = () => {
  const data = corporalEvaluationAtom.get()
  const [loaded, setLoaded] = useState<boolean>(false)

  const updateData = useCallback((v: string) => {
    updateCorporalEvaluationData({ abdomen: +v })
  }, [])

  useEffect(() => {
    updateCorporalEvaluationData({ abdomen: antropometryAtom.get()?.abdomen })
    setLoaded(true)
  }, [])

  const handleFocus = useCallback(() => {
    setCorporalEvaluationImageIndex(8)
  }, [])

  return (
    <div>
      {loaded ? (
        <MaskedInput
          mode='horizontal'
          label='Abdômen (cm)'
          placeholder='0,0'
          value={data?.abdomen}
          onFocus={handleFocus}
          onChange={updateData}
        />
      ) : null}
    </div>
  )
}
