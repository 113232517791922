import { PropsWithChildren } from 'react'

import { Loading } from './components/Loading'
import { IButtonProps } from './IButtonProps'

export const Button: React.FC<PropsWithChildren<IButtonProps>> = ({
  mode = 'filled',
  color = 'standard',
  loading,
  loadingMessage,
  className,
  children,
  ...rest
}) => {
  return (
    <button
      data-mode={mode}
      data-color={color}
      className={`flex shrink-0 h-10 whitespace-nowrap justify-center items-center px-2 gpa-xs:px-4 py-1 rounded-lg cursor-pointer disabled:cursor-not-allowed select-none font-semibold text-sm gpa-xs:text-base disabled:opacity-60 border-gpa-blue-500 border-2 bg-white text-gpa-blue-500 enabled:active:bg-slate-50 data-[mode=filled]:bg-gpa-blue-500 data-[mode=filled]:text-white data-[mode=filled]:enabled:active:bg-[#1b5b9a] data-[mode=filled]:data-[color=error]:bg-gpa-red data-[mode=filled]:data-[color=error]:hover:bg-red-400 data-[mode=filled]:data-[color=error]:enabled:active:bg-red-500 data-[color=error]:border-gpa-red data-[mode=outlined]:data-[color=error]:text-gpa-red ${className}`}
      {...rest}
    >
      {loading ? <Loading>{loadingMessage}</Loading> : children}
    </button>
  )
}
