import { useEffect, useState } from 'react'

import { TextInput } from '~/components/TextInput'
import { aerobicCapabilitiesAtom } from '~/modules/aerobicCapabilities/data'
import { updateAerobicCapabilitiesData } from '~/modules/aerobicCapabilities/services'
import { BrunoBalkProtocolData } from '~/modules/aerobicCapabilities/types'

export const BrunoBalk: React.FC = () => {
  const data = aerobicCapabilitiesAtom.get()
  const [value, setValue] = useState<BrunoBalkProtocolData>({
    distance: data?.brunoBalk?.distance,
    time: data?.brunoBalk?.time,
  })

  useEffect(() => {
    updateAerobicCapabilitiesData({ brunoBalk: value })
  }, [value])

  return (
    <div className='flex gap-3'>
      <TextInput
        defaultValue={value.time}
        label='Tempo (min)'
        placeholder='0'
        onChange={(e) =>
          setValue((state) => ({
            ...state,
            time: +e.target.value.replace(/[\D]/, ''),
          }))
        }
      />
      <TextInput
        defaultValue={value.distance}
        label='Distância (m)'
        placeholder='0'
        onChange={(e) =>
          setValue((state) => ({
            ...state,
            distance: +e.target.value.replace(/[\D]/, ''),
          }))
        }
      />
    </div>
  )
}
