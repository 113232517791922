import { useCallback, useState } from 'react'
import { FiMinus, FiPlus } from 'react-icons/fi'

import { Button } from '../Button'
import { Modal } from '../Modal'

type Props = {
  exercise: string
  onDelete?: () => void
  onAdd?: () => void
}

export const ExerciseTag: React.FC<Props> = ({ exercise, onAdd, onDelete }) => {
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
    useState<boolean>(false)

  const handleConfirmDeletion = useCallback(() => {
    setShowConfirmDeleteModal(false)
    setTimeout(() => {
      onDelete && onDelete()
    }, 250)
  }, [onDelete])

  const handleClick = useCallback(() => {
    if (onAdd) {
      onAdd()
      return
    }

    if (onDelete) {
      setShowConfirmDeleteModal(true)
      return
    }
  }, [onAdd, onDelete])

  return (
    <>
      <button
        data-add={!!onAdd}
        className='flex gap-2 p-2 bg-red-50 rounded-md self-start hover:bg-red-100 active:bg-red-200 data-[add=true]:hover:bg-blue-200 data-[add=true]:active:bg-blue-300 data-[add=true]:bg-gpa-blue-50'
        onClick={handleClick}
      >
        {exercise}
        <span
          data-add={!!onAdd}
          className='p-1 rounded-full bg-red-400 data-[add=true]:bg-blue-400'
        >
          {onAdd ? (
            <FiPlus className='text-white' />
          ) : (
            <FiMinus className='text-white' />
          )}
        </span>
      </button>
      <Modal visible={showConfirmDeleteModal}>
        <div className='flex flex-col gap-3'>
          <h2 className='text-xl font-semibold'>Confirmar</h2>
          <p>Realmente deseja apagar?</p>
          <div className='flex gap-3 self-end'>
            <Button type='button' color='error' onClick={handleConfirmDeletion}>
              Sim
            </Button>
            <Button
              type='button'
              mode='outlined'
              color='error'
              onClick={() => setShowConfirmDeleteModal(false)}
            >
              Não
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
