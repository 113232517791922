import { Option } from '~/types/types'

import { ExerciseType, Level } from './types'

export const exerciseTypesOptions: Option<ExerciseType>[] = [
  {
    label: 'Endurance',
    value: 'Endurance',
  },
  {
    label: 'Força Dinâmica - Hipertrofia',
    value: 'Força Dinâmica - Hipertrofia',
  },
  {
    label: 'Força Explosiva',
    value: 'Força Explosiva',
  },
  {
    label: 'Força Pura',
    value: 'Força Pura',
  },
  {
    label: 'R.M.L. - Resistência Muscular Localizada',
    value: 'R.M.L. - Resistência Muscular Localizada',
  },
]

export const levelEnum = ['iniciante', 'intermediário', 'avançado'] as const

export const levelOptions: Option<Level>[] = levelEnum.map((l) => ({
  label: l,
  value: l,
}))
