import { isBefore } from 'date-fns'
import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import {
  //  useNavigate,
  useParams,
} from 'react-router-dom'

import { Modal } from '~/components/Modal'
import { stepCounterAtom } from '~/modules/evalutation/data'
import { openEvaluation } from '~/modules/evalutation/services'
import { stressFormSchema } from '~/pages/authenticated/personalTrainer/studentEvaluation/components/formsContainer/components/anamnesisForm/components/stressForm/schema'
// import {
//   aerobicCapabilitiesRoute,
//   aerobicExercisesRoute,
//   aerobicPrescriptionRoute,
//   antropometryRoute,
//   corporalEvaluationRoute,
//   exercisesPrescriptionsRoute,
//   hemodynamicMeasuresRoute,
//   schedulerRoute,
//   standardizedSeriesRoute,
// } from '~/routes/routes'
import { EvaluationRouteParams } from '~/routes/types'

let controller = new AbortController()

export const EvaluationContext: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(true)
  const { student_id, evaluation_id } = useParams<EvaluationRouteParams>()
  const hasEvaluation = useRef<boolean>(false)
  // const navigate = useNavigate()

  useEffect(() => {
    if (hasEvaluation.current || !evaluation_id || !student_id) return
    controller = new AbortController()
    openEvaluation(student_id, evaluation_id, controller).then((evaluation) => {
      if (evaluation) {
        setLoading(false)
        hasEvaluation.current = true
      } else {
        return
      }

      if (
        isBefore(
          new Date(evaluation.standardizedSeries.createdAt || ''),
          new Date(evaluation.standardizedSeries.updatedAt || ''),
        )
      ) {
        stepCounterAtom.set(9)
        // navigate(
        //   standardizedSeriesRoute
        //     .replace(':student_id', student_id!)
        //     .replace(':evaluation_id', evaluation_id),
        // )
      } else if (
        isBefore(
          new Date(evaluation.aerobicExercises.createdAt || ''),
          new Date(evaluation.aerobicExercises.updatedAt || ''),
        )
      ) {
        stepCounterAtom.set(8)
        // navigate(
        //   exercisesPrescriptionsRoute
        //     .replace(':student_id', student_id!)
        //     .replace(':evaluation_id', evaluation_id),
        // )
      } else if (
        isBefore(
          new Date(evaluation.scheduler.createdAt || ''),
          new Date(evaluation.scheduler.updatedAt || ''),
        )
      ) {
        stepCounterAtom.set(7)
        // navigate(
        //   aerobicExercisesRoute
        //     .replace(':student_id', student_id!)
        //     .replace(':evaluation_id', evaluation_id),
        // )
      } else if (
        isBefore(
          new Date(evaluation.aerobicPrescriptions.createdAt || ''),
          new Date(evaluation.aerobicPrescriptions.updatedAt || ''),
        )
      ) {
        stepCounterAtom.set(6)
        // navigate(
        //   schedulerRoute
        //     .replace(':student_id', student_id!)
        //     .replace(':evaluation_id', evaluation_id),
        // )
      } else if (
        isBefore(
          new Date(evaluation.aerobicCapabilities.createdAt || ''),
          new Date(evaluation.aerobicCapabilities.updatedAt || ''),
        )
      ) {
        stepCounterAtom.set(5)
        // navigate(
        //   aerobicPrescriptionRoute
        //     .replace(':student_id', student_id!)
        //     .replace(':evaluation_id', evaluation_id),
        // )
      } else if (
        isBefore(
          new Date(evaluation.hemodynamicMeasurements.createdAt || ''),
          new Date(evaluation.hemodynamicMeasurements.updatedAt || ''),
        )
      ) {
        stepCounterAtom.set(4)
        // navigate(
        //   aerobicCapabilitiesRoute
        //     .replace(':student_id', student_id!)
        //     .replace(':evaluation_id', evaluation_id),
        // )
      } else if (
        isBefore(
          new Date(evaluation.corporalEvaluation.createdAt || ''),
          new Date(evaluation.corporalEvaluation.updatedAt || ''),
        )
      ) {
        stepCounterAtom.set(3)
        // navigate(
        //   hemodynamicMeasuresRoute
        //     .replace(':student_id', student_id!)
        //     .replace(':evaluation_id', evaluation_id!),
        // )
      } else if (
        isBefore(
          new Date(evaluation?.antropometry.createdAt || ''),
          new Date(evaluation?.antropometry.updatedAt || ''),
        )
      ) {
        stepCounterAtom.set(2)
        // navigate(
        //   corporalEvaluationRoute
        //     .replace(':student_id', student_id!)
        //     .replace(':evaluation_id', evaluation_id!),
        // )
      } else if (stressFormSchema.safeParse(evaluation?.anamnesis).success) {
        stepCounterAtom.set(1)
        // navigate(
        //   antropometryRoute
        //     .replace(':student_id', student_id!)
        //     .replace(':evaluation_id', evaluation_id!),
        // )
      } else {
        stepCounterAtom.set(0)
      }
    })

    return () => {
      controller.abort()
    }
  }, [evaluation_id, student_id])

  return (
    <>
      {loading ? null : children}
      <Modal visible={loading}>
        <div className='flex flex-col flex-1 gap-4'>
          <p className='text-center'>Carregando dados da avaliação...</p>
          <div className='h-8 w-8 border-4 border-x-slate-200 border-y-slate-200 border-t-slate-400 rounded-full mx-auto animate-spin' />
        </div>
      </Modal>
    </>
  )
}
