import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { PersonalTrainerRegistrationForm } from '~/forms/PersonalTrainerRegistrationForm'
import { useQuery } from '~/hooks/useQuery'
import { validateInvitationLink } from '~/modules/invitationLinks/services'

import logo from '../../../assets/img/gpaLogo.png'

export function Invite() {
  const query = useQuery()
  const [loading, setLoading] = useState<boolean>(true)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [invite] = useState<string | null>(() => query.get('convite'))
  const navigate = useNavigate()

  const handleSubmit = useCallback(() => {
    navigate('/')
  }, [navigate])

  useEffect(() => {
    const abortController = new AbortController()

    if (invite) {
      validateInvitationLink(invite, abortController).then((response) => {
        if (response) {
          setIsValid(response.valid)
        } else {
          console.log('get out')
          setTimeout(() => {
            navigate('/')
          }, 250)
        }
        setLoading(false)
      })
    }

    return () => {
      abortController.abort()
    }
  }, [invite, navigate])

  return (
    <div className='bg-red-400 flex flex-col min-h-screen'>
      {invite ? (
        <div className='flex flex-1 items-center justify-center bg-gpa-blue-50'>
          {loading ? (
            <h2 className='text-2xl p-8 rounded-2xl bg-white shadow-md'>
              Validando Convite...
            </h2>
          ) : isValid ? (
            <div className='flex flex-col gap-8'>
              <img
                src={logo}
                alt='GPA'
                className='max-w-[170px] w-full mx-auto'
              />
              <div className='p-8 rounded-2xl bg-white shadow-md'>
                <PersonalTrainerRegistrationForm
                  onSubmit={handleSubmit}
                  link={invite}
                />
              </div>
            </div>
          ) : (
            <h2 className='text-2xl p-8 rounded-2xl bg-white shadow-md'>
              Convite Inválido...
            </h2>
          )}
        </div>
      ) : (
        <div className='flex flex-1 items-center justify-center bg-gpa-blue-50'>
          <h2 className='text-2xl p-8 rounded-2xl bg-white shadow-md'>
            Convite Inválido
          </h2>
        </div>
      )}
    </div>
  )
}
