import { atom } from '~/observers/jotai'

import { AerobicExercises } from './types'

export const defaultAerobicExercises = [
  'Correr',
  'Andar',
  'Pedalar',
  'Nadar',
  'Hidroginástica',
  'Remar',
  'Ginástica',
]
export const aerobicExercisesAtom = atom<AerobicExercises | undefined>(
  undefined,
)
