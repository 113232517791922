import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import zod from 'zod'

import { Button } from '~/components/Button'
import { TextInput } from '~/components/TextInput'
import { computeValuesViaStrengthTest } from '~/modules/standardizedSeries/services'

const schema = zod.object({
  weight: zod.number({ invalid_type_error: 'Campo obrigatório' }),
  numRepetitions: zod
    .number({ invalid_type_error: 'Campo obrigatório' })
    .min(1, 'Valod deve ser maior ou igual a 1')
    .max(10, 'Valor deve ser menor ou igual a 10'),
})

type FormType = zod.infer<typeof schema>

type Props = {
  seriesIndex: number
  exerciseIndex: number
}

export const StrengthTestForm: React.FC<Props> = ({
  seriesIndex,
  exerciseIndex,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormType>({
    resolver: zodResolver(schema),
  })

  const onFormSubmit = useCallback(
    (data: FormType) => {
      computeValuesViaStrengthTest(
        data.weight,
        data.numRepetitions,
        seriesIndex,
        exerciseIndex,
      )
    },
    [seriesIndex, exerciseIndex],
  )

  return (
    <div className='flex flex-col gap-3 p-3 bg-blue-50 rounded-md'>
      <h2 className='font-semibold text-lg'>
        Definir Carga Máx. Utilizando teste de força
      </h2>
      <p>
        Com estas informações o GPA poderá sugerir um valor de carga máxima para
        este exercício
      </p>
      <form
        className='flex flex-col gap-3'
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <TextInput
          containerClassName='bg-white'
          label='Carga Utilizada'
          {...register('weight', { valueAsNumber: true })}
          error={errors.weight?.message}
        />
        <TextInput
          containerClassName='bg-white'
          label='Número de repetições feitas (Máximo de 10 repetições)'
          {...register('numRepetitions', { valueAsNumber: true })}
          error={errors.numRepetitions?.message}
        />

        <Button className='self-end'>Utilizar</Button>
      </form>
    </div>
  )
}
