import { useCallback, useContext, useState } from 'react'
import { FiChevronLeft, FiChevronRight, FiMinus } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '~/components/Button'
import { ExerciseTag } from '~/components/ExerciseTag'
import {
  removeExerciseFromOrder,
  saveAerobicExercises,
  selectExercise,
} from '~/modules/aerobicExercises/services'
import { useAerobicExercises } from '~/modules/aerobicExercises/useAerobicExercises'
import { exercisesPrescriptionsRoute, schedulerRoute } from '~/routes/routes'
import { EvaluationRouteParams } from '~/routes/types'

import { formsLayoutContext } from '../../layout'
import { NewActivityModal } from './NewActivityModal'
import { RemoveActivityModal } from './RemoveActivityModal'

export const AerobicExercises: React.FC = () => {
  const navigate = useNavigate()
  const { scrollToTop } = useContext(formsLayoutContext)
  const { evaluation_id, student_id } = useParams<EvaluationRouteParams>()

  const { data } = useAerobicExercises()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const handleToggleModal = useCallback(() => {
    setShowModal((state) => !state)
  }, [])

  const handleToggleRemoveModal = useCallback(() => {
    setShowRemoveModal((state) => !state)
  }, [])

  const handleSave = useCallback(() => {
    if (!student_id || !evaluation_id) return

    setLoading(true)
    saveAerobicExercises(student_id, evaluation_id).then((success) => {
      setLoading(false)

      success &&
        navigate(
          exercisesPrescriptionsRoute
            .replace(':student_id', student_id)
            .replace(':evaluation_id', evaluation_id),
          { replace: true },
        )
    })
  }, [student_id, evaluation_id, navigate])

  const handleGoBack = useCallback(() => {
    if (!student_id || !evaluation_id) return
    navigate(
      schedulerRoute
        .replace(':student_id', student_id)
        .replace(':evaluation_id', evaluation_id),
      {
        replace: true,
      },
    )
    scrollToTop()
  }, [navigate, scrollToTop, student_id, evaluation_id])

  return (
    <div className='flex flex-col px-6 py-9 gap-6 rounded-2xl bg-white'>
      <div className='flex justify-end'></div>
      <div className='flex flex-col flex-1 gap-4 border rounded-md border-slate-200 p-3'>
        <div className='flex justify-between'>
          <h3 className='text-lg font-bold'>Escolha os exercícios aeróbicos</h3>
          <div className='flex gap-3 flex-wrap justify-center'>
            <Button className='self-start' onClick={handleToggleModal}>
              Novo exercício
            </Button>
            <Button
              mode='outlined'
              className='self-start'
              onClick={handleToggleRemoveModal}
            >
              Excluir exercício
            </Button>
          </div>
        </div>
        <p>
          Selecione quantos desejar, a ordem deles será a ordem em que os
          trabalhos da série serão montados
        </p>
        <div className='flex gap-3 flex-wrap mb-3'>
          {data?.exercises?.map((activity, index) => (
            <ExerciseTag
              key={`activity-list-item-${index}`}
              exercise={activity}
              onAdd={() => selectExercise(activity)}
            />
          ))}
        </div>
      </div>

      <div className='flex flex-col flex-1 gap-4 border rounded-md border-slate-200 p-3'>
        <h3 className='text-lg font-bold'>Trabalhos Aeróbicos Selecionados</h3>

        <div className='flex gap-3 flex-wrap'>
          {data?.exercisesOrder.map((activity, index) => (
            <button
              key={`activity-selected-item-${index}`}
              className='flex gap-2 p-2 bg-gpa-blue-50 rounded-md self-start hover:bg-blue-200 active:bg-blue-300'
              onClick={() => removeExerciseFromOrder(index)}
            >
              {activity}
              <span className='p-1 rounded-full bg-blue-400'>
                <FiMinus className='text-white' />
              </span>
            </button>
          ))}
        </div>
      </div>
      <NewActivityModal visible={showModal} onClose={handleToggleModal} />
      <RemoveActivityModal
        visible={showRemoveModal}
        onClose={handleToggleRemoveModal}
      />
      <div className='flex justify-between w-full'>
        <Button
          type='button'
          loading={loading}
          disabled={loading}
          onClick={handleGoBack}
        >
          <FiChevronLeft size={24} />
          <span className='mb-1'>Voltar</span>
        </Button>
        <Button
          loadingMessage='Salvando...'
          loading={loading}
          disabled={loading}
          onClick={handleSave}
        >
          <span className='mb-1'>Salvar</span>
          <FiChevronRight size={24} />
        </Button>
      </div>
    </div>
  )
}
