export function swapItemsInArrayService(
  array: Array<any> | null,
  indexA: number,
  indexB: number,
) {
  if (array === null) return

  const copy = array[indexB]
  array[indexB] = array[indexA]
  array[indexA] = copy
}
