import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FiChevronRight } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '~/components/Button'
import { Checkbox } from '~/components/Checkbox'
import { QuestionFrame } from '~/components/QuestionFrame'
import { SelectInput } from '~/components/SelectInput'
import { TextInput } from '~/components/TextInput'
import {
  anamnesisAtom,
  anamnesisStepCounterAtom,
  // anamnesisStepCounterAtom,
} from '~/modules/anamnesis/data'
import {
  patchAnamnesis,
  updateAnamnesisData,
} from '~/modules/anamnesis/services'
import { schedulerAtom } from '~/modules/scheduler/data'
import { syncAvailableWeekdays } from '~/modules/scheduler/services'
import { useAtom } from '~/observers/jotai'
import { EvaluationRouteParams } from '~/routes/types'

import { formsLayoutContext } from '../../../../layout'
import {
  GeneralFormType,
  generalFormSchema,
  validateQuestionsSchema,
} from './schema'
import {
  availableWeekDaysEnum,
  exercisesEnum,
  goalsEnum,
  regularMealsEnum,
  diseasesEnum,
  medicationEnum,
  jointMuscularDamageEnum,
  pedigreeEnum,
  questionsRefs,
  periodOptions,
  presentPhysicalConditioningOptions,
  availableDaysPerWeekOptions,
  exercisesIntensityLevelOptions,
  exercisesLocaleOptions,
  dietOptions,
  effortTestOptions,
  alcoholicDrinksOptions,
  litersPerDayOptions,
  diabetesOptions,
  glicemyLevelOptions,
  cholesterolLevelsOptions,
  triglyceridesLevelsOptions,
  tabagismOptions,
  availableTimeOptions,
} from './variables'

export const GeneralForm: React.FC = () => {
  const [defaultValues] = useAtom(anamnesisAtom)
  const [loading, setLoading] = useState<boolean>(false)
  const { evaluation_id, student_id } = useParams<EvaluationRouteParams>()
  const navigate = useNavigate()
  const { scrollToTop } = useContext(formsLayoutContext)

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, dirtyFields },
  } = useForm<GeneralFormType>({
    defaultValues,
    mode: 'all',
    shouldFocusError: false,
    resolver: zodResolver(generalFormSchema),
  })

  const handleFormSubmit = useCallback(
    async (data: GeneralFormType) => {
      if (!student_id || !evaluation_id) return
      setLoading(true)

      const success = await patchAnamnesis(
        student_id,
        evaluation_id,
        'general',
        data,
      )

      if (success) {
        scrollToTop()
        anamnesisStepCounterAtom.get() < 1 && anamnesisStepCounterAtom.set(1)

        syncAvailableWeekdays(
          data.availableWeekDaysForPhysical,
          student_id,
          evaluation_id,
        )

        navigate('?subform=parQ', {
          replace: true,
        })
      }
      setLoading(false)
    },
    [student_id, evaluation_id, navigate, scrollToTop],
  )

  useEffect(() => {
    const { unsubscribe } = watch((data) => {
      updateAnamnesisData(data as any)
    })

    return () => {
      unsubscribe()
    }
  }, [watch])

  useEffect(() => {
    const keys = Object.keys(errors) as (keyof GeneralFormType)[]
    if (keys[0]) {
      questionsRefs.get(keys[0])?.current?.scrollIntoView({ block: 'center' })
    }
  }, [errors])

  return (
    <form
      className='flex flex-col gap-6'
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <QuestionFrame
        ref={questionsRefs.get('goals')}
        title='1) Selecione os objetivos a serem alcançados'
        errors={!!errors.goals}
        isValid={
          validateQuestionsSchema
            .pick({ goals: true })
            .safeParse({ goals: watch('goals') }).success
        }
      >
        <div className='grid grid-cols-[repeat(auto-fit,_minmax(150px,_230px))] justify-between gap-4'>
          {goalsEnum.map((item, index) => (
            <Checkbox
              key={`goal-${index}`}
              {...register('goals', {
                setValueAs: (v) => (v ? [...v] : []),
              })}
              onFocus={undefined}
              label={item}
              value={item}
              error={!!errors.goals?.message}
            />
          ))}
        </div>
        <span className='flex text-xs text-gpa-red'>
          {errors.goals?.message}
        </span>
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('presentPhysicalConditioning')}
        title='2) Condicionamento físico atual'
        errors={!!errors.presentPhysicalConditioning}
        isValid={
          validateQuestionsSchema
            .pick({ presentPhysicalConditioning: true })
            .safeParse({
              presentPhysicalConditioning: watch('presentPhysicalConditioning'),
            }).success
        }
      >
        <Controller
          control={control}
          name='presentPhysicalConditioning'
          render={({ field }) => (
            <SelectInput
              label='Estado atual'
              placeholder='Selecione um valor'
              options={presentPhysicalConditioningOptions}
              error={errors.presentPhysicalConditioning?.message}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('physicalConditioningTimePeriod')}
        errors={!!errors.physicalConditioningTimePeriod}
        title='3) Há quanto tempo?'
        isValid={
          validateQuestionsSchema
            .pick({ physicalConditioningTimePeriod: true })
            .safeParse({
              physicalConditioningTimePeriod: watch(
                'physicalConditioningTimePeriod',
              ),
            }).success
        }
      >
        <div className='flex gap-4 flex-wrap'>
          <Controller
            control={control}
            name='physicalConditioningTimePeriod.period'
            render={({ field }) => (
              <SelectInput
                label='tempo'
                placeholder='Selecione um valor'
                options={periodOptions}
                error={errors.physicalConditioningTimePeriod?.period?.message}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <TextInput
            {...register('physicalConditioningTimePeriod.quantity', {
              valueAsNumber: true,
            })}
            label='Quantidade'
            placeholder='0'
            type='number'
            error={errors.physicalConditioningTimePeriod?.quantity?.message}
          />
        </div>
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('availableDaysPerWeek')}
        errors={!!errors.availableDaysPerWeek}
        title='4) Quantos dias disponíveis por semana?'
        isValid={
          validateQuestionsSchema
            .pick({ availableDaysPerWeek: true })
            .safeParse({
              availableDaysPerWeek: watch('availableDaysPerWeek'),
            }).success
        }
      >
        <Controller
          control={control}
          name='availableDaysPerWeek'
          render={({ field }) => (
            <SelectInput
              placeholder='Selecione um valor'
              label='Dias'
              options={availableDaysPerWeekOptions}
              error={errors.availableDaysPerWeek?.message}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('availableTime')}
        errors={!!errors.availableTime}
        title='5) Tempo disponível?'
        isValid={
          validateQuestionsSchema.pick({ availableTime: true }).safeParse({
            availableTime: watch('availableTime'),
          }).success
        }
      >
        <Controller
          control={control}
          name='availableTime'
          render={({ field }) => (
            <SelectInput
              placeholder='Selecione um valor'
              label='Tempo'
              options={availableTimeOptions}
              error={errors.availableTime?.message}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('availableWeekDaysForPhysical')}
        errors={!!errors.availableWeekDaysForPhysical}
        title='6) Marque os dias disponíveis para prática de atividade físicas'
        isValid={
          validateQuestionsSchema
            .pick({ availableWeekDaysForPhysical: true })
            .safeParse({
              availableWeekDaysForPhysical: watch(
                'availableWeekDaysForPhysical',
              ),
            }).success
        }
      >
        <div className='grid grid-cols-[repeat(auto-fit,_minmax(150px,_230px))] justify-between gap-4'>
          <Controller
            control={control}
            defaultValue={[] as any}
            name='availableWeekDaysForPhysical'
            render={({ field }) => (
              <>
                {availableWeekDaysEnum.map((item, index) => (
                  <Checkbox
                    key={`availableWeekDays-${index}`}
                    onChange={() => {
                      if (field.value?.includes(item)) {
                        field.onChange(field.value.filter((i) => i !== item))
                      } else if (
                        watch('availableDaysPerWeek') >
                        (field.value?.length || 0)
                      ) {
                        field.onChange([...field.value, item])
                      }
                    }}
                    checked={field.value?.includes(item)}
                    label={item}
                    value={item}
                    error={!!errors.availableWeekDaysForPhysical?.message}
                  />
                ))}
              </>
            )}
          />
        </div>
        <span className='flex text-xs text-gpa-red'>
          {errors.availableWeekDaysForPhysical?.message}
        </span>
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('currentExercises')}
        errors={
          (errors.currentExercises || dirtyFields.currentExercises) &&
          (!(
            watch('currentExercises.exercises')?.length ||
            watch('currentExercises.other')?.length
          ) ||
            !(
              (watch('currentExercises.exercises')?.length === 1 &&
                watch('currentExercises.exercises')?.includes('Nenhum') &&
                !watch('currentExercises.other')?.length) ||
              ((watch('currentExercises.exercises')?.length ||
                watch('currentExercises.other')?.length) &&
                !watch('currentExercises.exercises')?.includes('Nenhum'))
            ))
        }
        title='7) Marque os exercícios físicos realizados atualmente'
        isValid={
          validateQuestionsSchema
            .pick({ currentExercises: true })
            .safeParse({ currentExercises: watch('currentExercises') }).success
        }
      >
        <div className='grid grid-cols-[repeat(auto-fit,_minmax(150px,_230px))] justify-between gap-4'>
          {exercisesEnum.map((item, index) => (
            <Checkbox
              key={`exercises-${index}`}
              {...register('currentExercises.exercises', {
                setValueAs: (v) => (v ? [...v] : []),
              })}
              label={item}
              value={item}
              error={
                (errors.currentExercises || dirtyFields.currentExercises) &&
                (!(
                  watch('currentExercises.exercises')?.length ||
                  watch('currentExercises.other')?.length
                ) ||
                  !(
                    (watch('currentExercises.exercises')?.length === 1 &&
                      watch('currentExercises.exercises')?.includes('Nenhum') &&
                      !watch('currentExercises.other')?.length) ||
                    ((watch('currentExercises.exercises')?.length ||
                      watch('currentExercises.other')?.length) &&
                      !watch('currentExercises.exercises')?.includes('Nenhum'))
                  ))
              }
            />
          ))}
        </div>

        <TextInput
          {...register('currentExercises.other')}
          label='Outras'
          error={
            (errors.currentExercises || dirtyFields.currentExercises) &&
            (!(
              watch('currentExercises.exercises')?.length ||
              watch('currentExercises.other')?.length
            )
              ? 'Ao menos um valor deve ser preenchido'
              : !(
                  (watch('currentExercises.exercises')?.length === 1 &&
                    watch('currentExercises.exercises')?.includes('Nenhum') &&
                    !watch('currentExercises.other')?.length) ||
                  ((watch('currentExercises.exercises')?.length ||
                    watch('currentExercises.other')?.length) &&
                    !watch('currentExercises.exercises')?.includes('Nenhum'))
                )
              ? 'Opção "Nenhum" não pode estar acompanhada'
              : '')
          }
        />
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('exercisesIntensity')}
        errors={!!errors.exercisesIntensity}
        title='8) Intensidade das (atividades acima)'
        isValid={
          validateQuestionsSchema.pick({ exercisesIntensity: true }).safeParse({
            exercisesIntensity: watch('exercisesIntensity'),
          }).success
        }
      >
        <Controller
          control={control}
          name='exercisesIntensity'
          render={({ field }) => (
            <SelectInput
              placeholder='Selecione um valor'
              error={errors.exercisesIntensity?.message}
              options={exercisesIntensityLevelOptions}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('exercisesLocale')}
        errors={!!errors.exercisesLocale}
        title='9) Onde prefere fazer atividade física?'
        isValid={
          validateQuestionsSchema.pick({ exercisesLocale: true }).safeParse({
            exercisesLocale: watch('exercisesLocale'),
          }).success
        }
      >
        <Controller
          control={control}
          name='exercisesLocale'
          render={({ field }) => (
            <SelectInput
              placeholder='Selecione um local'
              options={exercisesLocaleOptions}
              error={errors.exercisesLocale?.message}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('regularMeals')}
        errors={!!errors.regularMeals}
        title='10) Refeições normalmente realizadas no dia'
        isValid={
          validateQuestionsSchema.pick({ regularMeals: true }).safeParse({
            regularMeals: watch('regularMeals'),
          }).success
        }
      >
        <div className='grid grid-cols-[repeat(auto-fit,_minmax(150px,_230px))] justify-between gap-4'>
          {regularMealsEnum.map((item, index) => (
            <Checkbox
              key={`regularMeals-${index}`}
              {...register('regularMeals', {
                setValueAs: (v) => (v ? [...v] : []),
              })}
              label={item}
              value={item}
              error={!!errors.regularMeals?.message}
            />
          ))}
        </div>
        <span className='flex text-xs text-gpa-red'>
          {errors.regularMeals?.message}
        </span>
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('diet')}
        errors={!!errors.diet}
        title='11) Algum tipo de dieta?'
        isValid={
          validateQuestionsSchema.pick({ diet: true }).safeParse({
            diet: watch('diet'),
          }).success
        }
      >
        <Controller
          control={control}
          name='diet'
          render={({ field }) => (
            <SelectInput
              placeholder='Selecione um valor'
              options={dietOptions}
              error={errors.diet?.message}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('suplements')}
        errors={!!errors.suplements}
        title='12) Suplementos alimentares em uso'
        isValid={
          validateQuestionsSchema.pick({ suplements: true }).safeParse({
            suplements: watch('suplements'),
          }).success
        }
      >
        <TextInput
          {...register('suplements')}
          error={errors.suplements?.message}
        />
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('effortTest')}
        errors={!!errors.effortTest}
        title='13) Já fez teste de esforço?'
        isValid={
          validateQuestionsSchema.pick({ effortTest: true }).safeParse({
            effortTest: watch('effortTest'),
          }).success
        }
      >
        <Controller
          control={control}
          name='effortTest'
          render={({ field }) => (
            <SelectInput
              placeholder='Selecione um valor'
              options={effortTestOptions}
              error={errors.effortTest?.message}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('alcoholicDrinks')}
        errors={!!errors.alcoholicDrinks}
        title='14) Bebidas alcóolicas (Fonte AUDIT)'
        isValid={
          validateQuestionsSchema.pick({ alcoholicDrinks: true }).safeParse({
            alcoholicDrinks: watch('alcoholicDrinks'),
          }).success
        }
      >
        <Controller
          control={control}
          name='alcoholicDrinks'
          render={({ field }) => (
            <SelectInput
              placeholder='Selecione um valor'
              options={alcoholicDrinksOptions}
              error={errors.alcoholicDrinks?.message}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('litersPerDay')}
        errors={!!errors.litersPerDay}
        title='15) Ingere líquidos com frequência? (2 litros é o recomendado pelo "Guia alimentar para população brasileira" - Min. da Saúde)'
        isValid={
          validateQuestionsSchema.pick({ litersPerDay: true }).safeParse({
            litersPerDay: watch('litersPerDay'),
          }).success
        }
      >
        <Controller
          control={control}
          name='litersPerDay'
          render={({ field }) => (
            <SelectInput
              placeholder='Selecione um valor'
              options={litersPerDayOptions}
              error={errors.litersPerDay?.message}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('diabetes')}
        errors={!!errors.diabetes}
        title='16) Diabetes?'
        isValid={
          validateQuestionsSchema.pick({ diabetes: true }).safeParse({
            diabetes: watch('diabetes'),
          }).success
        }
      >
        <Controller
          control={control}
          name='diabetes'
          render={({ field }) => (
            <SelectInput
              placeholder='Selecione um valor'
              options={diabetesOptions}
              error={errors.diabetes?.message}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('glicemyLevel')}
        errors={!!errors.glicemyLevel}
        title='17) Nível de glicemia em jejum (Fonte: Laboratório Delboni Auriemo)'
        isValid={
          validateQuestionsSchema.pick({ glicemyLevel: true }).safeParse({
            glicemyLevel: watch('glicemyLevel'),
          }).success
        }
      >
        <Controller
          control={control}
          name='glicemyLevel'
          render={({ field }) => (
            <SelectInput
              placeholder='Selecione um valor'
              options={glicemyLevelOptions}
              error={errors.glicemyLevel?.message}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('cholesterolLevels')}
        errors={!!errors.cholesterolLevels}
        title='18) Nível de colesterol total (Fonte Laboratório Delboni Auriemo)'
        isValid={
          validateQuestionsSchema.pick({ cholesterolLevels: true }).safeParse({
            cholesterolLevels: watch('cholesterolLevels'),
          }).success
        }
      >
        <Controller
          control={control}
          name='cholesterolLevels'
          render={({ field }) => (
            <SelectInput
              placeholder='Selecione um valor'
              options={cholesterolLevelsOptions}
              error={errors.cholesterolLevels?.message}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('triglyceridesLevels')}
        errors={!!errors.triglyceridesLevels}
        title='19) Nível de triglicerídeos (Fonte Laboratório Delboni Auriemo)'
        isValid={
          validateQuestionsSchema
            .pick({ triglyceridesLevels: true })
            .safeParse({
              triglyceridesLevels: watch('triglyceridesLevels'),
            }).success
        }
      >
        <Controller
          control={control}
          name='triglyceridesLevels'
          render={({ field }) => (
            <SelectInput
              placeholder='Selecione um valor'
              options={triglyceridesLevelsOptions}
              error={errors.triglyceridesLevels?.message}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('tabagism')}
        errors={!!errors.tabagism}
        title='20) Tabagismo'
        isValid={
          validateQuestionsSchema.pick({ tabagism: true }).safeParse({
            tabagism: watch('tabagism'),
          }).success
        }
      >
        <Controller
          control={control}
          name='tabagism'
          render={({ field }) => (
            <SelectInput
              placeholder='Selecione um valor'
              options={tabagismOptions}
              error={errors.tabagism?.message}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('diseases')}
        errors={
          (errors.diseases || dirtyFields.diseases) &&
          (!(
            watch('diseases.diseases')?.length ||
            watch('diseases.other')?.length
          ) ||
            !(
              (watch('diseases.diseases')?.length === 1 &&
                watch('diseases.diseases')?.includes('Nenhuma') &&
                !watch('diseases.other')?.length) ||
              ((watch('diseases.diseases')?.length ||
                watch('diseases.other')?.length) &&
                !watch('diseases.diseases')?.includes('Nenhuma'))
            ))
        }
        title='21) Doenças e queixas'
        isValid={
          validateQuestionsSchema.pick({ diseases: true }).safeParse({
            diseases: watch('diseases'),
          }).success
        }
      >
        <div className='grid grid-cols-[repeat(auto-fit,_minmax(150px,_230px))] justify-between gap-4'>
          {diseasesEnum.map((item, index) => (
            <Checkbox
              key={`diseases-${index}`}
              {...register('diseases.diseases', {
                setValueAs: (v) => (v ? [...v] : []),
              })}
              label={item}
              value={item}
              error={
                (errors.diseases || dirtyFields.diseases) &&
                (!(
                  watch('diseases.diseases')?.length ||
                  watch('diseases.other')?.length
                ) ||
                  !(
                    (watch('diseases.diseases')?.length === 1 &&
                      watch('diseases.diseases')?.includes('Nenhuma') &&
                      !watch('diseases.other')?.length) ||
                    ((watch('diseases.diseases')?.length ||
                      watch('diseases.other')?.length) &&
                      !watch('diseases.diseases')?.includes('Nenhuma'))
                  ))
              }
            />
          ))}
        </div>

        <TextInput
          {...register('diseases.other')}
          label='Outras doenças e queixas'
          error={
            (errors.diseases || dirtyFields.diseases) &&
            (!(
              watch('diseases.diseases')?.length ||
              watch('diseases.other')?.length
            )
              ? 'Ao menos um valor deve ser preenchido'
              : !(
                  (watch('diseases.diseases')?.length === 1 &&
                    watch('diseases.diseases')?.includes('Nenhuma') &&
                    !watch('diseases.other')?.length) ||
                  ((watch('diseases.diseases')?.length ||
                    watch('diseases.other')?.length) &&
                    !watch('diseases.diseases')?.includes('Nenhuma'))
                )
              ? 'Opção "Nenhuma" não pode estar acompanhada'
              : '')
          }
        />
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('regularMedications')}
        errors={
          (errors.regularMedications || dirtyFields.regularMedications) &&
          (!(
            watch('regularMedications.medications')?.length ||
            watch('regularMedications.other')?.length
          ) ||
            !(
              (watch('regularMedications.medications')?.length === 1 &&
                watch('regularMedications.medications')?.includes('Nenhum') &&
                !watch('regularMedications.other')?.length) ||
              ((watch('regularMedications.medications')?.length ||
                watch('regularMedications.other')?.length) &&
                !watch('regularMedications.medications')?.includes('Nenhum'))
            ))
        }
        title='22) Medicamentos utilizados regularmente'
        isValid={
          validateQuestionsSchema.pick({ regularMedications: true }).safeParse({
            regularMedications: watch('regularMedications'),
          }).success
        }
      >
        <div className='grid grid-cols-[repeat(auto-fit,_minmax(150px,_230px))] justify-between gap-4'>
          {medicationEnum.map((item, index) => (
            <Checkbox
              key={`regularMedications-${index}`}
              {...register('regularMedications.medications', {
                setValueAs: (v) => (v ? [...v] : []),
              })}
              label={item}
              value={item}
              error={
                (errors.regularMedications || dirtyFields.regularMedications) &&
                (!(
                  watch('regularMedications.medications')?.length ||
                  watch('regularMedications.other')?.length
                ) ||
                  !(
                    (watch('regularMedications.medications')?.length === 1 &&
                      watch('regularMedications.medications')?.includes(
                        'Nenhum',
                      ) &&
                      !watch('regularMedications.other')?.length) ||
                    ((watch('regularMedications.medications')?.length ||
                      watch('regularMedications.other')?.length) &&
                      !watch('regularMedications.medications')?.includes(
                        'Nenhum',
                      ))
                  ))
              }
            />
          ))}
        </div>

        <TextInput
          {...register('regularMedications.other')}
          label='Outro medicamento utilizado'
          error={
            (errors.regularMedications || dirtyFields.regularMedications) &&
            (!(
              watch('regularMedications.medications')?.length ||
              watch('regularMedications.other')?.length
            )
              ? 'Ao menos um valor deve ser preenchido'
              : !(
                  (watch('regularMedications.medications')?.length === 1 &&
                    watch('regularMedications.medications')?.includes(
                      'Nenhum',
                    ) &&
                    !watch('regularMedications.other')?.length) ||
                  ((watch('regularMedications.medications')?.length ||
                    watch('regularMedications.other')?.length) &&
                    !watch('regularMedications.medications')?.includes(
                      'Nenhum',
                    ))
                )
              ? 'Opção "Nenhum" não pode estar acompanhada'
              : '')
          }
        />
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('jointMuscularDamage')}
        errors={!!errors.jointMuscularDamage}
        title='23) Lesões articulares ou musculares'
        isValid={
          validateQuestionsSchema
            .pick({ jointMuscularDamage: true })
            .safeParse({
              jointMuscularDamage: watch('jointMuscularDamage'),
            }).success
        }
      >
        <div className='grid grid-cols-[repeat(auto-fit,_minmax(150px,_230px))] justify-between gap-4'>
          {jointMuscularDamageEnum.map((item, index) => (
            <Checkbox
              key={`muscularDamage-${index}`}
              {...register('jointMuscularDamage', {
                setValueAs: (v) => (v ? [...v] : []),
              })}
              label={item}
              value={item}
              error={!!errors.jointMuscularDamage?.message}
            />
          ))}
        </div>
        <span className='flex text-xs text-gpa-red'>
          {errors.jointMuscularDamage?.message}
        </span>
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('pedigree')}
        errors={!!errors.pedigree}
        title='24) Histórico familiar'
        isValid={
          validateQuestionsSchema.pick({ pedigree: true }).safeParse({
            pedigree: watch('pedigree'),
          }).success
        }
      >
        <div className='grid grid-cols-[repeat(auto-fit,_minmax(150px,_230px))] justify-between gap-4'>
          {pedigreeEnum.map((item, index) => (
            <Checkbox
              key={`pedigree-${index}`}
              {...register('pedigree', {
                setValueAs: (v) => (v ? [...v] : []),
              })}
              label={item}
              value={item}
              error={!!errors.pedigree?.message}
            />
          ))}
        </div>
        <span className='flex text-xs text-gpa-red'>
          {errors.pedigree?.message}
        </span>
      </QuestionFrame>

      <QuestionFrame
        ref={questionsRefs.get('commentary')}
        errors={!!errors.commentary}
        title='25) Comentários'
        isValid={
          validateQuestionsSchema.pick({ commentary: true }).safeParse({
            commentary: watch('commentary'),
          }).success
        }
      >
        <TextInput
          label='Algo que deva ser levado em consideração'
          {...register('commentary')}
          error={errors.commentary?.message}
        />
      </QuestionFrame>

      <Button loading={loading} disabled={loading}>
        <span className='mb-1'>Salvar</span>
        <FiChevronRight size={24} />
      </Button>
    </form>
  )
}
