import { AxiosError } from 'axios'

import { WeekDays } from '~/types/types'
import { api } from '~/utils/api'
import { getWeekdayIndex, weekDaysOrder } from '~/utils/weekdays'

import { stepCounterAtom } from '../evalutation/data'
import { standardizedSeriesAtom } from '../standardizedSeries/data'
import { insertToast } from '../toasts/services'
import { schedulerAtom } from './data'
import { Scheduler } from './types'
import { defaultActivities } from './variables'

export function updateSchedulerData(payload: Partial<Scheduler>) {
  if (schedulerAtom.get()) {
    schedulerAtom.set({
      ...schedulerAtom.get(),
      ...(payload as Scheduler),
    })
  }
}

export function recoverStandardActivities() {
  const oldData = schedulerAtom.get()
  const newActivities = defaultActivities.filter(
    (item) => !oldData?.activities.includes(item),
  )

  if (schedulerAtom.get()) {
    schedulerAtom.set({
      ...(oldData as Scheduler),
      activities: [...(oldData?.activities ?? []), ...newActivities],
    })
  }
}

export function syncAvailableWeekdays(
  daysInWeek: WeekDays[],
  studentId: string,
  evaluationId: string,
) {
  const activityWeek = schedulerAtom.get()?.activityWeek ?? []
  // TODO: melhorar esse procedimento: ordernar no momento do input
  const sortedDaysInWeek = weekDaysOrder
    .filter((d) => daysInWeek.includes(d))
    .map((i) => getWeekdayIndex(i))

  for (let i = 0; i < 7; i++) {
    if (!sortedDaysInWeek.includes(i)) {
      activityWeek[i] = null
    }
  }

  api
    .patch(
      `personal-trainers/students/${studentId}/evaluations/${evaluationId}/scheduler`,
      { activityWeek },
    )
    .then(({ data }) => {
      if (data) {
        schedulerAtom.set(data)
      }
    })
    .catch((e: AxiosError<APIError>) => {
      console.log(e)
    })
}

export function deleteActivity(activity: string) {
  if (!schedulerAtom.get()) return
  const state = { ...schedulerAtom.get() }
  state.activities = state.activities?.filter((a) => a !== activity)
  state.activityWeek =
    state.activityWeek?.map(
      (aw) => aw?.filter((a) => a.name !== activity) || null,
    ) || []
  schedulerAtom.set(state as Scheduler)
}

export async function saveScheduler(studentId: string, evaluationId: string) {
  const payload = { ...schedulerAtom.get() }

  delete payload.id
  delete payload.evaluationId
  delete payload.createdAt
  delete payload.updatedAt

  return api
    .patch(
      `personal-trainers/students/${studentId}/evaluations/${evaluationId}/scheduler`,
      payload,
    )
    .then(({ data }) => {
      if (data) {
        schedulerAtom.set(data)
        stepCounterAtom.get() < 7 && stepCounterAtom.set(7)

        const numSeries = payload.numSeries
        const currentSeries = standardizedSeriesAtom.get()?.series ?? []

        // sync
        api
          .patch(
            `personal-trainers/students/${studentId}/evaluations/${evaluationId}/standardized-series`,
            {
              series: currentSeries.slice(0, numSeries),
            },
          )
          .then(({ data }) => {
            if (data) {
              standardizedSeriesAtom.set(data)
            }
          })
          .catch((e) => {
            console.log(e)
          })

        return true
      }
      return false
    })
    .catch((e: AxiosError<APIError>) => {
      console.log(e)
      if (e.response?.data.message) {
        insertToast({
          duration: 3,
          message: e.response?.data.message,
          title: 'Erro ao salvar',
        })
      } else {
        insertToast({
          duration: 3,
          message: 'Erro interno',
          title: 'Erro ao salvar',
        })
      }
      return false
    })
}

export function clearScheduler() {
  schedulerAtom.set(undefined)
}
