import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FiX } from 'react-icons/fi'

import { Button } from '~/components/Button'
import { ExerciseTag } from '~/components/ExerciseTag'
import { Modal } from '~/components/Modal'
import { SelectInput } from '~/components/SelectInput'
import { TextInput } from '~/components/TextInput'
import {
  addExerciseToGroup,
  removeExerciseFromGroup,
  restoreStandardExercisesFromGroup,
} from '~/modules/exercisesPrescriptions/services'
import { ExerciseGroupNames } from '~/modules/exercisesPrescriptions/types'
import { useExercisesPrescriptions } from '~/modules/exercisesPrescriptions/useExercisesPrescriptions'

import { FormType, schema } from './schema'

export const Exercises: React.FC = () => {
  const { data, exercisesOptions } = useExercisesPrescriptions()
  const [selectedGroup, setSelectedGroup] = useState<ExerciseGroupNames>()
  const [showEditModal, setShowEditModal] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormType>({
    resolver: zodResolver(schema),
  })

  const exercises = useMemo(() => {
    const section = data?.exercises.find((e) => e.groupName === selectedGroup)

    return section?.exercises ?? []
  }, [data, selectedGroup])

  const handleSelectGroup = useCallback(
    (value: ExerciseGroupNames) => {
      setSelectedGroup(value)
      setValue('exerciseGroup', value)
      clearErrors()
    },
    [setValue, clearErrors],
  )

  const handleToggleEditModal = useCallback(() => {
    setShowEditModal((state) => {
      if (state) setValue('exercise', '')
      return !state
    })
  }, [setValue])

  const handleRestoreExercises = useCallback(() => {
    restoreStandardExercisesFromGroup(selectedGroup)
  }, [selectedGroup])

  const onFormSubmit = useCallback(
    ({ exercise, exerciseGroup }: FormType) => {
      const success = addExerciseToGroup(exercise, exerciseGroup)

      success
        ? handleToggleEditModal()
        : setError('exercise', {
            message: 'Exercício já cadastrado',
            type: 'custom',
          })
    },
    [handleToggleEditModal, setError],
  )

  return (
    <div className='flex flex-col gap-3'>
      <h2 className='font-bold'>
        Aqui você pode verificar e editar a lista de exercícios
      </h2>
      <div className='flex gap-3 flex-wrap items-center'>
        <SelectInput
          label='Selecione um grupo de exercícios'
          options={exercisesOptions}
          value={selectedGroup ?? ''}
          onChange={(v) => handleSelectGroup(v)}
        />
      </div>
      <div className='grid grid-cols-[repeat(auto-fit,_minmax(_180px,auto))] gap-3 min-h-[80px] bg-slate-100 p-3 rounded-lg max-h-[300px] overflow-y-auto items-center'>
        {exercises.map((e) => (
          <p
            key={`exercise-${e.replace(' ', '-')}`}
            className='bg-blue-100 p-2 rounded-lg text-center'
          >
            {e}
          </p>
        ))}
      </div>

      {selectedGroup ? (
        <Button className='flex-1' onClick={handleToggleEditModal}>
          Editar Grupo
        </Button>
      ) : null}

      <Modal visible={showEditModal}>
        {selectedGroup ? (
          <div className='flex flex-col gap-4'>
            <div className='flex justify-between'>
              <h2 className='text-xl font-medium'>Editar {selectedGroup}</h2>
              <button
                className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
                onClick={handleToggleEditModal}
              >
                <FiX size={24} />
              </button>
            </div>

            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div className='flex flex-wrap items-center gap-3'>
                <TextInput
                  {...register('exercise')}
                  label='Caso queira, você também pode adicionar novos exercício a esta lista'
                  placeholder='Novo Exercício'
                  error={errors.exercise?.message}
                />
                <div className='mt-5'>
                  <Button>Adicionar</Button>
                </div>
              </div>
            </form>

            <div className='flex flex-col gap-4'>
              <p>Clique no exercício abaixo para apagá-lo</p>
              <div className='flex flex-wrap gap-4'>
                {exercises.map((e) => (
                  <ExerciseTag
                    key={`exercise-tag-${e.replace(' ', '_')}`}
                    exercise={e}
                    onDelete={() => {
                      removeExerciseFromGroup(e, selectedGroup)
                    }}
                  />
                ))}
              </div>
              <Button onClick={handleRestoreExercises}>
                Restaurar exercícios padrão
              </Button>
            </div>
          </div>
        ) : (
          <div className='flex flex-col gap-4'>
            <div className='flex justify-between'>
              <h2 className='text-xl font-medium'>Aviso</h2>
              <button
                className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
                onClick={handleToggleEditModal}
              >
                <FiX size={24} />
              </button>
            </div>

            <p>Para editar, selecione primeiro um grupo de exercícios</p>

            <Button className='self-end' onClick={handleToggleEditModal}>
              Entendido
            </Button>
          </div>
        )}
      </Modal>
    </div>
  )
}
