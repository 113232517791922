import { useCallback, useMemo, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

export const PageNavigation: React.FC<PageNavigationProps> = ({
  currentPage,
  numPages,
  onPageChange,
}) => {
  const [currentSection, setCurrentSection] = useState<number>(0)
  const maxPagesDisplayed = useMemo<number>(() => 5, [])

  const pages = useMemo<number[]>(() => {
    const arr = []

    for (let i = 0; i < numPages; i += 1) {
      arr.push(i + 1)
    }

    return arr
  }, [numPages])

  const handelAdvancePage = useCallback(() => {
    if (
      currentPage + 1 >= maxPagesDisplayed &&
      (currentPage + 1) % maxPagesDisplayed === 0
    ) {
      setCurrentSection((state) => state + 1)
    }
    onPageChange(currentPage + 1)
  }, [onPageChange, currentPage, maxPagesDisplayed])

  const handleRetrocedePage = useCallback(() => {
    if (
      currentPage + 1 >= maxPagesDisplayed &&
      currentPage % maxPagesDisplayed === 0
    ) {
      setCurrentSection((state) => state - 1)
    }
    onPageChange(currentPage - 1)
  }, [onPageChange, currentPage, maxPagesDisplayed])

  return numPages > 1 ? (
    <div className='flex gap-3'>
      <button
        disabled={currentPage === 0}
        className='flex shrink-0 justify-center items-center border border-gpa-gray-800 rounded-full w-[30px] h-[30px] cursor-pointer disabled:opacity-50'
        onClick={handleRetrocedePage}
      >
        <FiChevronLeft className='text-gpa-blue-500 m-0' size={20} />
      </button>
      <div className='flex shrink-0 justify-center items-center border border-gpa-gray-800 rounded-full h-[30px]'>
        {pages
          .map((page, index) => (
            <button
              key={`page-${index}`}
              disabled={currentPage === numPages}
              className={
                (currentPage === page - 1
                  ? 'bg-gpa-blue-500 text-white'
                  : 'bg-transparent text-black') +
                ' flex justify-center items-center rounded-full h-[30px] w-[30px]'
              }
              onClick={() => onPageChange(page - 1)}
            >
              <span className='text-semibold'>{page}</span>
            </button>
          ))
          .splice(currentSection * maxPagesDisplayed, maxPagesDisplayed)}
      </div>
      <button
        disabled={currentPage + 1 === numPages}
        className='flex shrink-0 justify-center items-center border border-gpa-gray-800 rounded-full w-[30px] h-[30px] cursor-pointer disabled:opacity-50'
        onClick={handelAdvancePage}
      >
        <FiChevronRight className='text-gpa-blue-500 m-0' size={20} />
      </button>
    </div>
  ) : null
}
