import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import zod from 'zod'

import { Button } from '~/components/Button'
import { TextInput } from '~/components/TextInput'
import { registerNewStandardizedSeries } from '~/modules/exercisesPrescriptions/services'

const schema = zod.object({
  groupName: zod
    .string()
    .trim()
    .min(3, 'Deve ter ao menos 3 caracteres')
    .max(30, 'Deve ter no máximo 30 caracteres'),
})

type FormType = zod.infer<typeof schema>
type Props = {
  onSubmit: () => void
}

export const CreateGroupForm: React.FC<Props> = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormType>({ resolver: zodResolver(schema) })

  const onFormSubmit = useCallback(
    (payload: FormType) => {
      registerNewStandardizedSeries(payload.groupName)
      reset()
      onSubmit && onSubmit()
    },
    [reset, onSubmit],
  )

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className='flex flex-wrap gap-3'>
        <TextInput
          {...register('groupName')}
          placeholder='Nome da nova série padronizada'
          error={errors.groupName?.message}
        />
        <Button>Criar Série Padronizada</Button>
      </div>
    </form>
  )
}
